import React from "react";
import HeroSection from "./HeroSection";
import Intro from "./Intro";
import ServiceList from "./ServiceList";
import WhyFtTune from "./WhyFtTune";
import LastComponent from "./LastComponent";
import OtherServices from "./OtherServices";

const RightsManagement = () => {
  const services = [
    {
      heading: "Copyright Registration and Protection",
      detail:
        "Ensuring your music is legally protected is the first step in safeguarding your creative work. FT Tunes offers professional copyright registration services, making sure your music is properly documented and registered with the appropriate authorities. This protects your rights against unauthorized use and provides legal backing in case of disputes.",
    },
    {
      heading: "Licensing and Royalties Management",
      detail:
        "Maximizing your earnings from your music is our priority. We manage licensing agreements, ensuring your music is used under the right terms and conditions. Whether it’s synchronization licenses for films, TV shows or commercials licenses for digital sales, FT Tunes handles it all. We also track and collect royalties on your behalf, ensuring you receive timely payments.",
    },
    {
      heading: "Digital Rights Management (DRM)",
      detail:
        "In today’s digital age, controlling how your music is distributed and consumed online is crucial. FT Tunes provides comprehensive digital rights management services, ensuring your music is protected across all digital platforms. We monitor online usage, enforce copyright and manage digital licenses to protect your music from unauthorized distribution.",
    },
    {
      heading: "Performance Rights Management",
      detail:
        "Earning royalties from live performances and public broadcasts is an essential revenue stream for artists. FT Tunes manages performance rights, ensuring that you are compensated whenever your music is played on radio, TV or at public events. We work with performance rights organizations (PROs) to collect royalties and ensure you are paid what you’re owed.",
    },
    {
      heading: "Legal Support and Consultation",
      detail:
        "Navigating the legal landscape of music rights can be challenging. FT Tunes provides expert legal support and consultation services, helping you understand your rights and offering guidance on complex legal issues. Our team of legal professionals is here to assist you in drafting contracts, resolving disputes and protecting your interests.",
    },
  ];
  const otherServices = [
    // {
    //   heading: "Rights Management",
    //   para: "Protect and manage your music rights across all platforms.",
    //   url: "/music-right-management",
    // },
    {
      heading: "Youtube Monetization",
      para: "Maximize revenue from your music on YouTube through effective monetization strategies.",
      url: "/youtube-monetization",
    },
    {
      heading: "Music Publishing",
      para: "Ensure your music is properly published and earns royalties globally.",
      url: "/music-publishing",
    },
    {
      heading: "Digital Distribution",
      para: "Distribute your music worldwide to digital stores and streaming platforms seamlessly.",
      url: "/digital-distribution",
    },
  ];
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "75px", background: "#000" }}
    >
      <HeroSection
        headingbefore="Best"
        mark="Music Right Management"
        headingafter="Company in India"
        detail="In the ever-evolving music industry, protecting your creative work is very important. FT Tunes is a leading music right management company in India, dedicated to ensuring that artists, composers and producers retain full control and ownership of their music. Our comprehensive music right management services are designed to safeguard your intellectual property, maximize your earnings and simplify the complexities of rights management, so you can focus on what you do best—creating great music."
      />
      <Intro
        headingbefore="Why Choose"
        mark="FTtunes"
        headingafter="for Music Right Management?"
        detail="At FT Tunes, we understand the value of your creative output. As a premier music right management company, we offer tailored solutions that cater to the unique needs of individual artists, record labels and music publishers. Our goal is to provide a seamless experience, ensuring that your rights are protected and that you receive the compensation you deserve for your work."
        imageurl="Images/why-fttunes.png"
      />
      <ServiceList
        services={services}
        heading="Comprehensive Music Right Management Services"
      />
      <WhyFtTune
        headingbefore="Why"
        mark="FTtunes"
        headingafter="?"
        detail="As a trusted music right management company in India, FT Tunes is committed to offering personalized services that meet the specific needs of our clients. We combine industry expertise with a deep understanding of the legal and commercial aspects of music rights management. "
        detail2="Our team is dedicated to protecting your creative work, maximizing your earnings and ensuring your music reaches its full potential."
        imageurl="Images/why-fttune.png"
      />

      <LastComponent
        headingbefore="Ready to protect"
        mark="your rights?"
        headingafter=""
        detail="Secure Now"
      />
      <OtherServices otherServices={otherServices} />
    </div>
  );
};

export default RightsManagement;
