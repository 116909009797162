import React from "react";
import "./OutStore.css";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const imageUrls = [
  "https://gallery.vision/wp-content/uploads/2022/12/TikTok_logo.png",
  "https://gallery.vision/wp-content/uploads/2022/12/TikTok_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/deezer.png",
  "https://gallery.vision/wp-content/uploads/2022/12/jio.png",
  "https://gallery.vision/wp-content/uploads/2022/12/spotify.png",
  "https://gallery.vision/wp-content/uploads/2022/12/boomplay.png",
  "https://gallery.vision/wp-content/uploads/2022/12/anghami.png",
  "https://gallery.vision/wp-content/uploads/2022/12/amazon-music.png",
  "https://gallery.vision/wp-content/uploads/2022/12/google-play-music-logo.png",
  "https://gallery.vision/wp-content/uploads/2022/12/7d_wordmark_colour_RGB-300x94-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/awa-1-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/awa-1-1-150x51.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Beatport-logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/beatsource-blue-logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/tidal-logo-stack-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Facebook-Logo.wine-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/logos_youtube.png",
  "https://gallery.vision/wp-content/uploads/2022/12/1-1-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/bmat-horizontal-logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Gracenote_A_Nielsen_Company_Logo-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Gracenote_A_Nielsen_Company_Logo-1-300x150.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Fizy_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Tencent_Logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/350-3508352_ups-truck-clip-art-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Vevo_2016_Logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/SoundCloud_-_Logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/huawei-ar21-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/huawei-ar21-1-300x150.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Lenovo_logo_2015-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Gionee_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/raaga_logo_new-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Frame-180.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Onkyo_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/KKBOX_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/LINE_MUSIC_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Yandex_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Beat.no_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Boucher.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Ex-Libris.png",
  "https://gallery.vision/wp-content/uploads/2022/12/grandPad_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Glonal-Radio.png",
  "https://gallery.vision/wp-content/uploads/2022/12/NEC-Logo.wine-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/My-Melo.png",
  "https://gallery.vision/wp-content/uploads/2022/12/clipart1843917-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Frame-183.png",
  "https://gallery.vision/wp-content/uploads/2022/12/XMLID_23_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Frame-186.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Naver_Logotype-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Rakuten_Global_Brand_Logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Shazam_logo-1-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/SoundMachine-Logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/SoundMachine-Logo-1-300x171.png",
  "https://gallery.vision/wp-content/uploads/2022/12/stacked-black-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Frame-192.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Movistar_2020_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Nightlife-Music.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Nightlife-Music-300x40.png",
  "https://gallery.vision/wp-content/uploads/2022/12/dfccd6fa3d-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/service_trackdrip-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/logo-standard-for-light-background-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/e6e16a0ad6f5a27268734db6022db0a3-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/cropped-Hardstylecom_gradient_logo_Tekengebied-1-kopie-3-1920x314-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/cropped-Hardstylecom_gradient_logo_Tekengebied-1-kopie-3-1920x314-1-300x50.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Brasbeat.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Xite.png",
  "https://gallery.vision/wp-content/uploads/2022/12/MOra-Qualitas.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Audible-Magic.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Bugs.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Dubeset-Media.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Dubeset-Media-300x30.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Kakakoo-Music.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Kakakoo-Music-300x31.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Sirius_XM_Radio_Logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Intersect.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Aldi-Life.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Naver-Music-300x38.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Hofer-Life.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Jaypay-Official-Site-removebg-preview-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Sound-Machine.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Hits-NL.png",
  "https://gallery.vision/wp-content/uploads/2022/12/juno-logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/JOOX_font_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Jupiter_Telecommunications_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Jaxsta_Black-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/itunes-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/633e81778a-removebg-preview-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/b09c54b8ae-removebg-preview-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/870dd72fd4-removebg-preview-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/870dd72fd4-removebg-preview-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/870dd72fd4-removebg-preview-1-300x94.png",
  "https://gallery.vision/wp-content/uploads/2022/12/672508d557webp-removebg-preview-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/f6a032b79e0c1e031bfac4adee618e81f0c73724-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/b387c5da9awebp-removebg-preview-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/b387c5da9awebp-removebg-preview-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/b387c5da9awebp-removebg-preview-1-300x132.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Frame-225.png",
  "https://gallery.vision/wp-content/uploads/2022/12/VK_Full_Logo_2021-present-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/cb844772e3-removebg-preview-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/HMV-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/kolbi_hor_positive-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/kolbi_hor_positive-1.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/kolbi_hor_positive-1-150x150.png",
  "https://gallery.vision/wp-content/uploads/2022/12/qq-music-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/TIM_logo_2016-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Bitel-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Vodafone-Logo.wine-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/261px-Digicel_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Virgin_Mobile_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Orange_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/odnoklassniki-ok-logo-vector-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/MTN_logo-1.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Zazell.nl_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Top40.nl_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Net5.nl_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/SBS6.in_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Mocreate.nl_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/GTL.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Mega-media.nl_.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Mega-media.nl_.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Mega-media.nl_-300x38.png",
  "https://gallery.vision/wp-content/uploads/2022/12/TVOranhe.nl_.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Nuuday.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Touch-Tunes.png",
  "https://gallery.vision/wp-content/uploads/2022/12/Playnetwork.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Playnetwork.png",
  // "https://gallery.vision/wp-content/uploads/2022/12/Playnetwork-300x45.png",
  "https://gallery.vision/wp-content/uploads/2023/02/Wynklogo-Horiz-big.png",
];

console.log("vshgvdsvgdkdk", imageUrls.length);

const OutStore = () => {
  const navigate = useNavigate();
  return (
    <div className="our-storee" style={{ background: "#000" }}>
      <section className="section-logo">
        <div className="padding-global">
          <Container className="container-large">
            <div className="padding-section-small">
              <div className="text-align-center">
                <div className="center-title_wrapper is-small">
                  <div className="margin-bottom margin-xsmall">
                    <div
                      id="w-node-_4eb95e6f-0d15-e86a-ae3c-c1888aade1d9-8aade1d9"
                      data-w-id="4eb95e6f-0d15-e86a-ae3c-c1888aade1d9"
                      className="tag-component"
                      style={{
                        transform:
                          "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                        opacity: 1,
                      }}
                    >
                      <div>A global network</div>
                    </div>
                  </div>
                  <div className="margin-bottom margin-small">
                    <h2 words-slide-up="" text-split="" style={{ opacity: 1 }}>
                      <mark>FTtunes</mark> - <br /> Music Distribution Partners
                    </h2>
                  </div>
                  <p className="our-store-paraa">
                    Get your music out to the Best, Biggest, and Most popular
                    platforms around the world.
                  </p>
                  <div>
                    <button
                      className="our-store-btnn button_button__MFmXH button_color__bLscP"
                      onClick={() => navigate("/music-distribution-pricing")}
                    >
                      Distribute your music
                    </button>
                  </div>
                  {/* <p className="our-store-paraa">
                    No joining fee. No CC required.
                  </p> */}
                </div>
              </div>
              <div className="logo-large_content-wrapper">
                <div className="w-layout-grid logo-large-list">
                  {imageUrls.map((item) => (
                    <div
                      id="w-node-a285a9ca-1a3f-5aff-efb5-9072d5e0f8a8-d5e0f89b"
                      className="logo-large_wrapper "
                    >
                      <img
                        src={item}
                        loading="lazy"
                        alt=""
                        className="logo-large_logo"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </div>
  );
};

export default OutStore;
