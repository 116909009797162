import React from "react";
import { Container } from "react-bootstrap";
import './Price.css'

const PricingUpper = () => {
  const products = [
    { name: "Music Distribution", icon: "/Images/pricing-icon/icon-music.svg" },
    { name: "Artist Insights", icon: "/Images/pricing-icon/i-artist-insights.svg" },
    { name: "Publishing Royalties", icon: "/Images/pricing-icon/icon-money.svg" },
    // { name: "Sync Licensing", icon: "/Images/pricing-icon/icon-clapper.svg" },
    // { name: "Online Mastering", icon: "/Images/pricing-icon/icon-speaker.svg" },
    { name: "Music Promotion", icon: "/Images/pricing-icon/icon-announcement.svg" },
  ];

  return (
    <div className="pricing-page">
      <div section="" id="joinfree" className="section">
        <div className="container has-text-white">
          <h1 className="heading">
            Everything an artist needs <strong>in one place</strong>
          </h1>
          <div className="plans-include">
            <div style={{color:'#fff'}}>All our plans include:</div>
            <div className="product-wrapper">
              {products.map((product, index) => (
                <div key={index} className="product">
                  <div className="product-inner">
                    <img
                      className="icon"
                      src={product.icon}
                      alt={`${product.name} icon`}
                    />
                    <div className="product-name">{product.name}</div>
                  </div>
                  <div className="product-name">
                    {product.name.split(' ').map((word, i) => (
                      <React.Fragment key={i}>
                        {word}
                        {i < product.name.split(' ').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="choose-plan" />
      </div>
    </div>
  );
};

export default PricingUpper;
