import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Reach = () => {
  return (
    <div>
      <section className="section_section__I0u9H">
        <Container className="container_container__e7iG2 container_right__z3b1d container_top__tLiv0">
          <div className="content_content__hiCNB content_lg__0npCb content_marginAuto__3jHEC">
            <h2>Want to Reach More Fans and Increase Your Streams?</h2>
            <h3>FT Tunes Accelerator helps self-releasing artists do both.</h3>
            {/* <p className="">
              80,000 artists have already enrolled in Accelerator, and nearly
              10,000 artists increased their streams by an average of +143%
              compared to the previous quarter.
            </p> */}
            <p className="">
              FT Tunes Accelerator empowers self-releasing artists to boost
              their discoverability and expand their global audience.
            </p>
            <p className="">
            Discover
              how FT Tunes Accelerator can elevate your music career and
              experience the difference for yourself.
            </p>
            <div className="button-container_buttonContainer__YRgZl undefined">
              <Link
                className="button_button__MFmXH button_color__bLscP"
                to="/signup"
              >
                SignUp
              </Link>
            </div>
          </div>
          <div className="media_default__Mq0yp media_medium__yJzWA">
            <img
              alt=""
              loading="lazy"
              width={600}
              height={347}
              className="media_image__CtmQY"
              style={{ color: "transparent" }}
              src="/Images/growth.png"
              // srcSet="https://www.tunecore.com/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F119568%2F1714571734-tc-accelerator_amplification-copy.png%3Ffit%3Dmax%26fm%3Dwebp%26h%3D600%26q%3D75%26w%3D800&w=640&q=75"
              // src="https://www.tunecore.com/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F119568%2F1714571734-tc-accelerator_amplification-copy.png%3Ffit%3Dmax%26fm%3Dwebp%26h%3D600%26q%3D75%26w%3D800&w=640&q=75"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Reach;
