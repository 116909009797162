import React from "react";
import { Container } from "react-bootstrap";
import ClientSupport from "./ClientSupport";

const ContactUs = () => {
  return (
    <div className="page-container">
      <div className="page-wrapper">
        <div className="page-title" style={{fontWeight:600}}><mark>Contact Us</mark></div>
        <div className="page-content page-contact">
          <ClientSupport />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
