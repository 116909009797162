import React from "react";
import HeroPart from "./HeroPart";
import HowTo from "./HowTo";
import OtherComp from "./OtherComp";
import Features from "./Features";
import StoreStrip from "./StoreStrip";

const SellMusic = () => {
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "85px", background: "#000" }}
    >
      <HeroPart
        headingbefore="Sell Your"
        mark="Music Globally"
        headingafter="- Top Music Sales Solutions"
        detail="In today’s fast-paced digital world, getting your music heard by a global audience is more important than ever. FTtunes offers comprehensive global music distribution services designed to help you reach listeners worldwide. Whether you're an independent artist or a record label, our top music sales solutions ensure that your music is available across all major digital platforms, giving you the exposure you need to grow your fan base and increase your sales."
      />
      <HowTo />
      <OtherComp />
      <Features />
      <StoreStrip />
    </div>
  );
};

export default SellMusic;
