import React from "react";
import HeroSection from "../RightsManagement/HeroSection";
import Intro from "../RightsManagement/Intro";
import ServiceList from "../RightsManagement/ServiceList";
import WhyFtTune from "../RightsManagement/WhyFtTune";
import LastComponent from "../RightsManagement/LastComponent";
import OtherServices from "../RightsManagement/OtherServices";

const YoutubeContentId = () => {
  const services = [
    {
      heading: "Comprehensive YouTube ID Management",
      detail:
        "We handle all aspects of YouTube Content ID management for you, from setting up and maintaining your content’s presence in the Content ID database to ensuring accurate matches and claims. Our team ensures that your music and videos are protected against unauthorized use and are correctly attributed to you.",
    },
    {
      heading: "Effective Content ID Monetization",
      detail:
        "Monetizing your content through YouTube Content ID is a vital revenue stream. We work diligently to maximize your earnings by ensuring that all eligible content is properly claimed and monetized. Our experts optimize your content’s revenue potential, so you can focus on creating without worrying about missed opportunities.",
    },
    {
      heading: "YouTube Rights Management",
      detail:
        "Our rights management services extend beyond Content ID. We provide a holistic approach to managing your intellectual property rights on YouTube, including handling disputes, managing licensing agreements and tracking the performance of your content. Our goal is to ensure that your rights are upheld and that you receive fair compensation for your work.",
    },
    {
      heading: "Music YouTube Content ID Management",
      detail:
        "Musicians and labels face unique challenges in managing their music content on YouTube. FT Tunes offers specialized services for music YouTube Content ID management, including identifying and protecting your tracks, handling audio fingerprinting and ensuring that your music is properly monetized and protected from unauthorized use.",
    },
    {
      heading: "Tailored Solutions for Content Creators",
      detail:
        "Whether you’re an individual artist or a large label, our YouTube Content ID management services are tailored to meet your specific needs. We provide personalized support and solutions to ensure that your content is effectively managed and monetized according to your goals.",
    },
  ];
  const otherServices = [
    {
      heading: "Rights Management",
      para: "Protect and manage your music rights across all platforms.",
      url: "/music-right-management",
    },
    {
      heading: "Youtube Monetization",
      para: "Maximize revenue from your music on YouTube through effective monetization strategies.",
      url: "/youtube-monetization",
    },
    {
      heading: "Music Publishing",
      para: "Ensure your music is properly published and earns royalties globally.",
      url: "/music-publishing",
    },
    {
      heading: "Digital Distribution",
      para: "Distribute your music worldwide to digital stores and streaming platforms seamlessly.",
      url: "/digital-distribution",
    },
  ];
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "75px", background: "#000" }}
    >
      <HeroSection
        headingbefore="Premier"
        mark="YouTube Content ID"
        headingafter="Services in India"
        detail="Welcome to FT Tunes, your trusted partner for comprehensive YouTube Content ID management and monetization services. In today’s digital age, protecting and monetizing your content on YouTube is crucial and our expertise ensures that your music and videos receive the attention and revenue they deserve."
      />
      <Intro
        headingbefore="Why Choose"
        mark="FTtunes"
        headingafter="for YouTube Content ID Services?"
        detail="Choose FT Tunes for YouTube Content ID management to safeguard your music and maximize your revenue. FT Tunes offers expert YouTube Content ID management services, ensuring that your content is properly identified, tracked and monetized across the platform. With a dedicated team and advanced tools, FT Tunes helps you claim and monetize unauthorized use of your music, providing you with peace of mind and increased earnings. Trust FT Tunes to manage your YouTube Content ID efficiently, so you can focus on creating while we protect your rights and boost your income."
        imageurl="Images/why-fttunes.png"
      />
      <ServiceList
        services={services}
        heading="Our YouTube Content ID Management Services"
      />
      <WhyFtTune
        headingbefore="Why"
        mark="FTtunes"
        headingafter="?"
        detail="FT Tunes is dedicated to providing the highest quality YouTube Content ID management and monetization services. Our team of experts is well-versed in the intricacies of YouTube’s Content ID system and is committed to ensuring that your content is protected and optimized for maximum revenue."
        detail2="We leverage our extensive experience and cutting-edge technology to deliver exceptional results for our clients."
        imageurl="Images/why-fttune.png"
      />
      <LastComponent
        headingbefore="Digital Distribution Services - "
        mark="Unlimited Releases"
        headingafter=""
        detail="Distribute My Music"
      />
      <OtherServices otherServices={otherServices} />
    </div>
  );
};

export default YoutubeContentId;
