import React from "react";
import SingleService from "../Services/SingleService";
import TopComponent from "./TopComponent";
import WhyFT from "./WhyFT";
import OurVision from "./OurVision";

const About = () => {
  return (
    <div style={{background:"#000", paddingBottom:"85px"}}>
      <TopComponent />
      <OurVision/>
      {/* <SingleService
        type="1"
        heading="Maximize Your Earnings with"
        subheading="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium, ab fugiat asperiores quia sed ex labore reprehenderit, repudiandae assumenda ducimus nobis tenetur? Magni dolorum accusamus nulla optio debitis harum!"
        mark="Rights Management"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      />
      <SingleService
        type="2"
        heading="Maximize Your Earnings with"
        subheading="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium, ab fugiat asperiores quia sed ex labore reprehenderit, repudiandae assumenda ducimus nobis tenetur? Magni dolorum accusamus nulla optio debitis harum!"
        mark="Youtube Monetisation"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      />
      <SingleService
        type="1"
        heading="Maximize Your Earnings with"
        subheading="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium, ab fugiat asperiores quia sed ex labore reprehenderit, repudiandae assumenda ducimus nobis tenetur? Magni dolorum accusamus nulla optio debitis harum!"
        mark="Music Publishing"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      />
      <SingleService
        type="2"
        heading="Maximize Your Earnings with"
        subheading="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium, ab fugiat asperiores quia sed ex labore reprehenderit, repudiandae assumenda ducimus nobis tenetur? Magni dolorum accusamus nulla optio debitis harum!"
        mark="Digital Distribution"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      /> */}
      <WhyFT />
    </div>
  );
};

export default About;
