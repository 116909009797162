import React, { useEffect, useState } from "react";
import Streams from "./Streams";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import DashboardStats from "./DashboardStats";
import AllAlbumsTable from "./AllAlbumsTable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import ImageModal from "../../ImageModal";

const Dashboard = () => {
  const navigate = useNavigate();
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        // Fetch artistId from localStorage
        const artistProfile = JSON.parse(localStorage.getItem("profile"));
        const artistId = artistProfile?.artistId;

        if (!artistId) {
          // setError("Artist ID not found");
          return;
        }

        // Call the API to fetch albums by the artist
        const response = await axios.get(
          `https://fttunes.com/api/artists/${artistId}/albums`
        );
        setAlbums(response.data.data); // Assuming API returns albums in `data.data`
        // setLoading(false);
      } catch (err) {
        // setError("Failed to fetch albums");
        // setLoading(false);
      }
    };

    fetchAlbums();
  }, []);

  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("profile")));
    console.log("profile", profile);
  }, []);

  return (
    <div className="content-body">
      {(profile.plan_purchased === "0" || profile.plan_purchased === null) && (
        <ImageModal />
      )}

      <Container fluid>
        <Row>
          <Col>
            <DashboardStats />
          </Col>

          <Col xs={12} className="artist-profile">
            <Card className="filter-tab m-0">
              <Card.Header>
                <h4 className="card-title">Latest Releases</h4>
                {/* <div className="filter-nav">
                  <a href="#" className="active">
                    All
                  </a>
                  <a href="#">Hindi</a>
                  <a href="#">Bhojpuri</a>
                  <a href="#">Bhakti</a>
                </div> */}
              </Card.Header>
              <Card.Body className="bs-0 p-0 bg-transparent">
                <Row>
                  {albums.length > 0 ? (
                    albums.map((item) => (
                      <Col key={item.id} md={3} sm={6} className="mb-4">
                        <Card className="items">
                          <Card.Body>
                            <div
                              className="items-img position-relative"
                              style={{ textAlign: "center" }}
                            >
                              <Image
                                src={`https://fttunes.com/api/${item.uploadPoster}`}
                                className="img-fluid rounded mb-3"
                                alt={item.title}
                              />
                              <Image
                                src={`https://fttunes.com/api/${profile.profileImage}`}
                                className="creator"
                                width={50}
                                alt="Creator"
                              />
                            </div>
                            <h4 className="card-title">{item.songName}</h4>
                            <div className="d-flex justify-content-between">
                              <div className="text-start">
                                {/* <p className="mb-2">Views Count</p>
                              <h5 className="text-muted">10.6K</h5> */}
                              </div>
                              <div className="text-end">
                                <p className="mb-2">
                                  Release Date{" "}
                                  <strong className="text-primary"></strong>
                                </p>
                                <h5 className="text-muted">
                                  {new Date(item.liveDate).toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "short",
                                      day: "numeric",
                                    }
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  navigate(`/artist/album/${item.id}`)
                                }
                              >
                                View Album
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col md={4}>
                      <Card className="release-card mb-4">
                        <Card.Body className="text-center">
                          <img
                            src="https://freerangestock.com/sample/50472/background-color-shows-music-note-and-acoustic.jpg" // Your placeholder image path
                            alt="Placeholder"
                            className="img-fluid mb-3"
                            style={{opacity:"0.3"}}
                          />
                          <Card.Title>Start Your First Release</Card.Title>
                          <p className="text-muted">No releases found yet.</p>
                          <Button variant="primary" as={Link} to="/artist/releases/create">Add Release</Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <AllAlbumsTable />

          {/* <Col xl={6} xxl={6}>
            <Streams />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
