import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./HowTo.css";
import { ImUpload2 } from "react-icons/im";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { FaWallet } from "react-icons/fa";

const HowTo = () => {
  return (
    <div className="how_to" style={{ paddingTop: "100px" }}>
      <Container>
        <Row className="row sk__featureboxes">
          <Col className="col text-center sk__heading-spacer-s">
            <div className="cover-text-wrapper" style={{ zIndex: 10 }}>
              <div
                className="fancy-gradient-text-box"
                style={{ transform: "translate(0px, 0px)", opacity: 1 }}
              >
                <h1 className="super-heading sk__gradient-fancy-text mb-2 mb-sm-0 mb-xxl-2">
                  How to Sell
                </h1>
                <span className="super-heading sk__gradient-fancy-text-back mb-2 mb-sm-0 mb-xxl-2">
                  {" "}
                  How to Sell
                </span>
              </div>
            </div>
            <h2
              className="h2-large sk__fade-in-6"
              data-sk-fade-match={3}
              style={{ opacity: 1 }}
            >
              Get your music playing to 155+ streaming services and stores worldwide, and start making the money by following these 3 steps
            </h2>
          </Col>
        </Row>

        <Row className="row sk__iconboxes-wrapper text-center sk__flex-center-x">
          <Col md={12} sm={6} lg={4} className="col-12 col-sm-6 col-lg-4">
            <div className="sk__iconbox">
              <a
                className="sk__iconbox-icon-link"
                href=""
              >
                <span className="sk__flex-center sk__iconbox-icon">
                  {/* <span className="icon-diamond1 sk__gradient-fancy-text" /> */}
                  <ImUpload2 />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__iconbox-icon-dash" />
              </a>
              <a
                className="sk__iconbox-text-link gradient-links-bright"
                href=""
              >
                <h5>Upload Your Music</h5>
              </a>
              <p>
                Just upload your artwork, tracks, and release info with just a
                few clicks easily. Choose 100+ streaming services including
                Amazon, Spotify, iTunes, and more
              </p>
            </div>
          </Col>
          <Col md={12} sm={6} lg={4} className="col-12 col-sm-6 col-lg-4">
            <div className="sk__iconbox">
              <a
                className="sk__iconbox-icon-link"
                href=""
              >
                <span className="sk__flex-center sk__iconbox-icon">
                  {/* <span className="icon-power-off sk__gradient-fancy-text" /> */}
                  <TiSocialYoutubeCircular />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__iconbox-icon-dash" />
              </a>
              <a
                className="sk__iconbox-text-link gradient-links-bright"
                href=""
              >
                <h5>We Release Your Music to Stores</h5>
              </a>
              <p>
              FTtunes will quickly release your masterpiece to stores and
                streaming services worldwide. Start selling your music online
                now to get your music heard by millions.
              </p>
            </div>
          </Col>
          <Col md={12} sm={6} lg={4} className="col-12 col-sm-6 col-lg-4">
            <div className="sk__iconbox">
              <a
                className="sk__iconbox-icon-link"
                href=""
              >
                <span className="sk__flex-center sk__iconbox-icon">
                  {/* <span className="icon-headphones1 sk__gradient-fancy-text" /> */}
                  <FaWallet />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__flex-center sk__iconbox-icon">
                  <span className="sk__iconbox-trail" />
                </span>
                <span className="sk__iconbox-icon-dash" />
              </a>
              <a
                className="sk__iconbox-text-link gradient-links-bright"
                href=""
              >
                <h5>Get Paid</h5>
              </a>
              <p>
                Earn money by doing what you love. Sell your music online and
                you get paid every time your music is played or downloaded
                worldwide.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowTo;
