import React from "react";
// import AllAlbumsTable from "../Dashboard/AllAlbumsTable";
import { Container } from "react-bootstrap";
import PendingReleaseTable from "./PendingReleaseTable";

const IncompleteReleases = () => {
  return (
    <div className="content-body">
      <Container>
        <PendingReleaseTable />
      </Container>
    </div>
  );
};

export default IncompleteReleases;
