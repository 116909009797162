import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const LandingText = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center align-items-center">
        <Col md={8} className="text-center">
          <h2 className="mb-4" style={{fontSize:"3.5rem", lineHeight:"4rem", color:"#fff"}}>
          <mark>Promote Your Music</mark> Worldwide on All Major Platforms
            <br />
            {/* Heard by millions. */}
          </h2>
          <p className="mb-3" style={{color:"#fff"}}>
            {/* Hitmakers. Storytellers. Artists who define their genre. */}
            {/* <br /> */}
            FTtunes offers seamless digital music distribution, ensuring your tracks reach major music distribution platforms worldwide. Promote your music globally and connect with new audiences through our expert services. With FTtunes, your music is everywhere it needs to be, giving you the exposure you deserve.
            <br />
            {/* Get your music in online stores and join the ranks of rising stars who distribute with FTtunes. */}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingText;
