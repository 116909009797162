import React from "react";
import "./AgentNavbar.css";
import NavControl from "./NavControl";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AgentNavbar = () => {
  return (
    <div style={{position:"sticky", top:"0", zIndex:"999"}} className="agentnav">
      <NavControl />
      <Header />
      {/* <Sidebar /> */}
    </div>
  );
};

export default AgentNavbar;
