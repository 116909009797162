import React, { useState } from 'react';
import axios from 'axios';

const PaymentForm = () => {
  const [amount, setAmount] = useState(100); // Default amount (in INR)
  const [phone, setPhone] = useState(''); // Phone number state
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  // Create Order API call to the PhonePe API
  const createOrder = async () => {
    try {
      const response = await axios.post('https://admin.lelotrip.com/api/phonepe', {
        payment_method: 'phonepe',
        Amount: amount,
        phone: phone,
      });
      console.log("response", response);
      
      // Assuming the API returns a success property in response.data
      if (response.data.success) {
        console.log("Payment response:", response.data);
        setPaymentSuccess(true);
        alert('Payment Successful!');
      } else {
        alert('Payment Failed');
      }
    } catch (error) {
      console.error('Error creating PhonePe order:', error);
      alert('An error occurred during the payment process');
    }
  };

  return (
    <div style={{ marginBlock: "100px" }}>
      <h2>PhonePe Payment Test</h2>
      
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount (INR)"
      />
      
      <input
        type="text"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        placeholder="Enter phone number"
      />
      
      <button onClick={createOrder}>Pay Now</button>

      {paymentSuccess && <p>Payment was successful!</p>}
    </div>
  );
};

export default PaymentForm;
