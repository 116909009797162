import React from "react";
import HeroPart from "../SellMusic/HeroPart";
import DistributionTools from "./DistributionTools";
import GetDiscovered from "./GetDiscovered";
import LandingText from "./LandingText";
import StoreStrip from "../SellMusic/StoreStrip";

const MusicDistribution = () => {
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "85px", background: "#000" }}
    >
      <HeroPart
        headingbefore="Top"
        mark="Music Distribution"
        headingafter="Services & Solutions"
        detail="Music distribution services transform melodies into worldwide sensations."
      />
      <DistributionTools />
      <GetDiscovered />
      <StoreStrip />
      <LandingText />
    </div>
  );
};

export default MusicDistribution;
