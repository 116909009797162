import React from "react";
import HeroSection from "./MusicPublishing/HeroSection";
import PublishingWhyChoose from "./MusicPublishing/PublishingWhyChoose";
import PublishingPartner from "./MusicPublishing/PublishingPartner";
import NewComponent from "./MusicPublishing/NewComponent";
import MusicSupervision from "./MusicSupervison";
import "./Services.css";
import ServiceSection from "./ServiceSection";
import SingleService from "./SingleService";
import SpecialitySection from "./SpecialitySection";

const Services = () => {
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "75px", background: "#000" }}
    >
      <HeroSection />
      <ServiceSection />
      <SpecialitySection />

      <MusicSupervision />
      {/* <SingleService
        type="1"
        heading="Maximize Your Earnings with"
        mark="Rights Management"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      />
      <SingleService
        type="2"
        heading="Maximize Your Earnings with"
        mark="Youtube Monetisation"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      />
      <SingleService
        type="1"
        heading="Maximize Your Earnings with"
        mark="Music Publishing"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      />
      <SingleService
        type="2"
        heading="Maximize Your Earnings with"
        mark="Digital Distribution"
        points={[
          "We’re partnered with hundreds of global music distribution companies to share your music in 200+ countries.",
          "You release awesome music, and we distribute it everywhere — simple.",
        ]}
        url="/music-right-management"
      /> */}
      <PublishingPartner />
      <NewComponent />
    </div>
  );
};

export default Services;
