import React from "react";
import "./RightChoice.css";
import { Container, Row, Col } from "react-bootstrap";
const RightChoice = () => {
  const data = [
    { head: "Super Fast Delivery",desc:'Deliver your music fast across 100+ platforms, including Spotify, Deezer and Pandora. Our music distribution service ensures your tracks reach a global audience quickly and efficiently.' },
    { head: "Lowest Release Plans",desc:'Choose our music distribution service for the quality and lowest release plans available. Enjoy affordable options while distributing your music widely, ensuring maximum reach without compromising on quality or features' },
    { head: "Unlimited Releasing",desc:'Unlock unlimited releasing plans with our music distribution service. Distribute as many tracks as you want, ensuring your music reaches fans everywhere without restrictions. Elevate your music career effortlessly.' },
    { head: "Worldwide Delivery" ,desc:'Distribute your music worldwide with our top-tier music distribution service. Ensure your tracks reach audiences everywhere, expanding your global presence and connecting with fans across the globe seamlessly.'},
    { head: "CallerTune / CRBT",desc:'Enhance your reach with CallerTune and CRBT services through our music distribution platform. Make your music the sound people hear, boosting visibility and connecting with fans in a unique way.' },
    { head: "Free YouTube Content ID" ,desc:'Protect your music with Free YouTube Content ID through our music distribution service. Automatically track and monetize your content, ensuring you get paid whenever your music is used online.'},
    { head: "Instagram Profile Linking",desc:'Boost your music presence with Instagram Profile Linking. Seamlessly connect your tracks to your Instagram profile, enhancing visibility and engaging with your audience effectively.' },

    { head: "Spotify Profile Verification",desc:'Achieve Spotify Profile Verification with our music distribution service. Gain credibility and enhance your visibility on Spotify, ensuring fans recognize and trust your profile as an official artist account.' },
    { head: "WhatsApp/Email/Phone & Chat Support",desc:'Experience top-notch support with our music distribution service. Get assistance via WhatsApp, email, phone or chat, ensuring your inquiries are handled promptly and efficiently, so you can focus on your music.' },
    { head: "Monthly Revenue Reports",desc:'Stay informed with our monthly revenue reports. Track your earnings, analyze performance and optimize your strategy with detailed insights delivered regularly to maximize your music’s success.' },
  ];
  return (
    <div style={{ background: "#080411" }}>
      <Container>
        <div id="events" className="padding-section-medium">
          <div className="margin-bottom margin-xsmall">
            <div className="max-width-large">
              <h2
                words-slide-from-right=""
                text-split=""
                className="heading-right-choice"
                style={{ opacity: 1 }}
              >
                <span>
                  <mark>Smart </mark>Dashboard & Key Benefits
                </span>
              </h2>
            </div>
          </div>
          <div className="w-layout-grid layout-2col_component">
            <div
              id="w-node-_822acfd4-cd8c-5996-f34b-cf01f7beac10-09d0b1db"
              className="home-events_content"
            >
              {data.map((item, id) => (
                <div id="w-node-_19de85e4-5d21-bb01-1149-6bdc5dea93da-09d0b1db">
                  <div className="home-experts_grid-item">
                    <div className="home-experts_grid-number">
                      {id + 1}
                    </div>
                    <div className="mark-div-right-choice"><mark>{item.head}</mark></div>
                  </div>
                  <p className="right-choice-para">
                   {item.desc} 
                  </p>
                </div>
              ))}
            </div>
            <div className="logo-looping-wrapper">
              <div className="flex-horizontal">
                <div className="marquee">
                  <div className="marquee-vertical-css w-embed">
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          '\n\n.track-vertical {\n  position: absolute;\n  white-space: nowrap;\n  will-change: transform;\n  animation: marquee-vertical 30s linear infinite;\n  /* manipulate the speed of the marquee by changing "20s" line above*/\n}\n\n@keyframes marquee-vertical {\n  from { transform: translateY(0); }\n  to { transform: translateY(-50%); }\n}\n\n.track-vertical-alt{\n  position: absolute;\n  white-space: nowrap;\n  will-change: transform;\n  animation: marquee-vertical-alt 30s linear infinite;\n  /* manipulate the speed of the marquee by changing "20s" line above*/\n}\n\n@keyframes marquee-vertical-alt {\n  from { transform: translateY(-50%); }\n  to { transform: translateY(0%); }\n}\n\n',
                      }}
                    />
                  </div>
                  <div className="marquee-cover" />
                  <div className="track-vertical-alt">
                    <div className="flex-vertical marquee-fix">
                      <div className="icon-container">
                        <img
                          src="/Images/partner/amazon.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/apple.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Deezer_logo.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Facebook.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/instagram.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/tiktok.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/napster.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="marquee">
                  <div className="track-vertical">
                    <div className="flex-vertical marquee-fix">
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Pandora.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/resso.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Spotify.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/tidal-b.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/vevo.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/YouTube01.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/amazon.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/apple.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="marquee">
                  <div className="track-vertical-alt">
                    <div className="flex-vertical marquee-fix">
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Deezer_logo.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Facebook.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/instagram.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/napster.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/Spotify.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/tiktok.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                      <div className="icon-container">
                        <img
                          src="/Images/partner/vevo.webp"
                          alt=""
                          className="iconssssss"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="marquee_gradient" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RightChoice;


