import React from "react";
import HeroSection from "../RightsManagement/HeroSection";
import Intro from "../RightsManagement/Intro";
import ServiceList from "../RightsManagement/ServiceList";
import WhyFtTune from "../RightsManagement/WhyFtTune";
import LastComponent from "../RightsManagement/LastComponent";
import OtherServices from "../RightsManagement/OtherServices";
import "./MusicPublishing.css"

const MusicPublishing = () => {
  const services = [
    {
      heading: "Copyright Registration",
      detail:
        "Protect your music from unauthorized use by registering your work with all necessary copyright bodies. We handle all the paperwork, ensuring your rights are secure.",
    },
    {
      heading: "Royalty Management",
      detail:
        "Whether it’s performance royalties from live shows, mechanical royalties from album sales or sync royalties from media placements, we manage it all, ensuring you get paid accurately and on time.",
    },
    {
      heading: "Sync Licensing",
      detail:
        "We actively seek out opportunities to place your music in films, TV shows, commercials and other media, creating additional revenue streams and exposure for your work.",
    },
    {
      heading: "Global Administration",
      detail:
        "Our global network allows us to administer your rights worldwide, making sure you’re covered no matter where your music is played.",
    },
    {
      heading: "Music Promotion",
      detail:
        "Beyond traditional publishing, we actively promote your music to industry professionals, helping you secure deals and collaborations that can elevate your career.",
    },
  ];
  const otherServices = [
    {
      heading: "Rights Management",
      para: "Protect and manage your music rights across all platforms.",
      url: "/music-right-management",
    },
    {
      heading: "Youtube Monetization",
      para: "Maximize revenue from your music on YouTube through effective monetization strategies.",
      url: "/youtube-monetization",
    },
    // {
    //   heading: "Music Publishing",
    //   para: "Ensure your music is properly published and earns royalties globally.",
    //   url: "/music-publishing",
    // },
    {
      heading: "Digital Distribution",
      para: "Distribute your music worldwide to digital stores and streaming platforms seamlessly.",
      url: "/digital-distribution",
    },
  ];
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "75px", background: "#000" }}
    >
      <HeroSection
        headingbefore="Best"
        mark="Music Publishing "
        headingafter="Services in India"
        detail="In the ever-evolving music industry, protecting your rights and maximizing the value of your creative work is essential. That’s where FT Tunes, a premier music publishing company, steps in. We specialize in music publishing services that empower artists, songwriters and composers by managing their rights and ensuring they receive fair compensation for their work. Whether you're an emerging talent or an established name, our comprehensive services are designed to safeguard your creative legacy."
      />
      <Intro
        headingbefore="Why Choose"
        mark="FTtunes"
        headingafter="for Music Publishing Services?"
        detail="FT Tunes offers comprehensive rights management, global reach and expert promotion. We ensure your music is protected through meticulous copyright registration and collect all your royalties, leaving no revenue unclaimed. Our global network allows us to secure placements for your music in films, TV shows and commercials, expanding your audience and income. With personalized services and industry expertise, FT Tunes is committed to maximizing your creative potential and ensuring you receive the recognition and compensation you deserve. Trust FT Tunes to safeguard and promote your musical legacy."
        imageurl="Images/why-fttunes.png"
      />
      <ServiceList
        services={services}
        heading="Music Publishing Services in India"
      />
      <WhyFtTune
        headingbefore="Why"
        mark="FTtunes"
        headingafter="?"
        detail="Your music is your legacy and at FT Tunes, we’re dedicated to helping you protect and profit from it. As a leading music publishing company in India, we offer the expertise, resources and global reach to ensure that your creative work gets the recognition it deserves."
        detail2="Whether you’re looking to secure your rights, maximize your royalties or promote your music to a broader audience, FT Tunes is here to help."
        imageurl="Images/why-fttune.png"
      />
      <LastComponent
        headingbefore="Collect your publishing "
        mark="royalties"
        headingafter=" with authority."
        detail="Distribute My Music"
      />
      <OtherServices otherServices={otherServices} />
    </div>
  );
};

export default MusicPublishing;
