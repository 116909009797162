import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Explore.css";
import Tilt from "react-parallax-tilt";
import { FaCrown } from "react-icons/fa";

const SpecialitySection = () => {
  return (
    <>
      

      <section
        className="tf-section section-speciality"
        //   style={{ background: "#1c1e1f" }}
      >
        <Container>
          <Row>
            <Col
              xl={3}
              md={6}
              xs={12}
              className="d-flex justify-content-center"
            >
              <Tilt style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                <div
                  className="tg-section-div-one rounded-5 p-4 text-center bg-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    border: "1px solid #ffffff38",
                  }}
                >
                  <img
                    src="/Images/service/unlimited-distribution.png"
                    alt="unlimited-distribution"
                    className="mb-4"
                  />
                  <a className="tg-section-a-one h5 d-block mb-2">
                    <mark>Unlimited Distribution                    </mark>
                  </a>
                  <p
                    className="tg-section-p-one mt-2 mb-4"
                    style={{ lineHeight: "1.7" }}
                  >
                   No limit, Distribute unlimited tracks worldwide over 150 stores.

                  </p>
                </div>
              </Tilt>
            </Col>
            <Col
              xl={3}
              md={6}
              xs={12}
              className="d-flex justify-content-center"
            >
              <Tilt style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                <div
                  className="tg-section-div-one tg-section-div-two rounded-5 p-4 text-center bg-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    border: "1px solid #ffffff38",
                  }}
                >
                  <img
                    src="/Images/service/quick-support.png"
                    alt="quick-support"
                    className="mb-4"
                  />
                  <a className="tg-section-a-one h5 d-block mb-2">
                    <mark>Quick Support                    </mark>
                  </a>
                  <p
                    className="tg-section-p-one mt-2 mb-4"
                    style={{ lineHeight: "1.7" }}
                  >
                   Our support professionals answer your questions with in 2hours, we are open to help you 7 days a week.

                  </p>
                </div>
              </Tilt>
            </Col>
            <Col
              xl={3}
              md={6}
              xs={12}
              className="d-flex justify-content-center"
            >
              <Tilt style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                <div
                  className="tg-section-div-one tg-section-div-there rounded-5 p-4 text-center bg-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    border: "1px solid #ffffff38",
                  }}
                >
                  <img
                    src="/Images/service/reporting.png"
                    alt="reporting"
                    className="mb-4"
                  />
                  <a className="tg-section-a-one h5 d-block mb-2">
                    <mark>Detailed Reporting
                    </mark>
                  </a>
                  <p
                    className="tg-section-p-one mt-2 mb-4"
                    style={{ lineHeight: "1.7" }}
                  >
                    Detailed reporting of your earning, check the details of your sales on dashboard.

                  </p>
                </div>
              </Tilt>
            </Col>
            <Col
              xl={3}
              md={6}
              xs={12}
              className="d-flex justify-content-center"
            >
              <Tilt style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                <div
                  className="tg-section-div-two tg-section-div-one rounded-5 p-4 text-center bg-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    border: "1px solid #ffffff38",
                  }}
                >
                  <img
                    src="/Images/service/global-payments.png"
                    alt="global-payments"
                    className="mb-4"
                  />
                  <a className="tg-section-a-one h5 d-block mb-2">
                    <mark>Global Payments                    </mark>
                  </a>
                  <p
                    className="tg-section-p-one mt-2 mb-4"
                    style={{ lineHeight: "1.7" }}
                  >
                   Get your royalties every month from anywhere in the world.
                  </p>
                </div>
              </Tilt>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SpecialitySection;
