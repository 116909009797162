import React, { useRef, useState } from "react";
import "./HallOfFame.css";
import { Container } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const HallOfFame = () => {
  const data = [
    {
      img: "/Images/hall-of-fame/manish-albela.jpg",
      name: "Manish Albela",
      // card: "2x UK Top 10s",
      // award: "Brit Award Nominated",
    },
    {
      img: "/Images/hall-of-fame/micle-amit.jpg",
      name: "Micle Amit",
      // card: "2x UK Top 10s",
      // award: "Brit Award Nominated",
    },
    {
      img: "/Images/hall-of-fame/rajeshwari-singh.jpg",
      name: "Rajeshwari Singh",
      // card: "2x UK Top 10s",
      // award: "Brit Award Nominated",
    },
    {
      img: "/Images/hall-of-fame/ritik-gupta.jpg",
      name: "Ritik Gupta",
      // card: "2x UK Top 10s",
      // award: "Brit Award Nominated",
    },
    {
      img: "/Images/hall-of-fame/roy-razneesh.jpg",
      name: "Roy Razneesh",
      // card: "2x UK Top 10s",
      // award: "Brit Award Nominated",
    },
    {
      img: "/Images/hall-of-fame/ayesha-rain.jpg",
      name: "Ayesha Rain",
      // card: "2x UK Top 10s",
      // award: "Brit Award Nominated",
    },
    
  ];
  return (
    <div className="hall-of-fame">
      {/* <Container> */}
      <div className="hall-of-f">
        <div className="div-block-218">
          {/* <img
              src="https://cdn.prod.website-files.com/655e0fa544c67c1ee5ce01a4/655e0fa544c67c1ee5ce01fc_%27logo-2048-white.svg"
              loading="lazy"
              alt="FTtunes Music
"
              className="image-204"
            /> */}
          <h3 className="hof-heading">
            <mark>HALL&nbsp;OF&nbsp;FAME</mark>
            <br />
          </h3>
          <p className="paragraph white center large">
            Some of the<strong> biggest artists in the game </strong>started
            their careers at FT Tunes Music.
            <br />
            Think you could be the next to join our Hall of Fame?
          </p>
        </div>
        <div className="div-block-215">
          <Swiper
            spaceBetween={40}
            slidesPerView={"auto"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            //   pagination={{
            //     clickable: true,
            //   }}
            //   navigation={true}
            modules={[Autoplay]}
            loop
            className="mySwiper"
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
          >
            {data.map((item) => (
                <SwiperSlide style={{width:'285px'}} >
                  {/* <div className="overlay olverlay-bold">
                  </div> */}
                  <div
                    loading="lazy"
                    style={{
                      backgroundImage: `url(${item.img})`}}
                    className="project-link-card"
                  >
                    <div className="div-100">
                      <div className="div-text">
                        <p className="hof-artistname">{item.name}</p>
                        <p className="artistcard-text">{item.card}</p>
                        <p className="artistcard-text">{item.award}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              
            ))}
          </Swiper>
        </div>
      </div>
      {/* </Container> */}
    </div>
  );
};

export default HallOfFame;
