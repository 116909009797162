import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const OtherComp = () => {
  return (
    <div>
      <section className="section_section__I0u9H">
        <Container className="container_container__e7iG2 container_right__z3b1d container_top__tLiv0">
          <div className="content_content__hiCNB content_lg__0npCb content_marginAuto__3jHEC">
            <h2>Why Choose FTtunes for Global Music Distribution?</h2>
            {/* <h3>FT Tunes Accelerator helps self-releasing artists do both.</h3> */}
            {/* <p className="">
              80,000 artists have already enrolled in Accelerator, and nearly
              10,000 artists increased their streams by an average of +143%
              compared to the previous quarter.
            </p> */}
            <p className="">
              At FTtunes, we understand the challenges of breaking into the
              global music market. Our platform provides everything you need to
              sell your music globally with ease and efficiency.
            </p>
            <p className="">
              By partnering with us, you gain access to an extensive network of
              digital music distribution channels, allowing your music to reach
              millions of potential fans across the globe.
            </p>
            <div className="button-container_buttonContainer__YRgZl undefined">
              <Link
                className="button_button__MFmXH button_color__bLscP gradient_border_btn"
                to="/signup"
              >
                Sign Up Now
              </Link>
            </div>
          </div>
          <div className="media_default__Mq0yp media_medium__yJzWA">
            <img
              alt=""
              loading="lazy"
              width={600}
              height={347}
              className="media_image__CtmQY"
              style={{ color: "transparent", borderRadius: "50px" }}
              src="/Images/sell-your-music-worldwide.png"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default OtherComp;
