import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Banner.css";
import { FaPlayCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
const Banner = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <section
      className="section banner-section-container position-relative hero_banner"
      style={{
        backgroundImage:
          "url(https://html.rometheme.pro/goodsound/image/image-1920x900-1.jpg)",
        height: "unset",
      }}
    >
      <div className="image-overlay" />
      {/* <h1 style={{zIndex:"-999999999", position:"absolute"}}>Sell Your Music Worldwide & Get Paid</h1>
      <h2 style={{zIndex:"-999999999", position:"absolute"}}>Upload Your Music From Anywhere, to Any Platform</h2> */}
      <Container
        fluid
        className="r-container h-100 position-relative"
        style={{ zIndex: 2 }}
      >
        
        <Row className="row row-cols-1 row-cols-lg-2 w-100 h-100">
          
          <Col className="h-100 p-lg-0">
          
            <div className="d-flex flex-column gap-3 justify-content-center h-100">
              <div className="font-1 fw-bold lh-1 banner_h1">
                <span className="accent-color">
                  <mark>Sell Your</mark> Music Worldwide & Get Paid{" "}
                </span>
              </div>
              
              <p className="text-gray fs-5">
                Get your music on Spotify, JioSaavn, Wynk, YouTube, Humgama,
                Gaana and Apple Music and more. Keep 100% ownership of your
                music and stay in control of your career.{" "}
                <b style={{ color: "#efefef" }}>
                  Unlimited Releases starting at ₹4999.
                </b>
              </p>
              <div className="d-flex flex-lg-row flex-column gap-lg-5 gap-4 ">
                <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
                  <Link
                    to="/signup"
                    className="button_button__MFmXH button_color__bLscP"
                  >
                    Sign Up
                  </Link>
                </div>
                {/* <div className="d-flex flex-row gap-3 align-items-center">
                  <button
                    type="button"
                    className="btn request-loader"
                    onClick={() => setShowModal(true)}
                  >
                    <FaPlayCircle color="#e12929" size={52} className="fa-solid fa-play ms-1" />
                  </button>
                  <span className="font-1 ls-2 fw-bold" style={{color:'#fff'}}>START LISTENING</span>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      {showModal && (
        <div
          className="modal fade show bg-overlay"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content bg-dark-color">
              <iframe
                className="ifr-video"
                src="https://www.youtube.com/embed/FK2RaJ1EfA8?autoplay=1"
                title="YouTube video player"
                allow="autoplay"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Banner;
