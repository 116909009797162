import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import "./SignUp.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Country, State, City } from "country-state-city";
// import location from "../../location.json"
const data = [
  { item: "Super Fast Delivery" },
  { item: "Lowest Release Plans" },
  { item: "Unlimited Releasing" },
  { item: "Worldwide Delivery" },
  { item: "CallerTune / CRBT" },
  { item: "Free YouTube Content ID" },
  { item: "Instagram Profile Linking" },
  { item: "Spotify Profile Verification" },
  { item: "WhatsApp/Email/Phone & Chat Support" },
  { item: "Monthly Revenue Reports" },
];
const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    labelName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    country: "IN",
    state: "",
    city: "",
    role: "",
    agreeToTerms: true,
  });

  const [passwordError, setPasswordError] = useState(""); // Error state for password mismatch
  const [showPassword, setShowPassword] = useState(false); // Toggle show password
  const [phoneCode, setPhoneCode] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "country") {
      const countryDetails = Country.getCountryByCode(value);
      console.log("countrydetails", countryDetails);
      if (countryDetails) {
        console.log("phonecode", countryDetails.phonecode);
        setPhoneCode(countryDetails.phonecode); // Set phone code based on country
      }
    }
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true to show spinner

    // Validate password and confirm password match
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match!");
      return; // Do not submit if passwords don't match
    }

    // Handle form submission logic, e.g., send the data to a server
    try {
      const { confirmPassword, ...submitData } = formData; // Remove confirmPassword from the form data
      console.log("Form data submitted:", submitData);
      const dataSubmit = await axios.post(
        "https://fttunes.com/api/signup",
        submitData
      );
      if (dataSubmit) {
        console.log("Form data submitted:", submitData);
        localStorage.setItem(
          "LoginData",
          JSON.stringify({
            email: submitData.email,
            password: submitData.password,
          })
        );

        try {
          const response = await axios.post(
            "https://fttunes.com/api/artist/login",
            {
              artistIdOrEmail: submitData.email, // Assuming artistId is the username
              password: submitData.password,
            }
          );

          console.log("login response", response);
          const data = response.data;
          console.log("login response", response.data);

          if (data.success) {
            // alert(data.message);
            // Store token in localStorage or sessionStorage if needed
            localStorage.setItem("token", data.token);

            try {
              const response = await axios.get(
                `https://fttunes.com/api/artists/${data.artist.artistId}`
              );
              localStorage.setItem("profile", JSON.stringify(response.data));
              // console.log("response", response);
              // setProfile(response.data); // Initialize form data with artist profile from API
            } catch (error) {
              console.error("Error fetching artist data:", error);
            }
            // Navigate to artist dashboard
            navigate("/artist/dashboard");
          } else {
            alert("Login failed: " + data.message);
          }
        } catch (error) {
          console.error("Error logging in:", error);
          alert("An error occurred during login. Please try again.");
        }

        setFormData({
          firstName: "",
          lastName: "",
          labelName: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
          country: "IN",
          state: "",
          city: "",
          role: "",
          agreeToTerms: true,
        });
        console.log("response", dataSubmit);

        navigate("/thank-you");
      }
    } catch (error) {
      console.error("Error while submitting the signup form", error);
    } finally {
      setLoading(false); // Set loading state to false once submission is complete
    }
    // Optionally reset the form
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Toggle the password visibility
  };

  // console.log(Country.getAllCountries());

  return (
    <div className="sign-up-container">
      <Container>
        <Row className="sm-col-reverse">
          <Col md={5}>
            <img
              title=""
              src="/Images/logo_dark.png"
              className="img-fluid logo"
              alt=""
              style={{ width: "300px" }}
            />
            <div className="col-1-signup-cont">
              <div className="">
                <h1 className="col-1-signup-head">
                  Sell Your Music
                  <span className="text-[#EF1E2F]">
                    {" "}
                    Everywhere &<mark> Get Paid</mark>
                  </span>
                </h1>
              </div>
              <div
                className="col-1-signup-divs"
                // style={{
                //   backgroundImage:
                //     "linear-gradient(135deg, rgb(255 255 255 / 17%) -4.23%, rgb(0 0 0 / 4%) 102.58%)",
                // }}
              >
                <ul className="col-1-signup-ul">
                  {data.map((items) => (
                    <li className="d-flex gap-4 align-item-center">
                      <img
                        src="https://gallery.vision/wp-content/themes/Gallery-Vision/assets/images/tikIcon.svg"
                        alt="tick-icon"
                      />
                      <p className="pb-0">{items.item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
          <Col className="col-1-signup-cont" md={7}>
            <div className="wrapper">
              <h2 style={{ textAlign: "center", marginBottom: "30px" }}>
                <mark>SIGN UP</mark>
              </h2>
              <div className="form-wrapper sign-in">
                <form
                  onSubmit={handleSubmit}
                  className="hs-form-private hsForm_57bfe58c-78bc-476a-b818-8cbdbd4a5e4a hs-form stacked hs-custom-style"
                >
                  <fieldset className="d-flex gap-4">
                    <div className="hs_firstname hs-fieldtype-text field hs-form-field">
                      <label className="form-signup-label">
                        <span>First name</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <input
                          id="firstName"
                          name="firstName"
                          required
                          placeholder="Eg: Satish"
                          type="text"
                          className="hs-input"
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="hs_lastname hs-fieldtype-text field hs-form-field">
                      <label className="form-signup-label">
                        <span>Last name</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <input
                          id="lastName"
                          name="lastName"
                          required
                          placeholder="Eg: Gulraj"
                          type="text"
                          className="hs-input"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </fieldset>

                  <fieldset className="form-columns-1">
                    <div className="hs_company hs-fieldtype-text field hs-form-field">
                      <label className="form-signup-label">
                        <span>Company / Label name</span>
                      </label>
                      <div className="input">
                        <input
                          id="labelName"
                          name="labelName"
                          placeholder=""
                          type="text"
                          className="hs-input"
                          value={formData.labelName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </fieldset>

                  {/* Country, State, City Fields */}
                  <fieldset
                    className="d-flex gap-4 display_flex"
                    // style={{ display: "flex" }}
                  >
                    <div
                      className="hs_country hs-fieldtype-text field hs-form-field w33"
                      // style={{ width: "33%" }}
                    >
                      <label className="form-signup-label">
                        <span>Country</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <select
                          id="country"
                          name="country"
                          className="hs-input"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Country</option>
                          {Country.getAllCountries().map((country) => (
                            <option
                              key={country.isoCode}
                              value={country.isoCode}
                            >
                              {country.name}
                            </option>
                          ))}
                          {/* Add more country options here */}
                        </select>
                      </div>
                    </div>
                    <div
                      className="hs_state hs-fieldtype-text field hs-form-field w33"
                      // style={{ width: "33%" }}
                    >
                      <label className="form-signup-label">
                        <span>State</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <select
                          id="state"
                          name="state"
                          className="hs-input"
                          value={formData.state}
                          onChange={handleChange}
                          required
                          disabled={!formData.country} // disable until a country is selected
                        >
                          <option value="">Select State</option>
                          {State.getStatesOfCountry(formData.country).map(
                            (state) => (
                              <option key={state.isoCode} value={state.isoCode}>
                                {state.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    <div
                      className="hs_city hs-fieldtype-dropdown field hs-form-field w33"
                      // style={{ width: "33%" }}
                    >
                      <label className="form-signup-label">
                        <span>City</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <select
                          id="city"
                          name="city"
                          className="hs-input"
                          value={formData.city}
                          onChange={handleChange}
                          required
                          disabled={!formData.state} // disable until a state is selected
                        >
                          <option value="">Select City</option>
                          {City.getCitiesOfState(
                            formData.country,
                            formData.state
                          ).map((city) => (
                            <option key={city.name} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div
                      className="hs_state hs-fieldtype-text field hs-form-field"
                      style={{ width: "33%" }}
                    >
                      <label className="form-signup-label">
                        <span>State</span>
                      </label>
                      <div className="input">
                        <input
                          id="state"
                          name="state"
                          placeholder="Enter State"
                          type="text"
                          className="hs-input"
                          value={formData.state}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div
                      className="hs_city hs-fieldtype-text field hs-form-field"
                      style={{ width: "33%" }}
                    >
                      <label className="form-signup-label">
                        <span>City</span>
                      </label>
                      <div className="input">
                        <input
                          id="city"
                          name="city"
                          placeholder="Enter City"
                          type="text"
                          className="hs-input"
                          value={formData.city}
                          onChange={handleChange}
                        />
                      </div>
                    </div> */}
                  </fieldset>

                  <fieldset className="form-columns-1 d-flex gap-4">
                    <div
                      className="hs_email hs-fieldtype-text field hs-form-field w50 ww50"
                      // style={{ width: "50%" }}
                    >
                      <label className="form-signup-label">
                        <span>Email</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <input
                          id="email"
                          name="email"
                          required
                          placeholder="Satish@gulraj.com"
                          type="email"
                          className="hs-input"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* </fieldset> */}
                    {/* <fieldset className="form-columns-1 d-flex gap-4"> */}
                    <div
                      className="hs_phone hs-fieldtype-phonenumber field hs-form-field w50 ww50"
                      // style={{ width: "50%" }}
                    >
                      <label className="form-signup-label">
                        <span>Mobile number</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input" style={{ position: "relative" }}>
                        <div className="hs-input hs-fieldtype-intl-phone">
                          <p
                            style={{
                              position: "absolute",
                              bottom: "-2px",
                              left: "0px",
                            }}
                          >
                            {phoneCode && phoneCode}
                          </p>
                          <input
                            name="phone"
                            className="hs-input"
                            type="text"
                            value={formData.phone}
                            // value={`${phoneCode ? `+${phoneCode} ` : ''}${formData.phone}`} // Show phone code
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset
                    className="d-flex gap-4 display_flex"
                    // style={{ display: "flex" }}
                  >
                    <div
                      className="hs_password hs-fieldtype-text field hs-form-field w50"
                      style={{ position: "relative" }}
                    >
                      <label className="form-signup-label">
                        <span>Password</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <input
                          id="password"
                          name="password"
                          required
                          placeholder="Password"
                          type={showPassword ? "text" : "password"} // Toggle between text and password
                          className="hs-input"
                          value={formData.password}
                          onChange={handleChange}
                        ></input>
                        {/* <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          className="password-icon"
                          onClick={toggleShowPassword}
                        /> */}
                        {showPassword ? (
                          <FaEyeSlash
                            style={{
                              color: "#fff",
                              position: "absolute",
                              right: "20px",
                              bottom: "20px",
                            }}
                            onClick={toggleShowPassword}
                          />
                        ) : (
                          <FaEye
                            style={{
                              color: "#fff",
                              position: "absolute",
                              right: "20px",
                              bottom: "20px",
                            }}
                            onClick={toggleShowPassword}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className="hs_confirm_password hs-fieldtype-text field hs-form-field w50"
                      // style={{ width: "50%" }}
                    >
                      <label className="form-signup-label">
                        <span>Confirm Password</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          required
                          placeholder="Confirm Password"
                          type={showPassword ? "text" : "password"} // Toggle between text and password
                          className="hs-input"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                        />
                      </div>
                      {passwordError && (
                        <p className="text-danger" style={{ marginTop: "5px" }}>
                          {passwordError}
                        </p>
                      )}
                    </div>
                  </fieldset>

                  {/* <fieldset className="form-columns-1">
                    <div className="hs_profession hs-fieldtype-radio field hs-form-field">
                      <label className="form-signup-label">
                        <span>Which of these best describes you</span>
                        <span className="hs-form-required">*</span>
                      </label>
                      <div className="input">
                        <ul required className="inputs-list multi-container">
                          <li className="hs-form-radio">
                            <label className="hs-form-radio-display form-signup-label">
                              <input
                                id="role0"
                                type="radio"
                                name="role"
                                value="Record Label"
                                checked={formData.role === "Record Label"}
                                onChange={handleChange}
                              />
                              <span style={{ marginLeft: "7px" }}>
                                Record Label
                              </span>
                            </label>
                          </li>
                          <li className="hs-form-radio">
                            <label className="hs-form-radio-display form-signup-label">
                              <input
                                id="role1"
                                type="radio"
                                name="role"
                                value="Artist"
                                checked={formData.role === "Artist"}
                                onChange={handleChange}
                              />
                              <span style={{ marginLeft: "7px" }}>Artist</span>
                            </label>
                          </li>
                          <li className="hs-form-radio">
                            <label className="hs-form-radio-display form-signup-label">
                              <input
                                id="role2"
                                type="radio"
                                name="role"
                                value="Distributor"
                                checked={formData.role === "Distributor"}
                                onChange={handleChange}
                              />
                              <span style={{ marginLeft: "7px" }}>
                                Distributor
                              </span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </fieldset> */}
                  <fieldset className="form-columns-1">
                    <div className="legal-consent-container">
                      <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                      />
                      <label
                        style={{
                          color: "#fff",
                          marginLeft: "5px",
                          marginBottom: "10px",
                          display: "inline",
                        }}
                      >
                        I have read and agree to FT Tunes{" "}
                        <Link to="/terms-of-service" style={{ color: "blue" }}>
                          Terms of service
                        </Link>{" "}
                        *
                      </label>
                    </div>
                  </fieldset>
                  <div className="hs_submit hs-submit">
                    <div className="actions">
                      <Button
                        type="submit"
                        className="hs-button primary large button_button__MFmXH button_color__bLscP sign_up_now_button"
                        // value="Sign Up Now"
                        style={{ width: "max-content" }}
                        disabled={loading}
                      >
                        {loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Sign Up Now"
                        )}
                      </Button>
                    </div>
                  </div>
                  <div style={{ color: "#fff" }}>
                    Already have an account?{"  "}
                    <Link
                      to="/signin"
                      style={{
                        background: "blue",
                        color: "white",
                        borderRadius: "5px",
                        marginInline: "5px",
                        padding: "2px 5px",
                      }}
                    >
                      Sign In
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignUp;
