import React, { useState } from "react";
import {
  Tab,
  Tabs,
  Accordion,
  Form,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./ContactUs.css";

function ClientSupport() {
  const [activeKey, setActiveKey] = useState("current-clients");

  const handleTabSelect = (k) => setActiveKey(k);

  return (
    <Container>
      <Tabs
        id="client-support-tabs"
        activeKey={activeKey}
        onSelect={handleTabSelect}
        className="mb-3"
      >
        <Tab eventKey="current-clients" title="Current Clients">
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Already a client of The FTtunes?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  If you have account questions, please reach out to your Label
                  Manager. You can find your Label Manager contact by logging
                  into{" "}
                  {/* <a href="#" target="_blank" rel="noopener"> */}
                    Workstation
                  {/* </a> */}
                  {" "}
                  and emailing the address at the bottom of the homepage next to
                  “Support Contact.”
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Having a problem logging in?</Accordion.Header>
              <Accordion.Body>
                <p>
                  If you are unable to access your account, first select “Don’t
                  remember your password?” to reset your login.
                </p>
                <p>
                  <a id="link-login-problem" href="#" style={{textDecoration:"none"}}>
                    Still unable to log in?
                  </a>
                </p>
                <p>
                  If you are still unable to login, contact us using the form
                  below:
                </p>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" required />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formSubject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Subject"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formLabel">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Label"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Message"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-3 submit_btn"
                        style={{ width: "150px", padding: "10px 20px" }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
        <Tab eventKey="copyright-disputes" title="Copyright Disputes">
          <Accordion defaultActiveKey="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
              Did you receive a copyright dispute from The FTtunes?
              </Accordion.Header>
              <Accordion.Body>
                <p>
                For questions about the YouTube claims process, please take a moment to read our blog post. You may also go straight to the{" "} 
                  {/* <a href="#" target="_blank" rel="noopener"> */}
                  YouTube Copyright Center.
                  {/* </a> */}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Still need to dispute a copyright claim?</Accordion.Header>
              <Accordion.Body>
                <p>
                If you still need to discuss a copyright claim, please fill out the form below:
                </p>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" required />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formSubject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Subject"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formLabel">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Label"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          placeholder="Message"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-3 submit_btn"
                        style={{ width: "150px", padding: "10px 20px" }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
      </Tabs>
    </Container>
  );
}

export default ClientSupport;
