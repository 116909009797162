import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import { RiArrowRightSLine } from "react-icons/ri";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footers = () => {
  return (
    <div>
      <section className="vs-newsletter-wrapper bg-dark z-index-step1">
        <Container>
          <div className="position-relative">
            <div className="inner-wrapper bg-black position-absolute top-50 start-50 translate-middle w-100 px-60 py-40">
              <Row className="align-items-center justify-content-center">
                <Col xl={6} className="text-center text-xl-start mb-3 mb-xl-0">
                  <span className="sub-title2 mt-2">Newsletter</span>
                  <h2 className="mb-0 text-white">Get monthly updates</h2>
                </Col>
                <Col md={10} lg={8} xl={6}>
                  <form className="newsletter-style1 d-md-flex">
                    <input
                      type="email"
                      placeholder="Enter email address"
                      className="me-2 form-control"
                    />
                    <button className="vs-btn gradient-btn">
                      Subscribe Now
                    </button>
                  </form>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <footer className="footer-wrapper footer-layout1 bg-fluid bg-major-black position-relative">
        <div
          className="bg-fluid d-none d-none d-xl-block position-absolute start-0 top-0 w-100 h-100 background-image"
          // style={{
          //   backgroundImage:
          //     'url("https://html.vecurosoft.com/gamio/demo/Linkssets/img/bg/footer-bg-1-1.jpg")',
          // }}
        />
        <div className="footer-widget-wrapper dark-style1 z-index-common">
          <Container>
            <Row className="justify-content-between">
              {/* <Col md={6} lg={3} xl={4}>
                <div className="widget footer-widget pt-0">
                  <h3 className="widget_title">About Us</h3>
                  <div className="vs-widget-about">
                    <p className="about-text text-footer1 pe-xl-5">
                      The League of Legends offseason is in full effect and
                      teams are looking to sign new players to strengthen their
                      roster or to re-sign their current players.
                    </p>
                  
                  </div>
                </div>
              </Col> */}
              <Col sm={3} md={6} lg={3} xl={3} xs={5}>
                <div className="widget widget_categories footer-widget">
                  <h3 className="widget_title">Company</h3>
                  <ul>
                    <li>
                      <Link to="/about">
                        <RiArrowRightSLine /> About us
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        <RiArrowRightSLine /> Support
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-service">
                        <RiArrowRightSLine /> Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        <RiArrowRightSLine />
                        Privacy Policy
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <RiArrowRightSLine /> MS-XBOX
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col sm={3} md={6} lg={3} xl={3} xs={7}>
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title">Useful Links</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      <li>
                        <Link to="/signup">
                          <RiArrowRightSLine /> Signup
                        </Link>
                      </li>
                      <li>
                        <Link to="/music-distribution-pricing">
                          <RiArrowRightSLine /> Pricing
                        </Link>
                      </li>
                      <li>
                        <Link to="/music-distribution-services">
                          <RiArrowRightSLine /> Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/sell-your-music">
                          <RiArrowRightSLine /> Sell Your Music
                        </Link>
                      </li>
                      <li>
                        <Link to="/music-publishing">
                          <RiArrowRightSLine /> Music Publishing
                        </Link>
                      </li>
                      <li>
                        <Link to="/music-right-management">
                          <RiArrowRightSLine />
                          Rights management
                        </Link>
                      </li>
                      <li>
                        <Link to="/youtube-content-id">
                          <RiArrowRightSLine /> YouTube Content ID
                        </Link>
                      </li>
                      <li>
                        <Link to="/youtube-monetization">
                          <RiArrowRightSLine />
                          YouTube Monetization
                        </Link>
                      </li>
                      <li>
                        <Link to="/digital-distribution">
                          <RiArrowRightSLine />
                          Digital Stores
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3} xl={6}>
                <div className="widget footer-widget">
                  <h3 className="widget_title">Get in Touch </h3>
                  <div className="vs-widget-about">
                    <div className="footer-contact-us">
                      <FaLocationDot
                        className="icon-btn fal fa-map-marker-alt text-white"
                        size={20}
                      />
                      <p className="contact-info">
                        Nearest Metro: Dashrathpuri (Approx 100 Meter Distance){" "}
                        <br />
                        A2, 2nd Floor Palam Dabri Road, Mahavir Enclave Dwarka,
                        New Delhi - 110045 <br /> <br />{" "}
                        <b>LandMark - KFC/PizzaHut/DIY</b>
                      </p>
                    </div>
                    <div className="footer-contact-us">
                      <FaWhatsapp
                        className="icon-btn fal fa-phone text-white"
                        size={20}
                      />
                      <p className="contact-info" style={{ marginTop: "10px" }}>
                        <Link
                          to="tel:+918084187424"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          +91 8084187424
                        </Link>
                      </p>
                    </div>
                    {/* <p className="contact-info">
                        <i className="fal fa-envelope text-white" />
                        <Link to="mailto:info@example.com">info@example.com</Link>
                      </p> */}
                    <div className="footer-contact-us">
                      <MdEmail
                        className="icon-btn fal fa-phone text-white"
                        size={20}
                      />
                      <p className="contact-info" style={{ marginTop: "10px" }}>
                        <Link
                          to="mailto:support@fttunes.com"
                          style={{ textDecoration: "none" }}
                        >
                          support@fttunes.com
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-copyright bg-black z-index-step1">
          <Container>
            <Row className="justify-content-between">
              {/* <Col
                xl="auto"
                className="align-self-center text-center py-3 py-xl-0 text-xl-start"
              > */}
              <p className="copywrite-text py-3 text-center">
                Copyright © 2024 <Link>FTtunes</Link> All Rights Reserved.{" "}
                Design & Develop By Eweblink Technology
              </p>
              {/* </Col> */}
              {/* <Col xl="auto" className="d-none d-xl-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#">About</Link>
                    </li>
                    <li>
                      <Link to="#">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="#">Support   </Link>
                    </li>
                    <li>
                      <Link to="#">Knowledge Base</Link>
                    </li>
                    <li>
                      <Link to="#">Blogs</Link>
                    </li>
                    
                  </ul>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default Footers;
