import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Container } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { jwtDecode } from "jwt-decode";
// import { CiCalendarDate } from "react-icons/ci";
import {
  FaCalendarAlt,
  FaCaretDown,
  FaCrown,
  FaFacebookF,
  FaHome,
  FaInstagram,
  FaLinkedinIn,
  FaPhone,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
// import BookingModalForm from "./BookingModalForm";
import { RiMenu3Fill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";

// import Logo from './logo.png'

const Navbars = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [active, setIsActive] = useState(false);

  const [prevScrollY, setPrevScrollY] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
    setIsActive(!active);
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 40);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > prevScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }

      if (window.scrollY > 40) {
        setIsSticky(true); // Navbar is sticky after scrolling 40px
      } else {
        setIsSticky(false); // Navbar is not sticky at the top
      }
      setPrevScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollY]);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false); // New state to track authentication
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    const checkAuthStatus = () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp > currentTime) {
            setIsAuthenticated(true); // Token is valid, user is authenticated
          } else {
            localStorage.removeItem("token");
            setIsAuthenticated(false);
            // navigate("/signin");
          }
        } catch (error) {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          // navigate("/signin");
        }
      }
    };

    checkAuthStatus();
  }, [token, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/signin");
  };

  return (
    <>
      <div className="banner-offer-cont">
        <FaCrown style={{ marginRight: "8px", marginTop: "-3px" }} size={19} />
        <span style={{fontSize:"larger", fontWeight:"600"}}>Super Offer</span> - Save 50% on all the packages. Limited time offer for
        limited number of{" "}
        <Link to="/signup" className="banner-offer-ancher">
          SignUp
        </Link>
        . Hurry Up!
      </div>
      <div id="home">
        <header
          className={`container-fluidss dog ${showNavbar ? "show" : "hide"} ${
            isSticky ? "sticky" : ""
          }`}
        >
          <div className="">
            <div className="row">
              <div className="cheader">
                <div className="hheaader">
                  <div className="header_skew">
                    <Link to="/" className="comp_logo">
                      <img
                        title=""
                        src="/Images/ft-tunes.png"
                        className="img-fluid logo"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className={`navigation ${isOpen ? "active" : ""}`}>
                    <ul>
                      {/* Menu items */}
                      <li className="dropdown">
                        <Link to="/sell-your-music" title="Sell Your Music">
                          Sell Your Music
                        </Link>
                      </li>
                      <li className="dropdown">
                        <Link
                          className="service_text_clr"
                          to="/music-distribution-services"
                          title="Service"
                        >
                          Services
                          <FaCaretDown />
                        </Link>
                        <ul className="dropdown-menu">
                          <li onClick={() => setIsOpen(false)}>
                            <Link
                              title="Rights Management"
                              to="/music-right-management"
                            >
                              Rights Management
                            </Link>
                          </li>
                          <li onClick={() => setIsOpen(false)}>
                            <Link
                              title="YouTube Monetization"
                              to="/youtube-monetization"
                            >
                              YouTube Monetization
                            </Link>
                          </li>
                          <li onClick={() => setIsOpen(false)}>
                            <Link
                              title="Music Publishing"
                              to="/music-publishing"
                            >
                              Music Publishing
                            </Link>
                          </li>
                          <li onClick={() => setIsOpen(false)}>
                            <Link
                              title="Digital Distribution"
                              to="/digital-distribution"
                            >
                              Digital Distribution
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown" onClick={() => setIsOpen(false)}>
                        <Link title="Pricing" to="/music-distribution-pricing">
                          Pricing
                        </Link>
                      </li>
                      <li onClick={() => setIsOpen(false)}>
                        <Link
                          title="Music Distribution"
                          to="/music-distribution"
                        >
                          Music Distribution
                        </Link>
                      </li>
                      <li className="dropdown" onClick={() => setIsOpen(false)}>
                        <Link title="Pricing" to="/music-stores">
                          Our Stores
                        </Link>
                      </li>
                      <li onClick={() => setIsOpen(false)}>
                        <Link title="Support" to="/contact-us">
                          Support
                        </Link>
                      </li>
                      <li onClick={() => setIsOpen(false)}>
                        <Link title="Pay Online" to="/payonline">
                          Pay Online
                        </Link>
                      </li>
                      <li className="navbar_phone_number phone-number-bttn">
                        <Link title="Contact Us" to="tel:+918084187424">
                          <FaPhoneAlt className="navbar-fonts" size={20} />
                          <div className="txt phone-number-fonts">
                            +91 8084187424
                          </div>
                        </Link>
                      </li>
                      <li className="socialss" style={{ marginTop: "10px" }}>
                        <div className="sixstyle__FooterSection-sc-915j0m-4 hLuZHF">
                          <div className="SocialShare__Wrapper-h9wts-0 qJGGM social_profiles">
                            <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                              <Link
                                // to="https://www.instagram.com/finaltakefilmproduction/"
                                target="_blank"
                                onClick={() => setIsOpen(false)}
                              >
                                <FaInstagram />
                              </Link>
                            </div>
                            <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                              <Link
                                // to="https://www.x.com/Finaltakeindia"
                                target="_blank"
                                onClick={() => setIsOpen(false)}
                              >
                                <FaTwitter />
                              </Link>
                            </div>
                            <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                              <Link
                                // to="https://www.facebook.com/finaltakefilmproduction"
                                target="_blank"
                                onClick={() => setIsOpen(false)}
                              >
                                <FaFacebookF />
                              </Link>
                            </div>
                            <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                              <Link
                                // tp="http://www.linkedin.com/in/finaltakefilmproduction"
                                target="_blank"
                                onClick={() => setIsOpen(false)}
                              >
                                <FaLinkedinIn />
                              </Link>
                            </div>
                            <div className="SocialShare__Item-h9wts-1 fvcUgI social_profile_item">
                              <Link
                                to="https://www.maps.app.goo.gl/4ftPgrEtrWh5cbDw8"
                                target="_blank"
                                onClick={() => setIsOpen(false)}
                              >
                                <FaLocationDot />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div
                  className="spaceetc"
                  style={{ display: "flex", gap: "20px" }}
                >
                  <div className="button-container_buttonContainer__YR button-container_center__H3">
                    <Link
                      className="button_button__MFmXH button_color-login"
                      to="/signin"
                      target="_blank"
                      style={{ color: "#000" }}
                    >
                      Login
                    </Link>
                  </div>
                  <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
                    <Link
                      className="button_button__MFmXH button_color__bLscP"
                      to="/signup"
                      target="_blank"
                    >
                      SignUp
                    </Link>
                  </div>
                </div> */}

                <div
                  className="spaceetc"
                  style={{ display: "flex", gap: "20px" }}
                >
                  {isAuthenticated ? (
                    <>
                      <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
                        <Link
                          className="button_button__MFmXH button_color__bLscP"
                          to="/artist/dashboard"
                        >
                          Dashboard
                        </Link>
                      </div>
                      {/* <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
                        <button
                          className="button_button__MFmXH button_color-login"
                          onClick={handleLogout}
                          style={{ color: "#000" }}
                        >
                          Logout
                        </button>
                      </div> */}
                    </>
                  ) : (
                    <>
                      <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
                        <Link
                          className="button_button__MFmXH button_color-login"
                          to="/signin"
                          style={{ color: "#000" }}
                          target="_blank"
                        >
                          Login
                        </Link>
                      </div>
                      <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
                        <Link
                          className="button_button__MFmXH button_color__bLscP"
                          to="/signup"
                          target="_blank"
                        >
                          SignUp
                        </Link>
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={`menu menu_btn hamburger ${isOpen ? "open" : ""}`}
                  onClick={handleMenuClick}
                >
                  <RiMenu3Fill className="ic" size={30} color="#d70d57" />
                  {/* <button className="button relative inline-flex items-center justify-center h-11 transition-colors hover:text-color-1 px-3 text-n-1 ml-auto lg:hidden"> */}
                  {/* <span className="relative z-10">
                    <svg
                      className="overflow-visible"
                      width={20}
                      height={12}
                      viewBox="0 0 20 12"
                    >
                      <rect
                        className="transition-all origin-center"
                        y={0}
                        width={20}
                        height={2}
                        rx={1}
                        fill="white"
                        transform="rotate(0)"
                      />
                      <rect
                        className="transition-all origin-center"
                        y={10}
                        width={20}
                        height={2}
                        rx={1}
                        fill="white"
                        transform="rotate(0)"
                      />
                    </svg>
                  </span> */}

                  {/* Left Border */}
                  {/* <svg
                    className="position-absolute top-0 start-0"
                    width={21}
                    height={44}
                    viewBox="0 0 21 44"
                  >
                    <path
                      fill="none"
                      stroke="url(#btn-left)"
                      strokeWidth={2}
                      d="M21,43.00005 L8.11111,43.00005 C4.18375,43.00005 1,39.58105 1,35.36365 L1,8.63637 C1,4.41892 4.18375,1 8.11111,1 L21,1"
                    />
                  </svg> */}

                  {/* Top and Bottom Border */}
                  {/* <svg
                    className="position-absolute top-0 start-50 translate-middle-x w-100"
                    height={44}
                    viewBox="0 0 100 44"
                    preserveAspectRatio="none"
                    fill="none"
                  >
                    <polygon
                      fill="url(#btn-top)"
                      fillRule="nonzero"
                      points="100 42 100 44 0 44 0 42"
                    />
                    <polygon
                      fill="url(#btn-bottom)"
                      fillRule="nonzero"
                      points="100 0 100 2 0 2 0 0"
                    />
                  </svg> */}

                  {/* Right Border */}
                  {/* <svg
                    className="position-absolute top-0 end-0"
                    width={21}
                    height={44}
                    viewBox="0 0 21 44"
                  >
                    <path
                      fill="none"
                      stroke="url(#btn-right)"
                      strokeWidth={2}
                      d="M0,43.00005 L5.028,43.00005 L12.24,43.00005 C16.526,43.00005 20,39.58105 20,35.36365 L20,16.85855 C20,14.59295 18.978,12.44425 17.209,10.99335 L7.187,2.77111 C5.792,1.62675 4.034,1 2.217,1 L0,1"
                    />
                  </svg> */}
                  {/* </button> */}
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Navbars;
