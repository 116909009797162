import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./WhyFT.css";

const WhyFT = () => {
  return (
    <div
      className="experience about_bg_image another_bg_image"
      // style={{ position: "relative" }}
    >
      <div className="upper_sm_img">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={12}>
              <div className="group_image_holder">
                <Image
                  src="https://www.studiofinaltake.com/Images/studio/studio-final-take-9.jpg"
                  alt=""
                  fluid
                />
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="experience_content experience_content_margin">
                <div className="section_header">
                  <h6 className="section_sub_title">
                    <mark>Why Choose FT Tunes?</mark>
                  </h6>
                  <h1 className="section_title">Building Dream into Reality</h1>
                  <p className="short_detail">
                    Choosing FT Tunes means choosing a partner who is committed
                    to your success and to offer personalized services that meet
                    the specific needs of our clients.
                  </p>
                  <p className="section_desc">
                    We offer a seamless and transparent experience, with
                    dedicated support at every step of your journey. Our
                    holistic approach to music distribution and management sets
                    us apart from the competition, as we are not just focused on
                    getting your music out there, but on building a sustainable
                    and profitable career for you.
                  </p>
                  <div className="section_call d-flex">
                    <Image
                      src="https://builderin-react.firebaseapp.com/images/about/call_img.png"
                      alt=""
                    />
                    <div className="call_info ml-3">
                      <p style={{ color: "#fff" }}>
                        Call Us anytime you want. We are{" "}
                        <span>available 24/7</span>
                      </p>
                      <h4 style={{ color: "#fff" }}>
                        <mark>+91 8084187424</mark> <spant>or</spant>{" "}
                        <mark>+91 8084187424</mark>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div
            className="floating_object floating_object_7"
            style={{ position: "absolute", bottom: "0", right: "0", display:"none" }}
          >
            <Image
              src="https://builderin-react.firebaseapp.com/images/about/s_b.png"
              alt=""
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default WhyFT;
