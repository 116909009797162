import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Ready to protect your rights?
const LastComponent = ({ headingbefore, mark, headingafter, detail }) => {
  const navigate = useNavigate();

  return (
    <section className="container-fluid separator-text-hp bg-black history ng-scope">
      <Container className="">
        <div className="row py-5">
          <div className="col-12 col-md-8 col-xl-9 p-5 p-sm-1 separator-text-hp__col">
            <h3 className="display-2 text-glow text-glow--white separator-text-hp__line">
              <span className="d-block d-sm-inline no-break">
                {headingbefore}{" "}
              </span>
            </h3>
            <h3 className="display-2 text-primary separator-text-hp__line">
              <span className="d-block d-sm-inline">{mark} </span>
              <span className="d-block d-sm-inline">{headingafter}</span>
            </h3>
          </div>
          <div className="col-12 col-md-4 col-xl-3 d-flex align-items-center justify-content-start justify-content-md-center p-5 p-sm-1 pt-0 separator-text-hp__col">
            <Button
              ui-sref="about"
              className="btn btn--main btn-primary gradient_border_btn"
              title="Find out more about Global Communities"
              onClick={() => navigate("/signup")}
            >
              {detail}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LastComponent;
