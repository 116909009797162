import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ServiceSection = () => {
  const navigate = useNavigate();

  const services = [
    {
      heading: "Rights Management",
      content:
        "FT Tunes offers top-tier music rights management services, protecting your work with comprehensive copyright solutions. We handle copyright laws and royalty collections, allowing you to focus on your craft. With FT Tunes, your music's value is safeguarded, ensuring you receive the recognition and compensation you deserve for your creativity.",
      link: "/music-right-management",
      image: "Images/service/music-rights-management.png",
      altText: "music-rights-management",
    },
    {
      heading: "Youtube Monetisation",
      content:
        "FT Tunes is a top YouTube management company, providing expert monetization services to optimize your channel for growth, engagement, and revenue. We manage ad placements, increase subscribers, and enhance visibility, allowing you to focus on content creation while we handle monetization complexities, turning your passion into profit and ensuring sustainable growth.",
      link: "/youtube-monetization",
      image: "Images/service/youtube-monitazation-service.png",
      altText: "youtube-monitazation-service",
    },
    {
      heading: "Music Publishing",
      content:
        "FT Tunes is a top music publishing company in Delhi, offering complete services to support artists and composers. We manage and promote your music, handling licensing, protection, and monetization. Our team takes care of copyright registration and royalty collection, maximizing your earnings and exposure. Partner with us to ensure your music achieves its full potential.",
      link: "/music-publishing",
      image: "Images/service/music-publishing.png",
      altText: "music-publishing",
    },
    {
      heading: "Digital Distribution",
      content:
        "FT Tunes is a leading music distribution company in Delhi, providing tailored services for artists and labels. We ensure your music reaches global audiences through major streaming platforms and digital stores. Our team manages digital rights and royalty collection, letting you focus on creativity. Partner with us to maximize your music's reach and impact.",
      link: "/digital-distribution",
      image: "Images/service/music-digital-distribution.png",
      altText: "music-digital-distribution",
    },
  ];

  return (
    <section
      id="rnb_publishing_why_choose"
      className="rnb_section rnb_bg_navy rnb-flex rnb-flex-col rnb-justify-center rnb-items-center rnb-relative"
    >
      <div className="rnb_publishing_artists_title rnb_margin_top_xl rnb-flex rnb-flex-col rnb-justify-center rnb-items-center rnb-text-center">
        <mark>
          {/* <h2
            className="rnb_h2 rnb-w-full rnb-text-white"
            style={{ fontSize: "60px" }}
          >
            <span className="rnb-whitespace-nowrap">Get the most &nbsp;</span>
            from your compositions
          </h2> */}
          <h2
            className="rnb_h2 rnb-w-full rnb-text-white"
            style={{ fontSize: "60px" }}
          >
            <span className="rnb-whitespace-nowrap">Comprehensive Music Distribution Services</span>
            {/* from your compositions */}
          </h2>
        </mark>{" "}
        <p className="rnb_p_lb rnb-text-white rnb_margin_top_sm rnb_publishing_artists_subtitle">
          We can collect your performance royalties, mechanical royalties and
          neighbouring rights - we also offer YouTube Micro Synchronisations.
        </p>
      </div>{" "}
      <div className="rnb_publishing_why_choose_inner rnb-w-full rnb-flex rnb-flex-col rnb-justify-center rnb-items-center">
        {" "}
        <div
          className="rnb_line_list rnb-flex rnb-w-full rnb-flex-col rnb-justify-start rnb-items-start rnb_margin_top_md"
          style={{ width: "60%" }}
        >
          {services.map((service, index) => (
            <div
              key={index}
              className="rnb-flex rnb-w-full rnb-flex-col rnb-justify-start rnb-items-start"
            >
              <div className="rnb_line_list_item rnb-flex rnb-w-full rnb-flex-row rnb-justify-between rnb-items-center">
                <img
                  src={service.image}
                  
                  className="service_image"
                  alt={service.altText}
                />
                <div className="rnb_line_list_text rnb-flex rnb-flex-col rnb-justify-start rnb-items-start">
                  <h3 className="rnb_h3 rnb_text_oswald rnb-text-white">
                    {service.heading}
                  </h3>
                  <p className="rnb_p_lb rnb_margin_top_sm rnb-text-white">
                    {service.content}
                  </p>
                  <Button
                    className="gradient_border_btn explore_btn"
                    onClick={() => navigate(service.link)}
                  >
                    Explore more
                  </Button>
                </div>
              </div>
              {index < services.length - 1 && (
                <svg
                  version="1.1"
                  viewBox="0 0 3 38"
                  className="rnb_line_list_dashed_line svg-icon svg-fill service_dash"
                >
                  <path
                    pid={0}
                    d="M1.5 0v40"
                    stroke="#1345AC"
                    strokeWidth={3}
                    fill="none"
                    fillRule="evenodd"
                    strokeDasharray="10,18"
                  />
                </svg>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
