import React from "react";
import "./Hero.css";
import HeroTwo from "./HeroTwo";
import Explore from "../ExploreAlbum/Explore";
import ServicesSection from "../ExploreAlbum/ServiceSection";
import SpecialitySection from "../ExploreAlbum/SpecialitySection";
import Banner from "./Banner";
import BannerStrip from "./BannerStrip";
import { FaCrown } from "react-icons/fa";
import Keephundred from "../ExploreAlbum/Keephundred";
import Pricing from "../ExploreAlbum/Pricing";
import Reach from "../ExploreAlbum/Reach";
// import Testimonial from "../Testimonial/Testimonial";
import MusicUpload from "../ExploreAlbum/MusicUpload";
import RightChoice from "../ExploreAlbum/RightChoice";
import HallOfFame from "../ExploreAlbum/HallOfFame";
import Testimonial from "../Testimonial/Testi/Testimonial";
import OtherServices from "../../Services/RightsManagement/OtherServices";
import LastComponent from "../../Services/RightsManagement/LastComponent";
// import Additional from "../ExploreAlbum/Additional";
const Hero = () => {
  const otherServices = [
    {
      heading: "Rights Management",
      para: "Protect and manage your music rights across all platforms.",
      url: "/music-right-management",
    },
    {
      heading: "Youtube Monetization",
      para: "Maximize revenue from your music on YouTube through effective monetization strategies.",
      url: "/youtube-monetization",
    },
    {
      heading: "Music Publishing",
      para: "Ensure your music is properly published and earns royalties globally.",
      url: "/music-publishing",
    },
    {
      heading: "Digital Distribution",
      para: "Distribute your music worldwide to digital stores and streaming platforms seamlessly.",
      url: "/digital-distribution",
    },
  ];
  return (
    <div>
      {/* <h1 className="sr-only">Sell Your Music Worldwide & Get Paid</h1>
      <h2 className="sr-only">
        Upload Your Music From Anywhere, to Any Platform
      </h2> */}
      {/* <h1>Sell Your Music Worldwide & Get Paid</h1> */}
      <Banner />
      <BannerStrip />

      {/* <section className="section_section__I0u9H section_gradient__n5Qe_ section_header__XvyD1 section_alignCenter__x2Hse section_tall__wQ9qW">
        <img
          alt=""
          fetchpriority="high"
          decoding="async"
          data-nimg="fill"
          className="content-record_backgroundImage__JW5D1"
          sizes="100vw"
          srcSet="https://html.rometheme.pro/goodsound/image/image-1920x900-1.jpg"
          src="https://html.rometheme.pro/goodsound/image/image-1920x900-1.jpg"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            inset: 0,
            objectFit: "cover",
            objectPosition: "50% 21%",
            color: "transparent",
          }}
        />
        <div className="container_container__e7iG2">
          <div className="content_content__hiCNB content_lg__0npCb content_marginAuto__3jHEC">
            <h1>
              <mark>FT Tunes:</mark> 
              Release Your Music Worldwide
            </h1>
            <p className="content-record_large__sQtlg">
              Get your music on Spotify, Apple Music, TikTok, YouTube, Tidal,
              Tencent and more.
              <br />
              Keep 100% ownership of your music and stay in control of your
              career. Unlimited Releases starting at $22.99/year.
            </p>
            <div className="button-container_buttonContainer__YRgZl button-container_center__H3vVZ">
              <a
                className="button_button__MFmXH button_color__bLscP"
                target="_self"
                href="https://web.tunecore.com/signup"
              >
                Sign Up Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <HeroTwo/> */}

      <SpecialitySection />
      <MusicUpload />

      {/* <Keephundred/> */}
      {/* <ServicesSection/> */}
      {/* <Explore /> */}
      <Pricing />
      <HallOfFame />
      <Reach />
      <RightChoice />
      {/* <Additional/> */}
      <Testimonial />
      <div className="music_services">
        <OtherServices otherServices={otherServices} />
        <LastComponent
          headingbefore="Digital Distribution Services - "
          mark="Unlimited Releases"
          headingafter=""
          detail="Distribute My Music"
        />
      </div>
    </div>
  );
};

export default Hero;
