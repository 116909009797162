import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
// import './SpecialitySection.css';

const SpecialitySection = () => {
  const boxItems = [
    {
      // id: "01",
      title: "Global Platform Reach",
      description:
        "Release unlimited music and songs on hundreds of platforms.",

      marginTop: "mt-neg-45",
      marginRight: "mr-28",
    },
    {
      // id: "02",
      title: "Revenue Management",
      description:
        "Review the performance and sales across music platforms worldwide",

      marginTop: "mt-45",
      marginRight: "",
    },
    {
      // id: "03",
      title: "Promotion and Marketing Support",
      description:
        "A team of experts ready to get you what you want - anytime.",

      marginTop: "mt-neg-60",
      marginRight: "mr-28",
    },
    {
      // id: "04",
      title: "Data Analytics",
      description:
        "Publish your music in the world's best stores no matter where you are.",

      marginTop: "mt-29",
      marginRight: "",
    },
  ];

  return (
    <section className="tf-section section-speciality">
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col xl={5} md={12}>
            <div className="relative">
              <h5
                className="leading-3_2 relative mb-3 before-content"
                style={{ lineHeight: "3.2", fontSize: "24px", color: "#fff" }}
              >
                Our Speciality
              </h5>
              <h3
                className="mb-4"
                style={{
                  marginBottom: "28px",
                  fontSize: "48px",
                  fontWeight: "700",
                  color: "#fff",
                }}
              >
                More Reasons to Choose <mark>FTtunes</mark>
              </h3>
              <p
                className="text-21"
                style={{ fontSize: "21px", color: "#fff" }}
              >
                At FT Tunes, we understand that music distribution is more than
                just getting your tracks on streaming platforms. It's about
                making sure your music is available to the right audience at the
                right time. Our music distribution services include:
              </p>
            </div>
          </Col>
          <Col xl={7} md={12}>
            <div className="section-speciality__box">
              {boxItems.map((item, index) => (
                <div
                  className={`featuresDiv2 ${item.marginTop} ${item.marginRight}`}
                >
                  <Card key={index} className={`box-item`}>
                    <h3>{item.id}</h3>
                    <Card.Link href={item.link} className="h5">
                      {item.title}
                    </Card.Link>
                    <Card.Text>{item.description}</Card.Text>
                  </Card>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SpecialitySection;
