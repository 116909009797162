import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    // If no token, redirect to signin
    if (!token) {
      navigate("/signin");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // current time in seconds

      if (decodedToken.exp < currentTime) {
        // Token is expired
        localStorage.removeItem("token"); // Clear expired token
        navigate("/signin"); // Redirect to Signin
      }
    } catch (error) {
      // If there's an error decoding the token (e.g., corrupted token), treat it as expired
      localStorage.removeItem("token");
      navigate("/signin");
    }
  }, [token, navigate]);

  return token ? children : null;
};

export default ProtectedRoute;
