import React from "react";
import HeroSection from "../RightsManagement/HeroSection";
import Intro from "../RightsManagement/Intro";
import ServiceList from "../RightsManagement/ServiceList";
import WhyFtTune from "../RightsManagement/WhyFtTune";
import LastComponent from "../RightsManagement/LastComponent";
import OtherServices from "../RightsManagement/OtherServices";

const DigitalDistribution = () => {
  const services = [
    {
      heading: "Music Uploading and Encoding",
      detail:
        "We handle the technical side of things, ensuring that your music is uploaded in the highest quality formats and is compatible with all major platforms.",
    },
    {
      heading: "Metadata Management",
      detail:
        "Proper metadata is crucial for ensuring that your music is easily discoverable. We carefully handle your track details to enhance their searchability. ",
    },
    {
      heading: "Release Scheduling",
      detail:
        "Whether you want to drop a single track or an entire album, we help you plan and execute your release for maximum impact.",
    },
    {
      heading: "Royalty Collection",
      detail:
        "We manage the collection of royalties from all platforms, ensuring that you get paid accurately and promptly.",
    },
    {
      heading: "Transparent Revenue Management",
      detail:
        "We believe in transparency. FTtunes provides you with detailed reports on your earnings and insights into your audience's listening habits, helping you make informed decisions about your music career.",
    },
  ];
  const otherServices = [
    {
      heading: "Rights Management",
      para: "Protect and manage your music rights across all platforms.",
      url: "/music-right-management",
    },
    {
      heading: "Youtube Monetization",
      para: "Maximize revenue from your music on YouTube through effective monetization strategies.",
      url: "/youtube-monetization",
    },
    {
      heading: "Music Publishing",
      para: "Ensure your music is properly published and earns royalties globally.",
      url: "/music-publishing",
    },
    // {
    //   heading: "Digital Distribution",
    //   para: "Distribute your music worldwide to digital stores and streaming platforms seamlessly.",
    //   url: "/digital-distribution",
    // },
  ];
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "75px", background: "#000" }}
    >
      <HeroSection
        headingbefore="Global"
        mark="Music Distribution"
        headingafter="Services"
        detail="In today’s rapidly evolving music industry, getting your music heard by a global audience requires more than just talent—it demands strategic distribution. At FT Tunes, we focus on digital music distribution, making sure your tracks are available worldwide. Whether you're an independent artist, a record label or a music producer, our services are designed to help you reach fans across multiple platforms."
      />
      <Intro
        headingbefore="Why Choose"
        mark="FTtunes"
        headingafter="for Digital Music Distribution Services?"
        detail="FT Tunes offers unmatched reach, personalized strategies and transparent reporting. We ensure your music is available on all major platforms like Spotify, Apple Music and more, maximizing your exposure. Our tailored approach helps you connect with the right audience, while our detailed analytics keep you informed about your music’s performance. With expert guidance and full monetization services, FTtunes ensures your music not only reaches a global audience but also drives revenue. Count on FTtunes to boost your music career through smooth and efficient distribution."
        imageurl="Images/why-fttunes.png"
      />
      <ServiceList
        services={services}
        heading="Digital Music Distribution Services in India"
      />
      <WhyFtTune
        headingbefore="Why"
        mark="FTtunes"
        headingafter="?"
        detail="In the competitive world of music, having the right distribution partner can make all the difference. FT Tunes is here to help you navigate the digital landscape with ease, providing the expertise and support you need to succeed."
        detail2="If you need dependable digital music distribution, we're here to help. Let us elevate your music and connect you with listeners worldwide."
        imageurl="Images/why-fttune.png"
      />
      <LastComponent
        headingbefore="Digital Distribution Services - "
        mark="Unlimited Releases"
        headingafter=""
        detail="Distribute My Music"
      />
      <OtherServices otherServices={otherServices} />
    </div>
  );
};

export default DigitalDistribution;
