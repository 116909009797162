import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Features = () => {
  const boxItems = [
    {
      //   id: "01",
      title: "Extensive Global Reach",
      description:
        "Our global music distribution services cover all major streaming platforms, including Spotify, Apple Music, Amazon Music and more. ",
      // link: "collections.html",
      marginTop: "mt-neg-45",
      marginRight: "mr-28",
    },
    {
      //   id: "02",
      title: "Easy and Transparent Distribution Process",
      description:
        "Selling your music globally shouldn’t be complicated. At FTtunes, we offer user-friendly platform that allows you to distribute your music effortlessly.",
      // link: "collections.html",
      marginTop: "mt-45",
      marginRight: "",
    },
    {
      //   id: "03",
      title: "Top Music Sales Solutions",
      description:
        "Our dedication to top music sales solutions includes FTtunes distribution and advanced marketing tools to effectively promote your music.",
      // link: "collections.html",
      marginTop: "mt-neg-60",
      marginRight: "mr-28",
    },
    {
      //   id: "04",
      title: "Keep Control of Your Music",
      description:
        "At FTtunes, we believe that artists should have full control over their music. That’s why we offer flexible distribution options that allow you to retain your rights and royalties. ",
      // link: "collections.html",
      marginTop: "mt-29",
      marginRight: "",
    },
  ];

  return (
    <section className="tf-section section-speciality">
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col xl={5} md={12}>
            <div className="relative">
              <h5
                className="leading-3_2 relative mb-3 before-content"
                style={{ lineHeight: "3.2", fontSize: "24px", color: "#fff" }}
              >
                Comprehensive Advantages
              </h5>
              <h3
                className="mb-4"
                style={{
                  marginBottom: "28px",
                  fontSize: "48px",
                  fontWeight: "700",
                  color: "#fff",
                }}
              >
                Elevate Your Music Career <mark>Without Limits</mark>
              </h3>
              <p
                className="text-21"
                style={{ fontSize: "21px", color: "#fff" }}
              >
                Discover unparalleled opportunities with our seamless music distribution services, designed to amplify your reach and maximize your potential.
              </p>
            </div>
          </Col>
          <Col xl={7} md={12}>
            <div className="section-speciality__box">
              {boxItems.map((item, index) => (
                <div
                  className={`featuresDiv2 ${item.marginTop} ${item.marginRight}`}
                >
                  <Card key={index} className={`box-item`}>
                    <h3>{item.id}</h3>
                    <Card.Link href={item.link} className="h5">
                      {item.title}
                    </Card.Link>
                    <Card.Text>{item.description}</Card.Text>
                  </Card>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
