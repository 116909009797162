import { Container, Row, Col, Button } from "react-bootstrap";
// import "./Banner.css"; // Import custom styles if necessary
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";

// const images = [
//   { img: "/Images/platform/Amazon-Music.png" },
//   { img: "/Images/platform/Apple-Music.png" },
//   { img: "/Images/platform/Wynk.png" },
//   { img: "/Images/platform/YouTube.png" },
//   { img: "/Images/platform/Audiomack.png" },
//   { img: "/Images/platform/Gaana.png" },
//   { img: "/Images/platform/JioSaavn.png" },
//   { img: "/Images/platform/Beatport.png" },
//   { img: "/Images/platform/Boomplay.png" },
//   { img: "/Images/platform/FLO.png" },
//   { img: "/Images/platform/TikTok.png" },
//   { img: "/Images/platform/Meta.png" },
//   { img: "/Images/platform/Napster.png" },
//   { img: "/Images/platform/VEVO.png" },
//   { img: "/Images/platform/Anghami.png" },
//   { img: "/Images/platform/Claro-Musica.png" },
//   { img: "/Images/platform/Deezer.png" },
//   { img: "/Images/platform/NetEase-Cloud-Music.png" },
//   { img: "/Images/platform/QQ-music.png" },
//   { img: "/Images/platform/SoundCloud.png" },
//   { img: "/Images/platform/TIDAL.png" },
//   { img: "/Images/platform/Boomplay.png" },
// ];

const images = [
  { img: "/Images/partner/amazon.webp" },
  { img: "/Images/partner/apple.webp" },
  { img: "/Images/partner/Deezer_logo.webp" },
  { img: "/Images/partner/Facebook.webp" },
  { img: "/Images/partner/instagram.webp" },
  { img: "/Images/partner/tiktok.webp" },
  { img: "/Images/partner/napster.webp" },
  { img: "/Images/partner/Pandora.webp" },
  { img: "/Images/partner/resso.webp" },
  { img: "/Images/partner/Spotify.webp" },
  { img: "/Images/partner/tidal-b.webp" },
  { img: "/Images/partner/vevo.webp" },
  { img: "/Images/partner/YouTube01.webp" },
];

const StoreStrip = () => {
  const navigate = useNavigate();

  return (
    <div
      className="position-relative py-3 px-3"
      style={{ borderBlock: "1px solid grey" }}
    >
      <div
        className="position-absolute top-0 start-0 w-100 h-100 bg-accent-color rounded-end-3"
        style={{ backgroundColor: "#000" }}
      />
      <div className="position-relative" style={{ zIndex: 2, color: "#fff" }}>
        <div
          className="small-text-22"
          style={{ color: "#fff", marginBottom: "30px" }}
        >
          GET YOUR MUSIC ON
        </div>
        <Swiper
          spaceBetween={30}
          //   centeredSlides={true}

          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          //   pagination={{
          //     clickable: true,
          //   }}
          //   navigation={true}
          modules={[Autoplay]}
          className="mySwiper"
          breakpoints={{
            "@0.00": {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 6,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 8,
              spaceBetween: 50,
            },
          }}
        >
          {images.map((data) => (
            <SwiperSlide>
              <img src={data.img} alt={data.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="small-text-22"
          style={{ color: "#fff", marginTop: "30px" }}
        >
          <Button
            className="gradient_border_btn"
            onClick={() => navigate("/music-stores")}
          >
            View All 155+ Stores
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StoreStrip;
