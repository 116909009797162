import React from "react";
import { Button, Col, Row } from "react-bootstrap";

const Intro = ({ headingbefore, mark, headingafter, detail, imageurl, imageAlt }) => {
  return (
    <div className="section-27 publishing_partner">
      <Row className="columns-15 " style={{alignItems:"center"}}>
        <Col md={6} className=" w-col-small-small-stack">
          <h2 className="heading-publishing-1">
            {headingbefore}{" "}
            <mark>
              <strong>{mark}</strong>
            </mark>{" "}
            {headingafter}
          </h2>
          <p className="paragraph-31">{detail}</p>
          {/* <Button
            logged-in-link="/en/signup/?subscription=6"
            href="/en/signup/?subscription=6"
            className="button-publishing-get-started w-button gradient_border_btn"
          >
            Get Started
          </Button> */}
        </Col>
        <Col md={6} className="  w-col-small-small-stack" style={{textAlign:"center"}}>
          <img
            // src={imageurl !== "undefined" ? imageurl : "https://cdn.prod.website-files.com/655e0fa544c67c1ee5ce01a4/655e0fa544c67c1ee5ce03c2_quote-img.webp"}
            src={imageurl}
            alt="why choose fttunes"
            // srcSet={imageurl !=="undefined" && "https://cdn.prod.website-files.com/655e0fa544c67c1ee5ce01a4/655e0fa544c67c1ee5ce03c2_quote-img-p-500.webp 500w, https://cdn.prod.website-files.com/655e0fa544c67c1ee5ce01a4/655e0fa544c67c1ee5ce03c2_quote-img-p-800.webp 800w, https://cdn.prod.website-files.com/655e0fa544c67c1ee5ce01a4/655e0fa544c67c1ee5ce03c2_quote-img.webp 1014w"}
            className="image-226"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Intro;
