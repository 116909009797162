import React from "react";
import NewReleaseForm from "./NewReleaseForm";

const CreateRelease = () => {
  return (
    <div className="content-body">
      {/* <div className="mcont mcont--home dashcont release"> */}
      <h1 style={{ width: "100%", textAlign: "center" }}>Upload Your Song</h1>
      <NewReleaseForm />
      {/* </div> */}
    </div>
  );
};

export default CreateRelease;
