import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./TopComponent.css";

const TopComponent = () => {
  return (
    <section
      id="CaO4Jcj7RzCx2ZhE5ommNw"
      className="section_section__I0u9H section_gradient__n5Qe_ section_header__XvyD1 section_alignCenter__x2Hse about_us_page"
      style={{
        // background: "linear-gradient(180deg, #fff, #f9f9f9)", // Example gradient
        // padding: "150px 0",
        // textAlign: "center",
      }}
    >
      <Container>
        <Row>
          <Col md={11} className="mx-auto">
            <h1 style={{color:"#fff", fontWeight:"900"}}>
            Leading <mark>Music Distribution</mark> Company in Delhi
            </h1>
            <p className="content-record_large__sQtlg">
            Welcome to FT Tunes, your premier partner in the music industry, where passion for music meets innovative business strategies. As a leading music distribution company, we are dedicated to helping artists, labels and content creators reach global audiences with unparalleled ease. At FT Tunes, we believe in the power of music to transcend boundaries and our mission is to ensure your work resonates across the world.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TopComponent;
