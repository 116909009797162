import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ServiceList.css";

const ServiceList = ({ services, heading }) => {
  return (
    <section
      className="sk__features-section sk__fade-in-10 sk__py-s dark-shade-3-bg text-center text-sm-start"
      data-sk-fade-match="1"
      //   style={{ paddingBottom: "150px" }}
    >
      <Container className="sk__supercontainer">
        <Row className="sk__heading-spacer-s">
          <Col>
            <h4 className="services_list_heading">
              <mark>{heading}</mark>
            </h4>
            <span className="fat-divider dark-shade-4-bg"></span>
          </Col>
        </Row>
        <Row
          className="sk__features delayed"
          style={{ justifyContent: "center" }}
        >
          {services.map((service, index) => (
            <Col md={4} className="sk__feature-col">
              <div className="featuresDiv">
                <div className="sk__feature">
                  <h5>{service.heading}</h5>
                  <div className="colorline-deco">
                    <div className="colorline-deco-normal sk__absolute"></div>
                    <div className="colorline-deco-hover sk__absolute sk__gradient-back-v1"></div>
                  </div>
                  <p>{service.detail}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="sk__features-subtext">
            <div className="features-subtext-deco"></div>
            <p className="p-xs text-center text-sm-end features-subtext-p">
              As a top digital music distribution company in India, FT Tunes
              offers a full range of services designed to give your music the
              exposure it deserves.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServiceList;
