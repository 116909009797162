import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import BannerStrip from "../Home/Hero/BannerStrip";
import { FaCheckCircle } from "react-icons/fa";
import BannerStripThankYou from "./BannerStripThankYou";

const ThankYouPage = () => {
  return (
    <Card
      style={{
        maxWidth: "600px",
        marginInline: "auto",
        marginBlock: "100px",
        borderRadius: "20px",
        overflow: "hidden",
      }}
    >
      <div style={{ paddingBlock: "0px", background: "#D97D87" }}>
        <div style={styles.page}>
          {/* Back to Homepage */}
          {/* <Container className="py-3">
          <Row>
            <Col>
              <Button
                as={Link}
                variant="link"
                to="/"
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontSize: "18px",
                }}
              >
                ← Back to Homepage
              </Button>
            </Col>
          </Row>
        </Container> */}

          {/* Main Content */}
          <Container className="text-center my-5">
            <Row>
              <Col>
                {/* Logo */}
                {/* <img
                src="/Images/ft-tunes.png"
                alt="Logo"
                style={{ width: "280px", height: "auto", marginBottom: "20px" }}
              /> */}
                <FaCheckCircle size={100} style={{ color: "green" }} />
                {/* Heading */}
                <h1 style={styles.heading}>
                  Congratulations for successful registeration on FTtunes!
                </h1>
                {/* Subheading */}
                {/* <p style={styles.subheading}>
                  Please note: Your profile is pending approval by the admin.
                  Once your profile is approved, you will receive an email
                  notification and be able to log in.
                </p> */}

                <Button as={Link} to="/artist/dashboard" style={{background:"#fff", marginBottom:"10px", border:"none", color:"#000"}}> Go To Dashboard</Button>

                {/* Video/Image Section */}
                {/* <div style={styles.videoPlaceholder}>
              <img
                src="/path-to-video-placeholder.png"
                alt="Video Placeholder"
                style={{ width: "100%", height: "auto", borderRadius: "10px" }}
              />
            </div> */}
                <BannerStripThankYou />

                {/* Link Section */}
                <p style={styles.linkSection}>
                  Sell Your Music Everywhere & Get Paid.{" "}
                  <a href="/" style={styles.link}>
                    Get Started Now →
                  </a>
                </p>
              </Col>
            </Row>
          </Container>

          {/* Footer */}
          {/* <footer style={styles.footer}>
          <Container>
            <Row>
              <Col className="text-center">
                <p>
                  Copyright © 2024 FTtunes All Rights Reserved. Design & Develop
                  By Eweblink Technology
                </p>
              </Col>
            </Row>
          </Container>
        </footer> */}
        </div>
      </div>
    </Card>
  );
};

// Styles
const styles = {
  page: {
    backgroundColor: "#D97D87", // Background color matching the image
    // minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "white",
  },
  heading: {
    fontSize: "2.5rem",
    fontWeight: "bold",
  },
  subheading: {
    fontSize: "1.2rem",
    marginBottom: "30px",
  },
  videoPlaceholder: {
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto 20px",
  },
  linkSection: {
    fontSize: "1.2rem",
    marginTop: "20px",
  },
  link: {
    color: "white",
    textDecoration: "underline",
  },
  footer: {
    padding: "10px 0",
    backgroundColor: "#C05454",
  },
};

export default ThankYouPage;
