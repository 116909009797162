import { Container, Row, Col, Image, Card } from "react-bootstrap";
// import "./MusicUpload.css";

const OurVision = () => {
  return (
    <Container fluid className="music-upload-cont">
      <div className="second-music-cont">
        <Row className="margin-bott">
          <Col xs={12} md={5}>
            <h3 className="display-3 font-weight-bold mb-3 musicupload-head-three">
              <span className="text-danger">
                <mark>Our Vision</mark>
              </span>
              {/* <span className="text-white"> Your Music Globally</span> */}
            </h3>
            <p className="text-light font-weight-semibold mb-3">
              At FT Tunes, our vision is to empower artists and music creators
              by providing them with the tools and platforms needed to succeed
              in an increasingly competitive industry. We aim to be the most
              trusted music management company globally, offering comprehensive
              services that cater to every aspect of an artist's career, from
              distribution to monetization.
            </p>
            <p className="text-light font-weight-semibold">
              Our goal is to build long-lasting relationships with our clients,
              guiding them on their journey to becoming global music icons.
            </p>
          </Col>
          <Col md={1} />
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
          >
            <Image
              src="https://t4.ftcdn.net/jpg/08/75/85/01/360_F_875850190_sJLXYk41kWkeFQTGPbyFveVAJ09gA2E4.jpg"
              alt="Music App"
              fluid
              className="rounded-3 shadow-lg music-upload-image"
            />
          </Col>
        </Row>

        {/* <Row className="margin-bott">
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
          >
            <Image
              src="/Images/markets.png"
              alt="DJ Party"
              fluid
              className="rounded-3 shadow-lg"
            />
          </Col>
          <Col md={1}/>
          <Col xs={12} md={5}>
            <h3 className="display-3 font-weight-bold mb-3 musicupload-head-three">
              <span className="text-danger">
                <mark>Market</mark>
              </span>
              <span className="text-white"> Your Music</span>
            </h3>
            <p className="text-light font-weight-semibold mb-3">
              You are at a high tide in the market of music. Let us help you
              ride the tide.
            </p>
            <p className="text-light font-weight-semibold">
              Don’t just publish your music. Get noticed. And not just get
              noticed, create your own fan base with followers, subscribers, and
              viewers. Let them go gaga over your music. Brace up your music
              marketing with our tools.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={5}>
            <h3 className="display-3 font-weight-bold mb-3">
              <span className="text-danger">
                <mark>Own</mark>
              </span>
              <span className="text-white"> Your Music</span>
            </h3>
            <p className="text-light font-weight-semibold mb-3">
              Be the author of your music.
            </p>
            <p className="text-light font-weight-semibold">
              We believe in the artists’ independence in their creations. We
              believe in you as much as you believe in yourself.
            </p>
          </Col>
          <Col md={1}/>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
          >
            <Image
              src="/Images/tech.png"
              alt="Writing"
              fluid
              className="rounded-3 shadow-lg"
            />
          </Col>
        </Row> */}
      </div>
    </Container>
  );
};

export default OurVision;
