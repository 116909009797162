import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { FaSpinner, FaQuestionCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";

const Checkout = () => {
  const amount = 4999;
  const { plan } = useParams();
  const url = decodeURIComponent(plan);
  return (
    <Container className="checkout-page text-light py-5">
      {/* <Row> */}
      <section id="cart" className="row">
        <Col md={9} className="">
          <header className="row">
            <Col md={8}>
              <h2>You have 1 item in your cart.</h2>
            </Col>
          </header>

          <nav className="row my-3">
            <Col md={12} className="text-right">
              {/* <span className="loading-cart-spinner mr-3">
                <FaSpinner className="fa-spin" aria-hidden="true" />{" "}
                Processing...
              </span> */}
              <Button
                id="checkout-proceed"
                className="button-callout btn-light"
                href={url}
                target="_blank"
                style={{
                  background: "transparent",
                  padding: "5px 10px",
                  border: "1px solid #fff",
                  borderRadius: "25px",
                }}
              >
                Proceed To Checkout
              </Button>

              <div className="cart-terms-link mt-2">
                *By clicking "Proceed to Checkout", I agree to the{" "}
                <a href="/terms-of-service" className="text-info">
                  Terms and Conditions
                </a>
              </div>
            </Col>
          </nav>

          <div className="cart_items">
            <header className="row">
              <Col md={12}>
                <h3>Order Summary</h3>
              </Col>
            </header>

            <div className="cart_items_content mt-3">
              <Row className="cart_item_headers py-2 bg-secondary text-light">
                <Col md={6}>Item</Col>
                <Col md={3}>Item Price</Col>
                {/* <Col md={2} className="text-center">
                  Actions
                </Col> */}
                <Col md={3}>Price</Col>
              </Row>

              {/* <Card className="purchase-item cart_item text-light my-3"> */}
              <Row
                className="align-items-center bg-dark"
                style={{ borderBottom: "1px solid grey" }}
              >
                {/* <Col md={1}>
                    <img
                      alt=""
                      src="/images/cart_icons/plan_3.png"
                      className="img-fluid"
                    />
                  </Col> */}
                <Col md={6}>
                  <h4>Pro Artist - Unlimited Plan</h4>
                  <p>
                    Add upto 3 Artists. Unlimited Releases to 155+ Indian &
                    International Plate forms, Caller Tunes (Airtel, Jio, VI,
                    BSNL), Free YouTube Content ID. Keep 100% of total revenue.
                  </p>
                </Col>
                <Col md={3}>₹{amount}</Col>
                {/* <Col md={2} className="text-center"> */}
                {/* <Form method="post" action="/purchases/35246087">
                      <Button
                        variant="link"
                        className="text-danger"
                        type="submit"
                      >
                        Remove Item
                      </Button>
                    </Form>
                    <a
                      href="/cart/purchases/35246087/cert/new"
                      className="link text-info"
                    >
                      Apply Promo Code
                    </a>

                    <div className="box-promo hide mt-2">
                      <InputGroup>
                        <Form.Control
                          type="text"
                          id="purchase_cert_35246087"
                          placeholder="Enter Promo Code"
                        />
                        <Button variant="success" className="ml-2">
                          Apply
                        </Button>
                        <Button variant="danger" className="ml-2">
                          Cancel
                        </Button>
                      </InputGroup>
                    </div> */}
                {/* </Col> */}
                <Col md={3} className="vat-tooltip-containment">
                  <strong>
                    ₹{amount}{" "}
                    {/* <FaQuestionCircle data-tooltip="Price charged: ₹3066.86" /> */}
                  </strong>
                  <div className="vat_tooltip">
                    In order to comply with EU regulations, FTtunes is
                    responsible for placing a value-added tax (VAT Tax) on your
                    purchase if you are located in the EU.
                  </div>
                </Col>
              </Row>
              {/* </Card> */}

              <Row
                className="cart-subtotal py-3 bg-dark text-light"
                style={{ borderBottom: "1px solid grey" }}
              >
                <Col md={7}></Col>
                <Col md={2} className="cart-subtotal_txt">
                  * SubTotal
                </Col>
                <Col md={3} className="cart-total_price">
                  ₹{((amount * 100) / 118).toFixed(2)}{" "}
                  {/* <FaQuestionCircle data-tooltip="Price charged: ₹3066.86" /> */}
                </Col>
              </Row>
              <Row
                className="cart-subtotal py-3 bg-dark text-light"
                style={{ borderBottom: "1px solid grey" }}
              >
                <Col md={7}></Col>
                <Col md={2} className="cart-subtotal_txt">
                  * IGST(18%)
                </Col>
                <Col md={3} className="cart-total_price">
                  ₹{((amount * 18) / 118).toFixed(2)}{" "}
                  {/* <FaQuestionCircle data-tooltip="Price charged: ₹3066.86" /> */}
                </Col>
              </Row>
              <Row
                className="cart-subtotal py-3 bg-dark text-light"
                style={{ borderBottom: "1px solid grey" }}
              >
                <Col md={7}></Col>
                <Col md={2} className="cart-subtotal_txt">
                  * Grand Total
                </Col>
                <Col md={3} className="cart-total_price">
                  ₹{amount}{" "}
                  {/* <FaQuestionCircle data-tooltip="Price charged: ₹3066.86" /> */}
                </Col>
              </Row>

              <nav className="row mt-4">
                <Col md={12} className="text-center">
                  {/* <span className="loading-cart-spinner">
                    <FaSpinner className="fa-spin" aria-hidden="true" />{" "}
                    Processing...
                  </span> */}
                  <Button
                    id="checkout-proceed"
                    className="button-callout btn-light ml-3"
                    href={url}
                    target="_blank"
                    style={{
                      background: "transparent",
                      padding: "5px 10px",
                      border: "1px solid #fff",
                      borderRadius: "25px",
                    }}
                  >
                    Proceed To Checkout
                  </Button>

                  <div className="cart-terms-link mt-2">
                    *By clicking "Proceed to Checkout", I agree to the{" "}
                    <a href="/terms-of-service" className="text-info">
                      Terms and Conditions
                    </a>
                  </div>
                </Col>
              </nav>
            </div>
          </div>
        </Col>
        {/* <aside className="columns two"></aside> */}
        <Col md={3}>
          <div className="included" style={{ padding: "20px" }}>
            <h3 style={{ textAlign: "center" }}>BENEFITS</h3>
            <ul className="clearfix" style={{ fontSize: "smaller" }}>
              <li>Unlimited Releases</li>
              <li>3 Artists</li>
              <li>155+ Indian & International Plateforms</li>
              <li>CallerTunes (Airtel, Jio, VI, BSNL)</li>
              <li>Within 48 Working hrs Delivery ( Prepaid Orders )</li>
              <li>100% Revenue Share</li>
              <li>Priority Customer Support</li>
            </ul>
          </div>

          <aside id="plan-promo">
            <div className="upgrade-block">
              <div className="heading">
                Add Your Releases to{" "}
                <span className="emphasis">155+ Digital Stores</span>
              </div>
              <div className="text-center">
                <Button
                  className="cta"
                  href="/plans"
                  id="BTN_plan_upgrade_sidebar"
                >
                  UPGRADE NOW
                </Button>
              </div>
            </div>
          </aside>
        </Col>
      </section>
      {/* </Row> */}
    </Container>
  );
};

export default Checkout;
