import React from "react";
import EditReleaseForm from "./EditReleaseForm";

const EditRelease = () => {
  return (
    <div className="content-body">
      {/* <div className="mcont mcont--home dashcont release"> */}
      <h1 style={{ width: "100%", textAlign: "center" }}>Edit Your Song</h1>
      <EditReleaseForm />
      {/* </div> */}
    </div>
  );
};

export default EditRelease;
