import React from "react";
import PaymentOptions from "./PaymentOptions";

const Payments = () => {
  return (
    <div className="page-container">
      <div className="page-wrapper">
        <div className="page-title" style={{ fontWeight: 600 }}>
          <mark>Pay Online</mark>
        </div>
        <div className="page-content page-contact page-payment">
          <PaymentOptions />
        </div>
      </div>
    </div>
  );
};

export default Payments;
