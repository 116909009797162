import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To get paymentId from URL
import { Container, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Success and Failed icons
import axios from "axios"; // For API call
import "./PaymentSuccess.css"; // Custom CSS
import { InvoiceTemplate } from "./agent-dashboard/Profile/Profile2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { createRoot } from "react-dom/client";

const PaymentSuccess = () => {
  //   const { paymentId: encodedPaymentId } = useParams(); // Get the encoded paymentId from URL params
  //   const paymentId = decodeURIComponent(encodedPaymentId); // Decode the paymentId
  const { paymentId } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null); // State to store payment details
  const [loading, setLoading] = useState(true); // State to handle loading

  // Fetch payment details by paymentId
  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/order/${paymentId}` // Adjust the endpoint as per your backend route
        );
        console.log("response", response);
        setPaymentDetails(response.data.paymentDetails); // Set the payment details
      } catch (error) {
        console.error("Error fetching payment details:", error);
      } finally {
        setLoading(false); // Stop loading when the API call is done
      }
    };

    fetchPaymentDetails();
  }, [paymentId]);

  // Render Loading Spinner while fetching data
  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  // Handle the case where no payment details are found
  if (!paymentDetails) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <h3 className="text-danger">No payment details found.</h3>
      </Container>
    );
  }

  // Check if the payment is successful or failed
  const isSuccess = paymentDetails.status === 1;

  const generatePDF = (payment) => {
    const container = document.createElement("div");
    document.body.appendChild(container);

    const root = createRoot(container);
    root.render(<InvoiceTemplate payment={payment} />);

    // Use a timeout to ensure rendering is complete
    setTimeout(() => {
      const ticketDataElement = container.querySelector(".invoicedata");

      if (ticketDataElement) {
        html2canvas(ticketDataElement, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("");
          const doc = new jsPDF("p", "mm", "a4");
          const componentWidth = doc.internal.pageSize.getWidth();
          const componentHeight = doc.internal.pageSize.getHeight();
          const imageWidth = canvas.width;
          const imageHeight = canvas.height;
          const ratio = Math.min(
            componentWidth / imageWidth,
            componentHeight / imageHeight
          );
          const imageX = componentWidth - imageWidth * ratio;
          const imageY = 10;
          doc.addImage(
            imgData,
            "PNG",
            imageX,
            imageY,
            imageWidth * ratio,
            imageHeight * ratio
          );
          doc.save(`Invoice_${payment.id}.pdf`);

          // Clean up the container and unmount the component
          root.unmount();
          document.body.removeChild(container);
        });
      } else {
        console.error("Element with class 'ticketdata' not found");
        root.unmount();
        document.body.removeChild(container);
      }
    }, 100); // Adjust the timeout duration if necessary
  };

  return (
    <div style={{ paddingBlock: "150px", background: "#D97D87" }}>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Row style={{ justifyContent: "center" }}>
          {/* Dark Theme Card */}
          <Col md={8} lg={6}>
            <Card className="text-center shadow-lg payment-card">
              <Card.Body className="bg-dark text-white p-4 rounded">
                {/* Success or Failed Icon and Title */}
                {isSuccess ? (
                  <FaCheckCircle size={50} color="green" className="mb-3" />
                ) : (
                  <FaTimesCircle size={50} color="red" className="mb-3" />
                )}

                <Card.Title className="mb-3">
                  {isSuccess ? "Payment Success!" : "Payment Failed!"}
                </Card.Title>
                <p className="text-muted">
                  {isSuccess
                    ? "Your payment has been successfully done."
                    : "There was an issue with your payment."}
                </p>

                {/* Payment Amount */}
                <h2 className="fw-bold my-4">INR {paymentDetails.amount}</h2>

                {/* Divider */}
                <hr className="my-4" />

                {/* Reference Info Boxes */}
                <Row className="text-start">
                  <Col xs={12} className="mb-3">
                    <div className="info-box">
                      <p className="info-label">Razorpay Order ID</p>
                      <p className="info-value">
                        {paymentDetails.razorpay_order_id}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <div className="info-box">
                      <p className="info-label">Razorpay Payment ID</p>
                      <p className="info-value">
                        {paymentDetails.razorpay_payment_id}
                      </p>
                    </div>
                  </Col>
                  {/* <Col xs={12} className="mb-3">
                    <div className="info-box">
                      <p className="info-label">Payment Method</p>
                      <p className="info-value">Bank Transfer</p>
                    </div>
                  </Col> */}
                  <Col xs={12} className="mb-3">
                    <div className="info-box">
                      <p className="info-label">Sender Id</p>
                      <p className="info-value">{paymentDetails.emailId}</p>
                    </div>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <div className="info-box">
                      <p className="info-label">Plan Purchased</p>
                      <p className="info-value">
                        {paymentDetails.plan_purchased}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <div className="info-box">
                      <p className="info-label">Phone</p>
                      <p className="info-value">{paymentDetails.phone}</p>
                    </div>
                  </Col>
                </Row>

                {/* Get PDF Button */}
                {isSuccess && (
                  <Button
                    variant="outline-light"
                    className="mt-4"
                    onClick={() => generatePDF(paymentDetails)}
                  >
                    Get PDF Receipt
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentSuccess;
