import React from "react";
import AllAlbumsTable from "../Dashboard/AllAlbumsTable";
import { Container } from "react-bootstrap";

const Releases = () => {
  return (
    <div className="content-body">
      <Container>
        <AllAlbumsTable />
      </Container>
    </div>
  );
};

export default Releases;
