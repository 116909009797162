import React, { useState } from "react";
import { Button, Form, Col, Row, Modal, Alert, Spinner } from "react-bootstrap";
import "./SignIn.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Modal states
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Password reset states
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      alert("Both username and password are required.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://fttunes.com/api/artist/login",
        { artistIdOrEmail: username, password }
      );

      if (response.data.success) {
        localStorage.setItem("token", response.data.token);
        const artistResponse = await axios.get(
          `https://fttunes.com/api/artists/${response.data.artist.artistId}`
        );
        localStorage.setItem("profile", JSON.stringify(artistResponse.data));
        navigate("/artist/dashboard");
      } else {
        alert("Login failed: " + response.data.message);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      alert("An error occurred during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleforgotpassword = async (e) => {
    e.preventDefault();
    setLoading2(true);
    setMessage("");
    setError("");

    try {
      console.log("username artistidoremail", username);
      const response = await axios.post(
        "https://fttunes.com/api/artist/forgot-password",
        { artistIdOrEmail: username } // Use the username for recovery
      );
      console.log("forgotpassword response", response);
      setMessage(response.data.message);
      setShowModal4(true); // Show the reset password modal after sending OTP
    } catch (err) {
      setError(
        err.response?.data.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading2(false);
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
      setPasswordMatch(value === verifyNewPassword);
    } else if (name === "verifyNewPassword") {
      setVerifyNewPassword(value);
      setPasswordMatch(value === newPassword);
    }
  };

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();

    if (!otp || !newPassword || !verifyNewPassword) {
      alert("Please fill in all fields.");
      return;
    }

    if (!passwordMatch) {
      alert("New passwords do not match!");
      return;
    }

    try {
      const response = await axios.post(
        "https://fttunes.com/api/artist/reset-password",
        { email: username, otp, newPassword }
      );

      if (response.data.success) {
        alert("Password changed successfully!");
        setShowModal4(false); // Close the modal on success
        setShowModal3(false);
      } else {
        alert("Failed to change password: " + response.data.message);
      }
    } catch (error) {
      // Check if the error response contains a message
      const errorMessage =
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again.";
      console.error("Error changing password:", errorMessage);
      alert(errorMessage);
    }
  };

  return (
    <div className="signin_page">
      <section
        id="rnb_sign_in"
        className="rnb-flex rnb-flex-row rnb-justify-between"
      >
        <Col md={5}>
          {/* <div className="rnb_sign_in_banner rnb-fixed rnb-h-screen"></div>

        <div className="rnb_sign_in_banner"></div> */}
          <img
            src="https://cdn.sanity.io/images/ja0kpdae/production/dbcdc51d411559071c0c5683500ab782085b5782-2000x1526.png"
            alt="signin"
            className="sign_in_image"
            style={{ background: "#563a49" }}
          />
        </Col>

        <Col md={7} style={{ paddingBottom: "40px" }}>
          <div style={{ minHeight: "100vh" }}>
            <div
              className="rnb_sign_in_login rnb-flex rnb-flex-row rnb-justify-betwee rnb-items-center rnb-absolute rnb-right-0 rnb_sign_in_inner"
              style={{ marginInline: "auto", justifyContent: "flex-end" }}
            >
              <p className="rnb_p_lb" style={{ marginBlock: "0px" }}>
                Don't have an account?
              </p>

              <Link
                to="/signup"
                className="rnb_a rnb-text-inherit rnb_border_button rnb_move_up"
              >
                <Button className="rnb_rectangle_button rnb_h6 gradient_border_btn">
                  Sign Up
                </Button>
              </Link>
            </div>

            <div className="rnb_sign_in_inner rnb-flex-1 rnb-mx-auto form_card ">
              <h3 className="rnb_h3 rnb_text_blue rnb-text-center">
                <mark>Sign in to your account</mark>
              </h3>

              <Form
                onSubmit={handleSubmit}
                className="rnb_sign_in_form rnb_margin_top_md rnb-flex rnb-flex-col rnb-items-end"
              >
                <div className="rnb-flex rnb-flex-col rnb-justify-start rnb-w-full">
                  <Form.Group>
                    <Form.Label className="rnb_h7 rnb_text_blue rnb_margin_top_md">
                      Email / FTtunes ID
                    </Form.Label>
                    <Form.Control
                      className="rnb_input rnb_bg_grey rnb_p_lb rnb_text_darker_grey rnb_margin_top_xs"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your Email or FTtunes ID"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="rnb_h7 rnb_text_blue rnb_margin_top_md">
                      Password
                    </Form.Label>
                    <Form.Control
                      className="rnb_input rnb_bg_grey rnb_p_lb rnb_text_darker_grey rnb_margin_top_xs"
                      type="password"
                      id="pass"
                      name="pass"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </div>

                <p
                  style={{
                    textAlign: "left",
                    width: "100%",
                    marginBlock: "20px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowModal3(true)}
                >
                  {" "}
                  Forgot your password?
                </p>

                <Button
                  type="submit"
                  className="rnb_rectangle_button rnb_h6 rnb_margin_top_md gradient_border_btn"
                  style={{ padding: "15px 30px", marginTop: "10px" }}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </Form>

              {/* <div className="rnb-w-full rnb-flex rnb-flex-row rnb-justify-center rnb-items-center rnb_margin_top_md">
          <span className="rnb_divider_line rnb-w-full rnb-flex-1"></span>
          <a
            href="https://www.routenote.com/rn/?q=user/password"
            target="blank"
            className="rnb_a rnb-flex rnb-flex-row rnb-justify-start rnb-items-center rnb_darken"
          >
            <span className="rnb_h7 rnb_text_blue">Forgotten password</span>
          </a>
          <span className="rnb_divider_line rnb-w-full rnb-flex-1"></span>
        </div> */}
            </div>
          </div>
        </Col>
      </section>

      <Modal
        show={showModal3}
        onHide={() => setShowModal3(false)}
        className="popupCtn"
        style={{ zIndex: "9999" }}
        size="lg"
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Forgot Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleforgotpassword}>
            <Form.Group>
              <Form.Label className="font14 latoBold blackText">
                Email
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Email or FTtunes ID"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" disabled={loading2} className="mt-3 modal_btn" style={{padding:"10px 20px"}}>
              {loading2 ? <Spinner animation="border" size="sm" /> : "Verify"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModal4}
        onHide={() => setShowModal4(false)}
        className="popupCtn"
        size="lg"
        style={{ zIndex: "9999" }}
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font26 latoBlack blackText">
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleChangePasswordSubmit}>
            <Form.Group>
              <Form.Label className="font14 latoBold blackText">OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="font14 latoBold blackText">
                New Password
              </Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                value={newPassword}
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="font14 latoBold blackText">
                Confirm New Password
              </Form.Label>
              <Form.Control
                type="password"
                name="verifyNewPassword"
                placeholder="Confirm new password"
                value={verifyNewPassword}
                onChange={handlePasswordChange}
                isInvalid={!passwordMatch}
              />
              <Form.Control.Feedback type="invalid">
                New passwords do not match!
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit" disabled={loading2} className="mt-3 modal_btn">
              {loading2 ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Change Password"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SignIn;
