import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Hero from "./components/Home/Hero/Hero";
import Price from "./components/Price/Price";
import SignUp from "./components/Signup/SignUp";
import OutStore from "./components/OutStore/OutStore";
import Comming from "./Comming";
import ContactUs from "./components/Support/ContactUs";
import MusicPublishing from "./components/Services/MusicPublishing/MusicPublishing";
import Services from "./components/Services/Services";
import RightsManagement from "./components/Services/RightsManagement/RightsManagement";
import YoutubeMonetisation from "./components/Services/YoutubeMonetisation/YoutubeMonetisation";
import DigitalDistribution from "./components/Services/DigitalDistribution/DigitalDistribution";
import About from "./components/About/About";
import SellMusic from "./components/SellMusic/SellMusic";
import MusicDistribution from "./components/MusicDistribution/MusicDistribution";
import TNC from "./components/TncPrivacyPolicy/TNC";
import PrivacyPolicy from "./components/TncPrivacyPolicy/PrivacyPolicy";
import { useEffect } from "react";
import ScrollToTopButton from "./ScrollToTop";
import MetaRoute from "./MetaRoute";
import YoutubeContentId from "./components/Services/YoutubeContentId/YoutubeContentId";
// import Navbar from "./agent-dashboard/Common/AgentNavbar";
import Footers from "./components/common/Footers/Footer";
import Footer from "./agent-dashboard/Common/Footer";
import Navbars from "./components/common/navbar/Navbars";
import AgentNavbars from "./agent-dashboard/Common/AgentNavbars";
import Dashboard from "./agent-dashboard/Dashboard/Dashboard";
import Releases from "./agent-dashboard/Support/Releases";
import CreateRelease from "./agent-dashboard/Distribution/CreateRelease";
import IncompleteReleases from "./agent-dashboard/Distribution/IncompleteReleases";
import Payouts from "./agent-dashboard/Payouts/Payouts";
import Overview from "./agent-dashboard/Reports/Overview";
import SignIn from "./components/Signup/SignIn";
import Profile from "./agent-dashboard/Profile/Profile";
import ProtectedRoute from "./ProtectedRoute";
import EditRelease from "./agent-dashboard/Distribution/EditRelease";
import AlbumDetailPage from "./agent-dashboard/AlbumDetailPage";
import Profile2, { InvoiceTemplate } from "./agent-dashboard/Profile/Profile2";
import Payments from "./components/Payments/Payments";
import PaymentForm from "./PaymentForm";
import Plans from "./agent-dashboard/Dashboard/Plans";
import Checkout from "./agent-dashboard/Dashboard/Checkout";
import ThankYouPage from "./components/Signup/ThankYouPage";
import PaymentSuccess from "./PaymentSuccess";
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const location = useLocation();
  const isArtistRoute = location.pathname.includes("/artist/");

  function Navbarr() {
    const location = useLocation();
    // const isAgentRegistration = location.pathname === "/agent-registration";
    const isArtistRoute = location.pathname.includes("/artist/");
    // !location.pathname.startsWith("/agent/login");

    if (
      location.pathname === "/signup" ||
      location.pathname === "/signin" ||
      // location.pathname === "/thank-you" ||
      location.pathname === "/artist/plans"
    ) {
      return null; // Return null to hide the footer
    }
    return isArtistRoute ? <AgentNavbars /> : <Navbars />;
  }

  function Footerr() {
    const location = useLocation();
    // const isDashboard = location.pathname === "/agent/dashboard";
    // const isAgentRegistration = location.pathname === "/agent-registration";
    const isArtistRoute = location.pathname.includes("/artist/");

    if (location.pathname === "/signup" || location.pathname === "/signin" 
      // || location.pathname === "/thank-you"
    ) {
      return null; // Return null to hide the footer
    }
    return isArtistRoute ? (
      // <Footer />
      ""
    ) : (
      <Footers />
    );

    // return <Footer />;
  }

  return (
    <div className="App" style={{ background: "#000" }}>
      <Navbarr />
      <Routes>
        <Route
          path="/"
          element={
            <MetaRoute
              title="Sell Your Music- Global Music Distribution Services | FT Tunes"
              description="Sell your music worldwide with FT Tunes, distribute to Spotify, JioSaavn, Wynk, YouTube, Hungama and more with our global music distribution services."
            >
              <Hero />
            </MetaRoute>
          }
        />
        <Route
          path="/music-distribution-pricing"
          element={
            <MetaRoute
              title="Affordable Music Distribution Pricing & Services | FT Tunes"
              description="Discover FT Tunes for competitive music distribution prices. Our expert music distribution services ensure your tracks reach global audiences affordably."
            >
              <Price />
            </MetaRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <MetaRoute
              title="Sign Up with FT Tunes for Worldwide Music Distribution Services | FT Tunes"
              description="Sign up with FT Tunes for top-tier music distribution and global music distribution services and get your music heard worldwide."
            >
              <SignUp />
            </MetaRoute>
          }
        />
        <Route
          path="/thank-you"
          element={
              <ThankYouPage />
          }
        />
        <Route
          path="/payment-success/:paymentId"
          element={
              <PaymentSuccess />
          }
        />
        <Route
          path="/signin"
          element={
            <MetaRoute
              title="Sign In with FT Tunes for Worldwide Music Distribution Services | FT Tunes"
              description="Sign in with FT Tunes for top-tier music distribution and global music distribution services and get your music heard worldwide."
            >
              <SignIn />
            </MetaRoute>
          }
        />
        <Route
          path="/about"
          element={
            <MetaRoute
              title="Leading Music Distribution Company in India | FT Tunes"
              description="FT Tunes is a leading music distribution company in India, offering top-notch music distribution services to elevate your sound to global audiences."
            >
              <About />
            </MetaRoute>
          }
        />
        <Route
          path="/music-stores"
          element={
            <MetaRoute
              title="Trusted Music Distribution Partners | FT Tunes"
              description="Your trusted music distribution partner for YouTube, Wynk Music, Gaana, Amazon Music, Apple Music and more. Reach global audiences with ease."
            >
              <OutStore />
            </MetaRoute>
          }
        />
        <Route
          path="/music-distribution-services"
          element={
            <MetaRoute
              title="Best Music Distribution Services in India | FT Tunes"
              description="FT Tunes offers the best music distribution services in India. Our digital music distribution solutions ensure your tracks reach a global audience seamlessly."
            >
              <Services />
            </MetaRoute>
          }
        />
        <Route
          path="/sell-your-music"
          element={
            <MetaRoute
              title="Sell Your Music Worldwide with Top Music Sales Solutions | FT Tunes"
              description="Sell your music worldwide with FT Tunes, use our music sales solutions to sell your music online and reach a global audience effortlessly."
            >
              <SellMusic />
            </MetaRoute>
          }
        />
        <Route
          path="/music-distribution"
          element={
            <MetaRoute
              title="Top Music Distribution Services & Solutions | FT Tunes"
              description="FT Tunes offers top-tier music distribution solutions. Our digital music distribution services ensure your tracks reach the right audience with ease."
            >
              <MusicDistribution />
            </MetaRoute>
          }
        />
        <Route
          path="/contact-us"
          element={
            <MetaRoute
              title="Contact FT Tunes: Leading Music Distribution Company | Digital & Global Music Distribution Services"
              description="Contact FT Tunes, your trusted music distribution company, for top-notch digital and global music distribution services. Reach out for seamless distribution."
            >
              <ContactUs />
            </MetaRoute>
          }
        />
        <Route
          path="/music-right-management"
          element={
            <MetaRoute
              title="Best Music Right Management Company in India | FT Tunes"
              description="The best music rights management company in India, offering expert music rights services to protect and manage your music globally."
            >
              <RightsManagement />
            </MetaRoute>
          }
        />
        <Route
          path="/music-publishing"
          element={
            <MetaRoute
              title="Best Music Publishing Services in India | FT Tunes"
              description="FT Tunes offers the best music publishing services in India, ensuring your work is protected and reaches the right audience. Trust our expert publishing services."
            >
              <MusicPublishing />
            </MetaRoute>
          }
        />
        <Route
          path="/youtube-monetization"
          element={
            <MetaRoute
              title="Leading YouTube Monetization Services in India | FT Tunes"
              description="FT Tunes offers leading YouTube Monetization services in India. Maximize earnings with the best YouTube monetization services tailored to your needs."
            >
              <YoutubeMonetisation />
            </MetaRoute>
          }
        />
        <Route
          path="/digital-distribution"
          element={
            <MetaRoute
              title="Premier Global Music Distribution Services | FT Tunes"
              description="FT Tunes provides top-tier global music distribution services, offering digital music distribution and global music promotion to expand your reach worldwide."
            >
              <DigitalDistribution />
            </MetaRoute>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <MetaRoute
              title="FT Tunes Terms and Conditions | Music Distribution Solutions"
              description="FT Tunes terms and conditions detail the use of our worldwide music distribution services for a seamless experience."
            >
              <TNC />
            </MetaRoute>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <MetaRoute
              title="FT Tunes Privacy Policy | Global Music Distribution Company & Music Distribution Partners"
              description="FT Tunes privacy policy outlines how we protect your data as a global music distribution company and trusted music distribution partners."
            >
              <PrivacyPolicy />
            </MetaRoute>
          }
        />
        <Route
          path="/youtube-content-id"
          element={
            <MetaRoute
              title="Premier YouTube Content ID Services | Expert Management and Monetization for YouTube Content ID"
              description="FT Tunes offers expert YouTube Content ID management services, ensuring your music rights are protected. Trust us for seamless YouTube content ID management."
            >
              <YoutubeContentId />
            </MetaRoute>
          }
        />
        <Route
          path="/payonline"
          element={
            // <MetaRoute
            //   title="Premier YouTube Content ID Services | Expert Management and Monetization for YouTube Content ID"
            //   description="FT Tunes offers expert YouTube Content ID management services, ensuring your music rights are protected. Trust us for seamless YouTube content ID management."
            // >
            <Payments />
            // </MetaRoute>
          }
        />
        {/* <Route
          path="/comming-soon"
          element={
            <MetaRoute
              title="Coming Soon | FT Tunes"
              description="Stay tuned for exciting new services and features coming soon to FT Tunes, your trusted music distribution partner."
            >
              <Comming />
            </MetaRoute>
          }
        /> */}
        {/* <Route path="/artist/test" element={<AgentNavbars />} /> */}
        <Route
          path="/artist/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/releases"
          element={
            <ProtectedRoute>
              <Releases />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/releases/create"
          element={
            <ProtectedRoute>
              <CreateRelease />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/releases/edit/:id"
          element={
            <ProtectedRoute>
              <EditRelease />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/incomplete-releases"
          element={
            <ProtectedRoute>
              <IncompleteReleases />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/payouts"
          element={
            <ProtectedRoute>
              <Payouts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/reports"
          element={
            <ProtectedRoute>
              <Overview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/profile"
          element={
            <ProtectedRoute>
              <Profile2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/album/:id"
          element={
            <ProtectedRoute>
              <AlbumDetailPage />
            </ProtectedRoute>
          }
        />

        {/* <Route
          path="/test"
          element={
            // <ProtectedRoute>
            <PaymentForm />
            // </ProtectedRoute>
          }
        /> */}

        <Route
          path="/artist/plans"
          element={
            <ProtectedRoute>
              <Plans />
            </ProtectedRoute>
          }
        />
        <Route
          path="/artist/cart/:plan"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />

        <Route
          path="/test"
          element={
            // <ProtectedRoute>
            // <InvoiceTemplate
            //   payment={{
            //     id: 22,
            //     razorpay_order_id: "order_P7GWn4fc2M5rNs",
            //     razorpay_payment_id: "pay_P7GWyetSoqiyCU",
            //     amount: 9999,
            //     status: 1,
            //     created_at: "2024-10-10T08:16:39.000Z",
            //     updated_at: "2024-10-10T08:16:39.000Z",
            //     artist_id: "FTT2024007",
            //     plan_purchased: "Record Label",
            //     custom_reason: "Payment for Record Label",
            //     emailId: "keshav@gmail.com",
            //     phone: "9876543210",
            //   }}
            // />
            // </ProtectedRoute>
            <PaymentForm />
          }
        />
      </Routes>
      <ScrollToTopButton />
      <Footerr />
    </div>
  );
}

export default App;
