import React from "react";
import "./Testi.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { Container, Row } from "react-bootstrap";
const Testimonial = () => {
  const testimonial = [
    {
      name: "Manish Albela",
      review:
        "FTtunes transformed my music journey in ways I never imagined. Their dedication and expertise made my dreams come true. I highly recommend FTtunes to any artist looking to take their career to the next level.",
      src: "/Images/hall-of-fame/manish-albela.jpg",
    },
    {
      name: "Micle Amit",
      review:
        "FTtunes has been a game-changer for me. They’ve helped me reach audiences I never thought possible. Their support and passion for music have made all the difference in my career.",
      src: "/Images/hall-of-fame/micle-amit.jpg",
    },
    {
      name: "Rajeshwari Singh",
      review:
        "Thanks to FTtunes, my music is now heard around the world. They provided the platform and tools I needed to grow. I couldn’t be happier with their service and highly recommend them.",
      src: "/Images/hall-of-fame/rajeshwari-singh.jpg",
    },
    {
      name: "Ritik Gupta",
      review:
        "FTtunes is more than a distribution company; they're partners in my artistic journey. Their commitment to helping artists succeed has made them invaluable to my music career.",
      src: "/Images/hall-of-fame/ritik-gupta.jpg",
    },
    {
      name: "Roy Razneesh",
      review:
        "Working with FTtunes has been one of the best decisions for my music. Their team is passionate and truly cares about artists' success. I’m grateful for their support in building my brand.",
      src: "/Images/hall-of-fame/roy-razneesh.jpg",
    },
    {
      name: "Ayesha Rain",
      review:
        "FTtunes has been instrumental in my growth as an artist. Their dedication to my success is evident in everything they do. I’m thrilled to have them by my side as I continue to reach new heights.",
      src: "/Images/hall-of-fame/ayesha-rain.jpg",
    },
  ];

  return (
    <div>
      <section
        id="ACigVCuNRPmXvPGazI0YCQ"
        className="section_section__I0u9H section_alignCenter__x2Hse"
      >
        <Container className="testimonial-containerr">
          <Row className="content_content__hiCNB content_lg__0npCb content_marginAuto__3jHEC">
            <h2>
              What Are <mark>Artists</mark> Saying About FTtunes?
            </h2>
            <div className="carousel_carouselContainer__Dx3W8"></div>
          </Row>
          <Swiper
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >
            {testimonial.map((testimonial, index)=>(<SwiperSlide>
              <div className="carousel_item__4Z77Y carousel_circle__Pte7w carousel_one__RKK8T">
                <img
                  alt="AdELA"
                  loading="lazy"
                  width={150}
                  height={150}
                  className="carousel_itemImage__F407g"
                  //   srcSet="/_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F119568%2F1713813845-adela.jpg%3Ffit%3Dmax%26fm%3Dwebp%26h%3D600%26q%3D75%26w%3D800&w=256&q=75 1x, /_next/image?url=https%3A%2F%2Fwww.datocms-assets.com%2F119568%2F1713813845-adela.jpg%3Ffit%3Dmax%26fm%3Dwebp%26h%3D600%26q%3D75%26w%3D800&w=384&q=75 2x"
                  src={testimonial.src}
                  style={{ color: "transparent" }}
                />
                <div className="carousel_itemText__HtUwk">
                  <div className="carousel_header__Fi6V7">{testimonial.name}</div>
                  <p>
                    {testimonial.review}
                  </p>
                  <div className="carousel_button__gii2G" />
                </div>
              </div>
            </SwiperSlide>))}
            
          </Swiper>
        </Container>
      </section>
    </div>
  );
};

export default Testimonial;
