import React from "react";
import HeroSection from "../RightsManagement/HeroSection";
import Intro from "../RightsManagement/Intro";
import ServiceList from "../RightsManagement/ServiceList";
import WhyFtTune from "../RightsManagement/WhyFtTune";
import LastComponent from "../RightsManagement/LastComponent";
import OtherServices from "../RightsManagement/OtherServices";

const YoutubeMonetisation = () => {
  const services = [
    {
      heading: "Channel Setup and Optimization",
      detail:
        "Before you can monetize your YouTube channel, it needs to meet certain criteria. FT Tunes assists with setting up and optimizing your channel to ensure it qualifies for monetization. This includes optimizing your video titles, descriptions, tags and thumbnails to attract more viewers and subscribers. We also guide you through the process of enabling monetization features like AdSense, Super Chat and memberships.",
    },
    {
      heading: "Content Strategy and Planning",
      detail:
        "Creating content that resonates with your audience is key to growing your channel and maximizing revenue. Our team works with you to develop a content strategy that aligns with your niche, targets your audience and enhances engagement. We provide insights into trending topics, optimal posting schedules and content types that drive higher views and ad revenue.",
    },
    {
      heading: "Compliance with YouTube Policies",
      detail:
        "YouTube’s monetization policies can be complex and non-compliance can result in demonetization. FT Tunes ensures your content adheres to YouTube’s guidelines, including community standards, copyright policies and advertiser-friendly content rules. Our experts monitor your channel to prevent any issues that could impact your monetization status.",
    },
    {
      heading: "Revenue Optimization",
      detail:
        "Maximizing your earnings on YouTube requires a deep understanding of monetization options. FT Tunes helps you optimize revenue streams by strategically placing ads, enabling Super Chat, setting up channel memberships and exploring additional monetization opportunities like merchandise sales and brand partnerships. We analyze your channel’s performance and make data-driven decisions to boost your income.",
    },
    {
      heading: "Audience Growth and Engagement",
      detail:
        "Growing your subscriber base and increasing viewer engagement are crucial for successful monetization. FT Tunes implements proven tactics to attract more subscribers, enhance viewer retention and increase watch time. We focus on building a loyal community around your channel, which in turn drives higher ad revenue and long-term success.",
    },
    {
      heading: "Analytics and Reporting",
      detail:
        "Understanding your channel’s performance is key to continuous improvement. FT Tunes provides detailed analytics and reporting to track your channel’s growth, revenue and engagement metrics. We offer insights into which videos are performing best, where your audience is coming from and how you can further optimize your content for better results.",
    },
  ];
  const otherServices = [
    {
      heading: "Rights Management",
      para: "Protect and manage your music rights across all platforms.",
      url: "/music-right-management",
    },
    // {
    //   heading: "Youtube Monetization",
    //   para: "Maximize revenue from your music on YouTube through effective monetization strategies.",
    //   url: "/youtube-monetization",
    // },
    {
      heading: "Music Publishing",
      para: "Ensure your music is properly published and earns royalties globally.",
      url: "/music-publishing",
    },
    {
      heading: "Digital Distribution",
      para: "Distribute your music worldwide to digital stores and streaming platforms seamlessly.",
      url: "/digital-distribution",
    },
  ];
  return (
    <div
      className="music_services"
      style={{ paddingBottom: "75px", background: "#000" }}
    >
      <HeroSection
        headingbefore="Leading"
        mark="YouTube Monetization"
        headingafter="Services in India"
        detail="In today’s digital era, YouTube has become a leading platform for content creators to showcase their work globally. However, producing excellent content is only the first step. To truly succeed, you need to monetize your efforts effectively. FT Tunes is here to help you unlock the full potential of your YouTube channel with our comprehensive YouTube Monetization services. As a leading provider in India, we offer tailored solutions that empower creators to maximize their revenue and grow their channels efficiently."
      />
      <Intro
        headingbefore="Why Choose"
        mark="FTtunes"
        headingafter="for YouTube Monetization Services?"
        detail="At FT Tunes, we specialize in providing the best YouTube Monetization services in India. Our team of experts understands the complexities of YouTube’s monetization policies and algorithms. We offer a range of services designed to help you meet the platform’s requirements, increase your viewership and optimize your earnings."
        imageurl="Images/youtube-monitazation-services.jpg"
        imageAlt="youtube monetization services"
      />
      <ServiceList
        services={services}
        heading="Our Comprehensive YouTube Monetization Services"
      />
      <WhyFtTune
        headingbefore="Why"
        mark="FTtunes"
        headingafter="?"
        detail="FT Tunes is committed to helping content creators succeed on YouTube. Our expertise in YouTube Monetization services sets us apart as a trusted partner in India."
        detail2="We offer personalized solutions that cater to the unique needs of your channel, ensuring you achieve your monetization goals efficiently and effectively."
        imageurl="Images/youtube-monetization-services.png"
      />
      <LastComponent
        headingbefore="Create and Earn Unlimited"
        mark="on YouTube"
        headingafter=""
        detail="Sign Up"
      />
      <OtherServices otherServices={otherServices} />
    </div>
  );
};

export default YoutubeMonetisation;
