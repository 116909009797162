import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Earnings from "./Earnings";
import Streams from "../Dashboard/Streams";
import TargetAudience from "./TargetAudience";
import AllReportsTable from "./AllReportsTable";

const Overview = () => {
  return (
    <div className="content-body">
      <Container>
        <Row>
          {/* <Col md={8}>
            <Streams />
          </Col>
          <Col md={4}>
            <Earnings />
          </Col>
          <Col md={6}>
            <TargetAudience />
          </Col> */}
          <AllReportsTable />
        </Row>
      </Container>
    </div>
  );
};

export default Overview;
