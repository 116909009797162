import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PublishingPartner = () => {
  const navigate = useNavigate();

  return (
    <div className="section-27 publishing_partner">
      <Row className="columns-15 ">
        <Col md={6} className=" w-col-small-small-stack">
          <h2 className="heading-publishing-1">
            Not claiming publishing royalties?{" "}
            <mark>
              <strong>You’re missing out.</strong>
            </mark>
          </h2>
          <p className="paragraph-31">
            You could be earning extra revenue whenever your music is performed
            live or broadcast in any kind of media. You can even claim
            additional publishing royalties every time your music is streamed on
            platforms like Spotify, Apple Music and YouTube.
          </p>
          <Button
            onClick={() => navigate("/signup")}
            className="button-publishing-get-started w-button gradient_border_btn"
          >
            Get Started
          </Button>
        </Col>
        <Col md={6} className="  w-col-small-small-stack" style={{textAlign:"center"}}>
          <img
            src="Images/distribution-royalties.png"
            alt="distribution royalties"
            className="image-226"
          />
        </Col>
      </Row>
    </div>
  );
};

export default PublishingPartner;
