import { Container, Row, Col, Image, Card } from "react-bootstrap";
import "./MusicUpload.css";
function MusicUpload() {
  return (
    <Container fluid className="music-upload-cont">
      <Row className="justify-content-center text-center mb-5">
        <Col xs={12} md={10} xl={8}>
          <h2 className="font-weight-bold mb-4">
            <span className="text-white">Upload </span>
            <span className="text-danger">
              <mark>Your Music</mark>
            </span>
            <span className="text-white">
              {" "}
              From Anywhere, to <mark>Any Platform</mark>
            </span>
          </h2>
          <p className="music-head-desc">
            Streaming, Downloads, YouTube Monetisation, Publishing. Anything
            from Anywhere. You earn your money and we'll earn you a name.
          </p>
        </Col>
      </Row>

      <div className="second-music-cont">
        <Row className="margin-bott" style={{alignItems:"center"}}>
          <Col xs={12} md={5}>
            <h3 className="display-3 font-weight-bold mb-3 musicupload-head-three">
              <span className="text-danger">
                <mark>Distribute</mark>
              </span>
              <span className="text-white"> Your Music Globally</span>
            </h3>
            <p className="text-light font-weight-semibold mb-3">
              Distribute your music globally on top platforms like Apple Music,
              JioSaavn, Spotify and more. Reach a worldwide audience and grow
              your fan base with seamless, efficient music distribution
              services.
            </p>
            <p className="text-light font-weight-semibold">
              Let your tracks be heard everywhere, ensuring your music gets the
              attention it deserves across all major streaming platforms.
            </p>
          </Col>
          <Col md={1} />
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
          >
            <Image
              src="Images/why-fttunes.png"
              alt="Music App"
              fluid
              className="rounded-3 shadow-lg music-upload-imag"
            />
          </Col>
        </Row>

        {/* <Row className="margin-bott">
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
          >
            <Image
              src="/Images/markets.png"
              alt="DJ Party"
              fluid
              className="rounded-3 shadow-lg"
            />
          </Col>
          <Col md={1}/>
          <Col xs={12} md={5}>
            <h3 className="display-3 font-weight-bold mb-3 musicupload-head-three">
              <span className="text-danger">
                <mark>Market</mark>
              </span>
              <span className="text-white"> Your Music</span>
            </h3>
            <p className="text-light font-weight-semibold mb-3">
              You are at a high tide in the market of music. Let us help you
              ride the tide.
            </p>
            <p className="text-light font-weight-semibold">
              Don’t just publish your music. Get noticed. And not just get
              noticed, create your own fan base with followers, subscribers, and
              viewers. Let them go gaga over your music. Brace up your music
              marketing with our tools.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={5}>
            <h3 className="display-3 font-weight-bold mb-3">
              <span className="text-danger">
                <mark>Own</mark>
              </span>
              <span className="text-white"> Your Music</span>
            </h3>
            <p className="text-light font-weight-semibold mb-3">
              Be the author of your music.
            </p>
            <p className="text-light font-weight-semibold">
              We believe in the artists’ independence in their creations. We
              believe in you as much as you believe in yourself.
            </p>
          </Col>
          <Col md={1}/>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
          >
            <Image
              src="/Images/tech.png"
              alt="Writing"
              fluid
              className="rounded-3 shadow-lg"
            />
          </Col>
        </Row> */}
      </div>
    </Container>
  );
}

export default MusicUpload;
