import React from "react";
import "./PriceTab.css";
import { Container } from "react-bootstrap";
const PriceTab = () => {
  return (
    <div style={{ background: "#000000" }}>
      <Container className="price_table_container">
        <div className="c98zp8-0 gSJmLi">
          <div className="c98zp8-0 lwvcfv-0 jRKRLu hitOkj">
            <div className="c98zp8-0 sc-2nffpe-0 jRKRLu bZFcpp">
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu hwGmHZ"
              >
                <div width="50%" className="c98zp8-0 sc-31nydx-0 jrPndC eqTeAr">
                  <div className="c98zp8-0 eqhRgK">
                    <h2
                      fontWeight="bold"
                      className="c98zp8-0 hv6g11-0 sc-2igbz0-0 laFXnL jTWLdb jMQofu"
                    >
                      Plans
                    </h2>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV eqTeAr sc-1gdq06g-0 ckdQlM">
                  <div className="c98zp8-0 eqhRgK">
                    <div
                      direction="vertical"
                      className="c98zp8-0 sc-9ueux3-1 jRKRLu IiRpi"
                    >
                      <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                        <span className="c98zp8-0 hv6g11-0 jfMJXT bPrCdf">
                          Pay Per Release
                        </span>
                      </div>
                      <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                        <span
                          fontWeight="regular"
                          className="c98zp8-0 hv6g11-0 hEfsiA etWezL"
                        >
                          ₹499
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV eqTeAr sc-1gdq06g-0 ckdQlM">
                  <div className="c98zp8-0 eqhRgK">
                    <div
                      direction="vertical"
                      className="c98zp8-0 sc-9ueux3-1 jRKRLu IiRpi"
                    >
                      <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                        <span className="c98zp8-0 hv6g11-0 jfMJXT bPrCdf">
                          Pro Artists
                        </span>
                      </div>
                      <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                        <span
                          fontWeight="regular"
                          className="c98zp8-0 hv6g11-0 hEfsiA etWezL"
                        >
                          ₹4,999
                        </span>{" "}
                        {/* <span className="c98zp8-0 hv6g11-0 jiFHgs etWezL">₹1,400</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV eqTeAr sc-1gdq06g-0 ckdQlM">
                  <div className="c98zp8-0 eqhRgK">
                    <div
                      direction="vertical"
                      className="c98zp8-0 sc-9ueux3-1 jRKRLu IiRpi"
                    >
                      <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                        <span className="c98zp8-0 hv6g11-0 jfMJXT bPrCdf">
                          Record Labels
                        </span>
                      </div>
                      <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                        <span
                          fontWeight="regular"
                          className="c98zp8-0 hv6g11-0 hEfsiA etWezL"
                        >
                          ₹9,999
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="c98zp8-0 j0vyu3-0 jRKRLu cyUqQu">
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Revenue shares
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg> */}
                    100%
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg> */}
                    100%
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg> */}
                    100%
                  </span>
                </div>
              </div>

              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        YouTube Content ID
                      </span>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 bllYeu">
                      <div display="inline-flex" className="c98zp8-0 dcPgsy">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          data-icon-name="InfoOutlined"
                          aria-label="InfoOutlined"
                          role="presentation"
                          className="c98zp8-0 IkZOH"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M20.25 12a8.25 8.25 0 11-16.501-.001A8.25 8.25 0 0120.25 12z"
                            />
                            <circle
                              cx={12}
                              cy="8.5"
                              r="1.25"
                              fill="currentColor"
                            />
                            <rect
                              width={2}
                              height={6}
                              x={11}
                              y={11}
                              fill="currentColor"
                              rx={1}
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <div display="flex" className="c98zp8-0 dvHGES">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        data-icon-name="CheckOutlined"
                        aria-label="CheckOutlined"
                        role="presentation"
                        color="primary.500"
                        className="c98zp8-0 jDMKZt"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0h24v24H0z" />
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M18 6l-7.496 12L6 13.5"
                          />
                        </g>
                      </svg>
                      <span
                        color="danger.300"
                        className="c98zp8-0 hv6g11-0 kiORgh iXJPXS"
                      >
                        Limited time only
                      </span>
                    </div>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>

              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        CallerTunes (Airtel, Jio, VI, BSNL)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>

              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Unlimited releases to 150+ digital stores &amp; social
                        media platforms
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Release approval time
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    3-5 days
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    2 days
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    2 days
                  </span>
                </div>
                {/* <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
          <span
            fontWeight="regular"
            className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
          >
            5 days
          </span>
        </div>
        <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
          <span
            fontWeight="regular"
            className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
          >
            2 days
          </span>
        </div> */}
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Support response time
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    48 hours
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    24 hours
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    Same day
                  </span>
                </div>
                {/* <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
          <span
            fontWeight="regular"
            className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
          >
            48 hours
          </span>
        </div>
        <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
          <span
            fontWeight="regular"
            className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
          >
            24 hours
          </span>
        </div> */}
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Artists included
                      </span>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 bllYeu">
                      <div display="inline-flex" className="c98zp8-0 dcPgsy">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          data-icon-name="InfoOutlined"
                          aria-label="InfoOutlined"
                          role="presentation"
                          className="c98zp8-0 IkZOH"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M20.25 12a8.25 8.25 0 11-16.501-.001A8.25 8.25 0 0120.25 12z"
                            />
                            <circle
                              cx={12}
                              cy="8.5"
                              r="1.25"
                              fill="currentColor"
                            />
                            <rect
                              width={2}
                              height={6}
                              x={11}
                              y={11}
                              fill="currentColor"
                              rx={1}
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    1
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    3
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    Unlimited
                  </span>
                </div>
                {/* <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
          <span
            fontWeight="regular"
            className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
          >
            1
          </span>
        </div>
        <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
          <span
            fontWeight="regular"
            className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
          >
            5
          </span>
        </div> */}
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Spotify artist verified checkmark
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Lyrics management with Musixmatch
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Use your own ISRC
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Royalty splits
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Basic trends &amp; earnings reports
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>

              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Advanced reporting
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CloseOutlined"
                      aria-label="CloseOutlined"
                      role="presentation"
                      color="neutral.500"
                      className="c98zp8-0 jviYgz"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M12 12l-6.25 6.25L12 12l6.25 6.25L12 12zm0 0L5.75 5.75 12 12l6.25-6.25L12 12z"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Custom label name
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CloseOutlined"
                      aria-label="CloseOutlined"
                      role="presentation"
                      color="neutral.500"
                      className="c98zp8-0 jviYgz"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M12 12l-6.25 6.25L12 12l6.25 6.25L12 12zm0 0L5.75 5.75 12 12l6.25-6.25L12 12z"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CloseOutlined"
                      aria-label="CloseOutlined"
                      role="presentation"
                      color="neutral.500"
                      className="c98zp8-0 jviYgz"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M12 12l-6.25 6.25L12 12l6.25 6.25L12 12zm0 0L5.75 5.75 12 12l6.25-6.25L12 12z"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div
                tabIndex={-1}
                className="c98zp8-0 sc-1d8w0qk-0 jRKRLu jOclRz"
              >
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <div
                    direction="horizontal"
                    className="c98zp8-0 sc-9ueux3-1 geDnnq XwNAG"
                  >
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <div className="c98zp8-0 hSHtlh">
                        <div className="sc-14vvn44-0 dOsGV">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            width="1em"
                            height="1em"
                            fill="none"
                          >
                            <path
                              stroke="currentColor"
                              strokeWidth="1.5"
                              d="M9.375 12.083a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M9.375 17.083A6.88 6.88 0 0 1 2.5 10.208a6.88 6.88 0 0 1 6.875-6.875 6.88 6.88 0 0 1 6.875 6.875M11.041 14.583h5"
                            />
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="m14.584 12.083 2.5 2.5-2.5 2.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="c98zp8-0 sc-9ueux3-0 jRKRLu">
                      <span
                        fontWeight="regular"
                        className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                      >
                        Custom release date
                      </span>
                    </div>
                  </div>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CloseOutlined"
                      aria-label="CloseOutlined"
                      role="presentation"
                      color="neutral.500"
                      className="c98zp8-0 jviYgz"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M12 12l-6.25 6.25L12 12l6.25 6.25L12 12zm0 0L5.75 5.75 12 12l6.25-6.25L12 12z"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
                <div className="c98zp8-0 sc-31nydx-0 bnHeWV fFqbdp">
                  <span
                    fontWeight="regular"
                    className="c98zp8-0 hv6g11-0 gChFoY bPrCdf"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-icon-name="CheckOutlined"
                      aria-label="CheckOutlined"
                      role="presentation"
                      color="primary.500"
                      className="c98zp8-0 jDMKZt"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h24v24H0z" />
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M18 6l-7.496 12L6 13.5"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PriceTab;
