import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MusicSupervision = () => {
  const navigate = useNavigate();

  return (
    <div
      className="music-supervision"
      style={{ background: "#000", paddingBlock: "50px" }}
    >
      <Container className="content-section d-flex align-items-center">
        <Row>
          <Col md={6}>
            <Image
              alt="Spotify curator image"
              className="spotify-curator-image"
              src="https://www.musicgateway.com/marketing-website/dist/img/pages/submit-music/spotify-curator.png"
              fluid
            />
          </Col>
          <Col md={6}>
            <Image
              className="mb-4"
              alt="Search icon graphic"
              src="	https://www.musicgateway.com/marketing-website/dist/img/svg/search-icon.svg"
              fluid
            />
            <h3 className="font-weight-light text-white">
              Additional <mark>Music Services</mark>
            </h3>
            <Image
              alt="Small blue and purple gradient divider"
              className="gradient-divider"
              src="	https://www.musicgateway.com/marketing-website/dist/img/svg/gradient-divider.svg"
              fluid
            />
            <p style={{ color: "#fff" }}>
              If you're in need of more guidance with your project, Music
              Gateway represents a network of UK and US music supervisors able
              to assist with creative direction. A range of composers are also
              available for hire to create bespoke compositions.
            </p>
            <Button
              variant="outline-light"
              className="gradient_border_btn"
              onClick={() => navigate("/signup")}
            >
              Music supervision
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MusicSupervision;
