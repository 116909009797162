import React from "react";
import { Navbar, Container, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavControl = () => {
  return (
    <Navbar
      className="nav-header"
      variant="dark"
      style={{ position: "absolute", zIndex: "999", height: "5rem", left:"30px" }}
    >
      <Container
        fluid
        className="d-flex align-items-center justify-content-between"
      >
        {/* Brand Logo */}
        <Navbar.Brand as={Link} to="/artist/dashboard" className="brand-logo">
          {/* <Image
            className="logo-abbr"
            src="/favicon.ico"
            alt="Logo"
            //   style={{width:"120px", height:"34px"}}
          /> */}
          <Image
            className="logo-compact d-none "
            src="/Images/logo_dark.png"
            alt="Logo Text"
            style={{ width: "182px", height: "51px" }}
          />
          <Image
            className="brand-title  d-sm-inline-block"
            src="/Images/logo_dark.png"
            alt="Brand Title"
            style={{ width: "225px", height: "75px" }}
          />
        </Navbar.Brand>

        {/* Hamburger Menu */}
        {/* <Button className="nav-control p-0 menu_navbar d-none" variant="link">
            <div className="hamburger">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </Button> */}
      </Container>
    </Navbar>
  );
};

export default NavControl;
