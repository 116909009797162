import React, { useEffect, useState } from "react";
import { Card, Button, Form, Row, Col, Modal } from "react-bootstrap";
import "./Plans.css";
import PriceTab from "../../components/Price/PriceTab";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Plans = () => {
  const navigate = useNavigate();
  //   const handleUpgrade=(plan)=>{
  //     navigate(`${plan.formAction}`)
  //   }
  const plansData = [
    {
      id: 1,
      name: "New Artist",
      price: "FREE",
      priceCurrency: "",
      priceIteration: "",
      blurb:
        "Kick-start your career with unlimited releases to Social Platforms.",
      bullets: [
        "Monetize your music on Social Platforms like TikTok, Facebook, Instagram, & YouTube (Keep 80% Revenue)",
        "Get paid weekly",
        "YouTube Official Artist Channel",
      ],
      planClass: "plan-green disabled",
      buttonText: "Current Plan",
      isCurrentPlan: true,
      formAction: "https://rzp.io/l/finaltake",
      clickedPlanPrice: 0,
    },
    {
      id: 2,
      name: "Pro Artist",
      price: "4,999",
      priceCurrency: "₹",
      priceIteration: "/year",
      blurb:
        "Add upto 3 Artists. Unlimited Releases to 155+ Indian & International Plate forms, Caller Tunes (Airtel, Jio, VI, BSNL), Free YouTube Content ID. Keep 100% of total revenue.",
      bullets: [
        "Spotify Verified Checkmark",
        "Official Sales Reports",
        "Artist Support (Standard)",
      ],
      planClass: "plan-blue",
      buttonText: "UPGRADE NOW",
      isCurrentPlan: false,
      formAction: "https://rzp.io/i/5mVwQRBAZd",
      clickedPlanPrice: 4999,
    },
    {
      id: 3,
      name: "Record Label",
      price: "9,999",
      priceCurrency: "₹",
      priceIteration: "/year",
      blurb:
        "Add unlimited Artists. Unlimited releases to 155+ Indian & International Plate forms, Caller Tunes (Airtel, Jio, VI, BSNL), Free YouTube Content ID. Keep 100% of total revenue.",
      bullets: [
        "Scheduled Release Date",
        "Cover Art Generator",
        "Daily Trend Reports from Apple Music, Spotify, and Amazon Music",
      ],
      planClass: "plan-red",
      buttonText: "UPGRADE NOW",
      isCurrentPlan: false,
      formAction: "https://rzp.io/i/db5ZVYs",
      clickedPlanPrice: 9999,
    },
    // {
    //   id: 4,
    //   name: "Professional",
    //   price: "3999",
    //   priceCurrency: "₹",
    //   priceIteration: "/year",
    //   blurb:
    //     "The premium plan for labels and industry pros. Release unlimited music plus access to exclusive services and products.",
    //   bullets: [
    //     "Custom Label Name",
    //     "Pro Panels & Expert Advice Sessions",
    //     "Promotional Opportunities and Partnerships",
    //   ],
    //   planClass: "plan-yellow",
    //   buttonText: "UPGRADE NOW",
    //   isCurrentPlan: false,
    //   formAction: "/plans/4/person_plans",
    //   clickedPlanPrice: 6450,
    // },
  ];

  const [amount, setAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("profile")));
    console.log("profile", profile);
  }, []);

  const createOrder = async (
    amount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    try {
      const response = await axios.post(
        "https://fttunes.com/api/create-order",
        {
          amount: amount,
          artist_id: artist_id,
          plan_purchased: plan_purchased,
          custom_reason: custom_reason,
          emailId: emailId,
          phone: phone,
        }
      );
      if (response.data.success) {
        initiateRazorpayPayment(
          response.data.order_id,
          response.data.amount,
          artist_id,
          plan_purchased,
          custom_reason,
          emailId,
          phone
        );
      } else {
        alert("Failed to create Razorpay order");
      }
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  const initiateRazorpayPayment = (
    order_id,
    order_amount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    const options = {
      key: "rzp_test_UXicWMfV5legHP", // Replace with your Razorpay key_id
      amount: order_amount, // Amount in paise (e.g., 50000 for Rs. 500)
      currency: "INR",
      name: "FINAL TAKE PRODUCTION",
      description: "Buy FTtunes Plan",
      order_id: order_id,
      handler: async (response) => {
        const { razorpay_payment_id, razorpay_signature } = response;
        try {
          const verifyResponse = await axios.post(
            "https://fttunes.com/api/payment/razorpay",
            {
              razorpay_payment_id,
              razorpay_signature,
              razorpay_order_id: order_id,
              amount: order_amount / 100,
              artist_id, // Pass artist_id
              plan_purchased, // Pass plan purchased
              custom_reason, // Pass custom reason
              emailId, // Pass email ID
              phone, // Pass phone number
            }
          );
          if (verifyResponse.data.success) {
            // alert("Payment Successful!");
            setPaymentDetails({
              razorpay_payment_id,
              razorpay_signature,
              razorpay_order_id: order_id,
              amount: order_amount / 100,
              artist_id, // Pass artist_id
              plan_purchased, // Pass plan purchased
              custom_reason, // Pass custom reason
              emailId, // Pass email ID
              phone, // Pass phone number
            });
            // setShowModal(true);
            navigate(`/payment-success/${order_id}`)

            // Automatically navigate to dashboard after 3 seconds
            // setTimeout(() => {
            //   window.location.href = "/artist/dashboard"; // This will navigate and reload the page
            //   setShowModal(false);
            // }, 3000);
          } else {
            alert("Payment Verification Failed");
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
        }
      },
      prefill: {
        name: "FTTunes User",
        email: emailId,
        contact: phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handlePayment = (
    fixedAmount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    if (fixedAmount) {
      createOrder(
        fixedAmount,
        artist_id,
        plan_purchased,
        custom_reason,
        emailId,
        phone
      ); // For Pro Artist and Record Label
    } else {
      if (amount && amount > 0) {
        createOrder(
          amount,
          artist_id,
          plan_purchased,
          custom_reason,
          emailId,
          phone
        ); // For Single Track and Custom Payment
        // handleCloseModal();
      } else {
        alert("Please enter a valid amount");
      }
    }
  };

  return (
    <>
      <div className="plans-container">
        <div className="plans">
          <div className="header">
            <mark>Upgrade Your Plan</mark>
          </div>
          <div className="subheader">
            Choose a new plan to unlock more features today.
          </div>
        </div>

        <Row className="plans plan-cards">
          {plansData.map((plan) => (
            <Col key={plan.id} md={4} lg={4}>
              <Card className={`plan ${plan.planClass}`}>
                <Card.Body>
                  {plan.isCurrentPlan && (
                    <div className="plan-current-plan-tag">Current Plan</div>
                  )}
                  <Card.Title className="plan-name">{plan.name}</Card.Title>
                  <div className="plan-price">
                    <span className="plan-currency">{plan.priceCurrency}</span>{" "}
                    {plan.price}{" "}
                    <span className="plan-iteration">
                      {plan.priceIteration}
                    </span>
                  </div>
                  <Card.Text className="plan-blurb">{plan.blurb}</Card.Text>
                  {/* <ul className="mobile plan-bullets">
                    {plan.bullets.map((bullet, index) => (
                      <li key={index}>{bullet}</li>
                    ))}
                  </ul> */}
                  <Form
                  //   action={navigate(`${plan.formAction}`)} method="post"
                  >
                    <Button
                      // type="submit"
                      className="plan-cta enabled"
                      //   data-plan-id={plan.id}
                      //   data-clicked-plan-price={plan.clickedPlanPrice}
                      //   onClick={handleUpgrade(plan)}
                      // href={`/artist/cart/${encodeURIComponent(
                      //   plan.formAction
                      // )}`}
                      // target="_blank"
                      onClick={() =>
                        handlePayment(
                          plan.clickedPlanPrice,
                          profile.artistId,
                          plan.name,
                          `Payment for ${plan.name}`,
                          profile.email,
                          profile.phone
                        )
                      }
                    >
                      {plan.buttonText}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <div className="footer plans">
          <p className="gst-disclaimer">
            * Applicable GST charges will be added to cart.
          </p>
          <a href="#compare-plans" id="compare-plans-btn" className="plan-link">
            Compare Plans
          </a>
          <a className="plan-link continue_no_plan" href="/dashboard">
            Continue with current plan
          </a>
        </div>
      </div>

      <div id="compare-plans">
        <PriceTab />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Successful!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Plan: {paymentDetails?.plan_purchased}</p>
          <p>Amount: ₹{paymentDetails?.amount}</p>
          <p>Thank you for your purchase!</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Plans;
