import { Container, Row, Col } from "react-bootstrap";
// import "./Banner.css"; // Import custom styles if necessary
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const images = [
  { img: "/Images/platform/Amazon-Music.png" },
  { img: "/Images/platform/Apple-Music.png" },
  { img: "/Images/platform/Wynk.png" },
  { img: "/Images/platform/YouTube.png" },
  { img: "/Images/platform/Audiomack.png" },
  { img: "/Images/platform/Gaana.png" },
  { img: "/Images/platform/JioSaavn.png" },
  { img: "/Images/platform/Beatport.png" },
  { img: "/Images/platform/Boomplay.png" },
  { img: "/Images/platform/FLO.png" },
  { img: "/Images/platform/TiKTok.png" },
  { img: "/Images/platform/Meta.png" },
  { img: "/Images/platform/Napster.png" },
  { img: "/Images/platform/VEVO.png" },
  { img: "/Images/platform/Anghami.png" },
  { img: "/Images/platform/Claro-Musica.png" },
  { img: "/Images/platform/Deezer.png" },
  { img: "/Images/platform/NetEase-Cloud-Music.png" },
  { img: "/Images/platform/QQ-music.png" },
  { img: "/Images/platform/SoundCloud.png" },
  { img: "/Images/platform/TIDAL.png" },
  { img: "/Images/platform/Boomplay.png" },
];
// const images = [
//   { img: "/Images/partners/amazon.svg",alt:"amazon" },
//   { img: "/Images/partners/apple-music.svg",alt:"apple-music"  },
//   { img: "/Images/partners/beatport.svg" ,alt:"beatport" },
//   { img: "/Images/partners/spotify.svg",alt:"spotify" },
//   { img: "/Images/partners/deezer.svg" ,alt:"deezer" },
//   { img: "/Images/partners/facebook.svg" ,alt:"facebook" },
//   { img: "/Images/partners/instragram.svg" ,alt:"instagram" },
//   { img: "/Images/partners/juno-download.svg" ,alt:"juno-download" },
//   { img: "/Images/partners/tiktok.svg" ,alt:"tiktok" },
//   { img: "/Images/partners/napster.svg" ,alt:"napster" },
//   { img: "/Images/partners/pandora.svg" ,alt:"pandora" },
//   { img: "/Images/partners/yt-music.svg" ,alt:"yt-music" },
//   { img: "/Images/partners/snapchat.svg" ,alt:"snapchat" },
//   { img: "/Images/partners/claro.svg" ,alt:"claro" },
//   { img: "/Images/partners/tidal.svg" ,alt:"tidal" },
//   { img: "/Images/partners/m-xcloud.svg" ,alt:"m-xcloud" },
//   { img: "/Images/partners/twitch.svg" ,alt:"twitch" },
//   { img: "/Images/partners/vevo.svg" ,alt:"vevo" },
//   { img: "/Images/partners/peloton.svg" ,alt:"peloton" },
// ];
const BannerStripThankYou = () => {
  return (
    <div className="position-relative py-3 px-3" 
    // style={{ marginTop: "-80px" }}
    >
      <div className="position-absolute top-0 start-0 w-100 h-100 bg-accent-color rounded-end-3 rounded-start-3" />
      <div className="position-relative" style={{ zIndex: 2 }}>
        <div className="small-text-22">GET YOUR MUSIC ON</div>
        <Swiper
          spaceBetween={30}
          //   centeredSlides={true}
        
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          //   pagination={{
          //     clickable: true,
          //   }}
          //   navigation={true}
          modules={[Autoplay]}
          className="mySwiper"
          breakpoints={{
            "@0.00": {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
        >
          {images.map((data) => (
            <SwiperSlide>
              <img src={data.img} alt={data.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BannerStripThankYou;
