import React, { useState, useEffect } from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Link, useNavigate } from "react-router-dom";
import "./ImageModal.css";
import axios from "axios";

const ImageModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [perRelaese, setPerRelease] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the modal has already been shown
    const hasShownModal = sessionStorage.getItem("hasShownModal");

    if (!hasShownModal) {
      const timer = setTimeout(() => {
        setShowModal(true);
        // Set the flag in localStorage to mark the modal as shown
        sessionStorage.setItem("hasShownModal", "true");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const [amount, setAmount] = useState(null);
  // const [showModal2, setShowModal2] = useState(false);
  // const [modalTitle, setModalTitle] = useState("");

  // const handleShowModal2 = (title) => {
  //   setModalTitle(title);
  //   setShowModal2(true);
  // };

  // const handleCloseModal2 = () => {
  //   setAmount(null);
  //   setShowModal2(false);
  // };

  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("profile")));
    console.log("profile", profile);
  }, []);

  const createOrder = async (
    amount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    try {
      const response = await axios.post(
        "https://fttunes.com/api/create-order",
        {
          amount: amount,
          artist_id: artist_id,
          plan_purchased: plan_purchased,
          custom_reason: custom_reason,
          emailId: emailId,
          phone: phone,
        }
      );
      if (response.data.success) {
        initiateRazorpayPayment(
          response.data.order_id,
          response.data.amount,
          artist_id,
          plan_purchased,
          custom_reason,
          emailId,
          phone
        );
      } else {
        alert("Failed to create Razorpay order");
      }
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
    }
  };

  const initiateRazorpayPayment = (
    order_id,
    order_amount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    const options = {
      key: "rzp_test_UXicWMfV5legHP", // Replace with your Razorpay key_id
      amount: order_amount, // Amount in paise (e.g., 50000 for Rs. 500)
      currency: "INR",
      name: "FINAL TAKE PRODUCTION",
      description: "Buy FTtunes Plan",
      order_id: order_id,
      handler: async (response) => {
        const { razorpay_payment_id, razorpay_signature } = response;
        try {
          const verifyResponse = await axios.post(
            "https://fttunes.com/api/payment/razorpay",
            {
              razorpay_payment_id,
              razorpay_signature,
              razorpay_order_id: order_id,
              amount: order_amount / 100,
              artist_id, // Pass artist_id
              plan_purchased, // Pass plan purchased
              custom_reason, // Pass custom reason
              emailId, // Pass email ID
              phone, // Pass phone number
            }
          );
          if (verifyResponse.data.success) {
            // alert("Payment Successful!");
            navigate(`/payment-success/${order_id}`);
          } else {
            alert("Payment Verification Failed");
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
        }
      },
      prefill: {
        name: "FTTunes User",
        email: emailId,
        contact: phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handlePayment = (
    fixedAmount,
    artist_id,
    plan_purchased,
    custom_reason,
    emailId,
    phone
  ) => {
    if (fixedAmount) {
      createOrder(
        fixedAmount,
        artist_id,
        plan_purchased,
        custom_reason,
        emailId,
        phone
      ); // For Pro Artist and Record Label
    } else {
      if (amount && amount > 0) {
        createOrder(
          amount,
          artist_id,
          plan_purchased,
          custom_reason,
          emailId,
          phone
        ); // For Single Track and Custom Payment
        // handleCloseModal();
      } else {
        alert("Please enter a valid amount");
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="xl"
        fullscreen
      >
        <Modal.Body className="p-0">
          <div style={{ position: "relative" }} className="popup-modal">
            <div
              className="pricing_plan"
              // style={{ padding: "50px 0px", background: "#000" }}
            >
              <Container>
                <div className="price-container-one">
                  <Col
                    md={12}
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="price-one-heads"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        className="price-one-heads-para"
                        style={{ textAlign: "center" }}
                      >
                        <mark>Pricing Plans</mark>
                      </p>
                      <h1>Pricing for every need</h1>
                      <div className="pricing_planToggle__bVkFD">
                        <div
                          className={`pricing_toggleOption__EOUHy ${
                            perRelaese
                              ? "pricing_planToggle__bVkFf_tableee"
                              : ""
                          }`}
                          onClick={() => setPerRelease(!perRelaese)}
                        >
                          Unlimited Plans
                        </div>
                        <div
                          className={`pricing_toggleOption__EOUHy ${
                            perRelaese
                              ? ""
                              : "pricing_planToggle__bVkFf_tableee"
                          }`}
                          onClick={() => setPerRelease(!perRelaese)}
                        >
                          Pay Per Release
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} sm={12} style={{ textAlign: "center" }}>
                    <div className="price-one-heads">
                      <p className="price-one-heads-paras">
                        Release to 155+ platforms, including Spotify, JioSaavn,
                        Wynk, Apple Music & Youtube and benefit from the best
                        support of any distribution company, period.
                      </p>
                    </div>
                  </Col>
                </div>
              </Container>
              {perRelaese ? (
                <Container style={{ marginTop: "50px" }}>
                  <Row>
                    <Col md={1} />
                    <Col xs={12} md={4}>
                      <div className="elements--pricing__card-featured_section-2-module-1  cards__card elements--pricing__card_section-2-module-1 elements--pricing__card-default_section-2-module-11 aos-init aos-animate">
                        <span className="button_button__MFmXH button_color__bLscP  elements--pricing__badge_section-2-module-1 elements--pricing__badge-default_section-2-module-1">
                          Pro Artist
                        </span>
                        <div
                          className="elements--pricing__price-container_section-2-module-1"
                          style={{ marginTop: 15 }}
                        >
                          <span className="elements--pricing__price-value_section-2-module-1 lements--pricing__pricees">
                            ₹10,000
                          </span>
                          <span className="elements--pricing__price-frecuency_section-2-module-1">
                            / ₹4,999
                          </span>
                        </div>

                        <div className="elements--pricing__separator_section-2-module-1">
                          &nbsp;
                        </div>
                        <h5
                          className="elements--pricing__features-title_section-2-module-1"
                          style={{}}
                        >
                          Everything in Basic, plus:
                        </h5>
                        {/* <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Add Unlimited Artist
                    </span>
                  </div> */}
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Unlimited Releases
                          </span>
                        </div>

                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            3 Artists
                          </span>
                        </div>

                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            155+ Indian & International Plateforms
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            CallerTunes (Airtel, Jio, VI, BSNL)
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Free YouTube Content ID
                          </span>
                        </div>

                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Within 48 Working hrs Delivery ( Prepaid Orders )
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            100% Revenue Share
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Priority Customer Support
                          </span>
                        </div>
                        <a
                          // href="https://rzp.io/i/5mVwQRBAZd"
                          // target="_blank"
                          onClick={() =>
                            handlePayment(
                              4999,
                              profile.artistId,
                              "Pro Artist",
                              "Payment for Pro Artist",
                              profile.email,
                              profile.phone
                            )
                          }
                          rel="noreferrer"
                        >
                          <div className="elements--pricing__button-container_section-2-module-1">
                            <button className="elements--pricing__button-default_section-2-module-1  btn-secondary-white">
                              Upgrade Now
                            </button>
                          </div>
                        </a>
                      </div>
                    </Col>
                    <Col md={1} />
                    <Col xs={12} md={4}>
                      <div className="cards__card elements--pricing__card_section-2-module-1 elements--pricing__card-default_section-2-module-1 aos-init aos-animate">
                        <span className="button_button__MFmXH button_color__bLscP elements--pricing__badge_section-2-module-1 elements--pricing__badge-default_section-2-module-1">
                          Record Label
                        </span>
                        <div
                          className="elements--pricing__price-container_section-2-module-1"
                          style={{ marginTop: 15 }}
                        >
                          <span className="elements--pricing__price-value_section-2-module-1 lements--pricing__pricees">
                            ₹20,000
                          </span>
                          <span className="elements--pricing__price-frecuency_section-2-module-1">
                            / ₹9,999
                          </span>
                        </div>

                        <div className="elements--pricing__separator_section-2-module-1">
                          &nbsp;
                        </div>
                        <h5
                          className="elements--pricing__features-title_section-2-module-1"
                          style={{}}
                        >
                          Everything in Pro, plus:
                        </h5>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Release with Your Own Label
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Unlimited Artists
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Unlimited Releases
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            155+ Indian & International Plateforms
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            CallerTunes (Airtel, Jio, VI, BSNL)
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Free YouTube Content ID
                          </span>
                        </div>

                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Within 48Hrs Delivery
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            100% Revenue Share
                          </span>
                        </div>
                        <div className="elements--pricing__features-container_section-2-module-1">
                          <span className="elements--pricing__features-image_section-2-module-1">
                            <img
                              src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                              alt="Elements Image"
                              width={26}
                              height={26}
                            />
                          </span>
                          <span className="elements--pricing__features-name_section-2-module-1">
                            Priority Customer Support
                          </span>
                        </div>
                        {/* <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      1 Year Distribution Free
                    </span>
                  </div> */}
                        <a
                          // href="https://rzp.io/i/db5ZVYs"
                          // target="_blank"
                          onClick={() =>
                            handlePayment(
                              9999,
                              profile.artistId,
                              "Record Label",
                              "Payment for Record Label",
                              profile.email,
                              profile.phone
                            )
                          }
                          rel="noreferrer"
                        >
                          <div className="elements--pricing__button-container_section-2-module-1">
                            <button className="elements--pricing__button-default_section-2-module-1  btn-secondary-white">
                              Upgrade Now
                            </button>
                          </div>
                        </a>
                      </div>
                    </Col>
                    <Col md={1} />
                  </Row>
                </Container>
              ) : (
                <Container style={{ marginTop: "50px" }}>
                  <Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Col xs={12} md={5}>
                        <div
                          className="cards__card cards__cardss elements--pricing__card_section-2-module-1 elements--pricing__card-default_section-2-module-1 aos-init aos-animate"
                          data-aos="fade-up"
                          data-aos-once="true"
                          data-aos-duration={400}
                          data-aos-delay={0}
                        >
                          <span className="button_button__MFmXH button_color__bLscP elements--pricing__badge_section-2-module-1 elements--pricing__badge-default_section-2-module-1">
                            Artist
                          </span>
                          <div
                            className="elements--pricing__price-container_section-2-module-1"
                            style={{ marginTop: 15 }}
                          >
                            <span className="elements--pricing__price-value_section-2-module-1 lements--pricing__pricees">
                              ₹1000
                            </span>
                            <span className="elements--pricing__price-frecuency_section-2-module-1">
                              / ₹499
                            </span>
                          </div>

                          <div className="elements--pricing__separator_section-2-module-1">
                            &nbsp;
                          </div>
                          <h5
                            className="elements--pricing__features-title_section-2-module-1"
                            style={{}}
                          >
                            What’s included:
                          </h5>
                          <div className="elements--pricing__features-container_section-2-module-1">
                            <span className="elements--pricing__features-image_section-2-module-1">
                              <img
                                src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                                alt="Elements Image"
                                width={26}
                                height={26}
                              />
                            </span>
                            <span className="elements--pricing__features-name_section-2-module-1">
                              155+ Indian & International Plateforms
                            </span>
                          </div>
                          <div className="elements--pricing__features-container_section-2-module-1">
                            <span className="elements--pricing__features-image_section-2-module-1">
                              <img
                                src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                                alt="Elements Image"
                                width={26}
                                height={26}
                              />
                            </span>
                            <span className="elements--pricing__features-name_section-2-module-1">
                              CallerTunes (Airtel, Jio, VI, BSNL)
                            </span>
                          </div>
                          <div className="elements--pricing__features-container_section-2-module-1">
                            <span className="elements--pricing__features-image_section-2-module-1">
                              <img
                                src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                                alt="Elements Image"
                                width={26}
                                height={26}
                              />
                            </span>
                            <span className="elements--pricing__features-name_section-2-module-1">
                              Free YouTube Content ID
                            </span>
                          </div>

                          <div className="elements--pricing__features-container_section-2-module-1">
                            <span className="elements--pricing__features-image_section-2-module-1">
                              <img
                                src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                                alt="Elements Image"
                                width={26}
                                height={26}
                              />
                            </span>
                            <span className="elements--pricing__features-name_section-2-module-1">
                              Within 48 Working Hrs Delivery ( Prepaid Orders )
                            </span>
                          </div>
                          <div className="elements--pricing__features-container_section-2-module-1">
                            <span className="elements--pricing__features-image_section-2-module-1">
                              <img
                                src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                                alt="Elements Image"
                                width={26}
                                height={26}
                              />
                            </span>
                            <span className="elements--pricing__features-name_section-2-module-1">
                              100% Revenue Share
                            </span>
                          </div>
                          <div className="elements--pricing__features-container_section-2-module-1">
                            <span className="elements--pricing__features-image_section-2-module-1">
                              <img
                                src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                                alt="Elements Image"
                                width={26}
                                height={26}
                              />
                            </span>
                            <span className="elements--pricing__features-name_section-2-module-1">
                              Priority Customer Support
                            </span>
                          </div>
                          <a
                            // href="https://rzp.io/l/finaltake"
                            // target="_blank"
                            // onClick={() => handleShowModal2("Pay Per Release")}
                            onClick={() =>
                              handlePayment(
                                499,
                                profile.artistId,
                                "Pay Per Release",
                                "Payment for Pay Per Release",
                                profile.email,
                                profile.phone
                              )
                            }
                            rel="noreferrer"
                          >
                            <div className="elements--pricing__button-container_section-2-module-1">
                              <button className="elements--pricing__button-default_section-2-module-1  btn-secondary-white">
                                Upgrade Now
                              </button>
                            </div>
                          </a>
                        </div>
                      </Col>
                    </div>
                  </Row>
                </Container>
              )}
            </div>
            <button className="close-btn-modal" onClick={handleClose}>
              &times;
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={showModal2}
        onHide={handleCloseModal2}
        data-bs-theme="dark"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formAmount">
            <Form.Label style={{ color: "#fff" }}>
              Enter Amount (INR)
            </Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal2}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handlePayment(null)}>
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default ImageModal;
