import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Price.css";
import PriceTab from "./PriceTab";
import PricingUpper from "./PricingUpper";
import Pricee from "./Pricee";
import Testimonial from "../Home/Testimonial/Testi/Testimonial";
import { Link, useNavigate } from "react-router-dom";
const Price = () => {
  const [perRelaese, setPerRelease] = useState(true);
  const navigate = useNavigate();

  return (
    <div className="price_parent">
      <PricingUpper />
      {/* <Pricee/> */}
      <div className="pricing_plan" 
      // style={{ padding: "50px 0px", background: "#000" }}
      >
        <Container>
          <div className="price-container-one">
            <Col md={12} sm={12} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <div className="price-one-heads" style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                <p className="price-one-heads-para" style={{textAlign:"center"}}>
                  <mark>Pricing Plans</mark>
                </p>
                <h1>Pricing for every need</h1>
                <div className="pricing_planToggle__bVkFD">
                  <div
                    className={`pricing_toggleOption__EOUHy ${
                      perRelaese ? "pricing_planToggle__bVkFf_tableee" : ""
                    }`}
                    onClick={() => setPerRelease(!perRelaese)}
                  >
                    Unlimited Plans
                  </div>
                  <div
                    className={`pricing_toggleOption__EOUHy ${
                      perRelaese ? "" : "pricing_planToggle__bVkFf_tableee"
                    }`}
                    onClick={() => setPerRelease(!perRelaese)}
                  >
                    Pay Per Release
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} sm={12} style={{textAlign:"center"}}>
              <div className="price-one-heads">
                <p className="price-one-heads-paras">
                  Release to 155+ platforms, including Spotify, JioSaavn, Wynk,
                  Apple Music & Youtube and benefit from the best support of any
                  distribution company, period.
                </p>
              </div>
            </Col>
          </div>
        </Container>
        {perRelaese ? (
          <Container style={{ marginTop: "50px" }}>
            <Row>
              <Col md={1} />
              <Col xs={12} md={4}>
                <div className="elements--pricing__card-featured_section-2-module-1  cards__card elements--pricing__card_section-2-module-1 elements--pricing__card-default_section-2-module-11 aos-init aos-animate">
                  <span className="button_button__MFmXH button_color__bLscP  elements--pricing__badge_section-2-module-1 elements--pricing__badge-default_section-2-module-1">
                    Pro Artist
                  </span>
                  <div
                    className="elements--pricing__price-container_section-2-module-1"
                    style={{ marginTop: 15 }}
                  >
                    <span className="elements--pricing__price-value_section-2-module-1 lements--pricing__pricees">
                      ₹10,000
                    </span>
                    <span className="elements--pricing__price-frecuency_section-2-module-1">
                      / ₹4,999
                    </span>
                  </div>

                  <div className="elements--pricing__separator_section-2-module-1">
                    &nbsp;
                  </div>
                  <h5
                    className="elements--pricing__features-title_section-2-module-1"
                    style={{}}
                  >
                    Everything in Basic, plus:
                  </h5>
                  {/* <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Add Unlimited Artist
                    </span>
                  </div> */}
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Unlimited Releases
                    </span>
                  </div>

                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      3 Artists
                    </span>
                  </div>

                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      155+ Indian & International Plateforms
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      CallerTunes (Airtel, Jio, VI, BSNL)
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Free YouTube Content ID
                    </span>
                  </div>
                  
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Within 48 Working hrs Delivery ( Prepaid Orders )
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      100% Revenue Share
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Priority Customer Support
                    </span>
                  </div>
                  <Link to="/signup">
                    <div className="elements--pricing__button-container_section-2-module-1">
                      <button className="elements--pricing__button-default_section-2-module-1  btn-secondary-white">
                      Sign Up
                      </button>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md={1} />
              <Col xs={12} md={4}>
                <div className="cards__card elements--pricing__card_section-2-module-1 elements--pricing__card-default_section-2-module-1 aos-init aos-animate">
                  <span className="button_button__MFmXH button_color__bLscP elements--pricing__badge_section-2-module-1 elements--pricing__badge-default_section-2-module-1">
                    Record Label
                  </span>
                  <div
                    className="elements--pricing__price-container_section-2-module-1"
                    style={{ marginTop: 15 }}
                  >
                    <span className="elements--pricing__price-value_section-2-module-1 lements--pricing__pricees">
                      ₹20,000
                    </span>
                    <span className="elements--pricing__price-frecuency_section-2-module-1">
                      / ₹9,999
                    </span>
                  </div>

                  <div className="elements--pricing__separator_section-2-module-1">
                    &nbsp;
                  </div>
                  <h5
                    className="elements--pricing__features-title_section-2-module-1"
                    style={{}}
                  >
                    Everything in Pro, plus:
                  </h5>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Release with Your Own Label
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Unlimited Artists
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Unlimited Releases
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      155+ Indian & International Plateforms
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      CallerTunes (Airtel, Jio, VI, BSNL)
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Free YouTube Content ID
                    </span>
                  </div>
                 
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Within 48Hrs Delivery
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      100% Revenue Share
                    </span>
                  </div>
                  <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      Priority Customer Support
                    </span>
                  </div>
                  {/* <div className="elements--pricing__features-container_section-2-module-1">
                    <span className="elements--pricing__features-image_section-2-module-1">
                      <img
                        src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                        alt="Elements Image"
                        width={26}
                        height={26}
                      />
                    </span>
                    <span className="elements--pricing__features-name_section-2-module-1">
                      1 Year Distribution Free
                    </span>
                  </div> */}
                   <Link to="/signup">
                    <div className="elements--pricing__button-container_section-2-module-1">
                      <button className="elements--pricing__button-default_section-2-module-1  btn-secondary-white">
                      Sign Up
                      </button>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col md={1} />
            </Row>
          </Container>
        ) : (
          <Container style={{ marginTop: "50px" }}>
            <Row>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Col xs={12} md={5}>
                  <div
                    className="cards__card cards__cardss elements--pricing__card_section-2-module-1 elements--pricing__card-default_section-2-module-1 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-duration={400}
                    data-aos-delay={0}
                  >
                    <span className="button_button__MFmXH button_color__bLscP elements--pricing__badge_section-2-module-1 elements--pricing__badge-default_section-2-module-1">
                      Artist
                    </span>
                    <div
                      className="elements--pricing__price-container_section-2-module-1"
                      style={{ marginTop: 15 }}
                    >
                      <span className="elements--pricing__price-value_section-2-module-1 lements--pricing__pricees">
                        ₹1000
                      </span>
                      <span className="elements--pricing__price-frecuency_section-2-module-1">
                        / ₹499
                      </span>
                    </div>

                    <div className="elements--pricing__separator_section-2-module-1">
                      &nbsp;
                    </div>
                    <h5
                      className="elements--pricing__features-title_section-2-module-1"
                      style={{}}
                    >
                      What’s included:
                    </h5>
                    <div className="elements--pricing__features-container_section-2-module-1">
                      <span className="elements--pricing__features-image_section-2-module-1">
                        <img
                          src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                          alt="Elements Image"
                          width={26}
                          height={26}
                        />
                      </span>
                      <span className="elements--pricing__features-name_section-2-module-1">
                        155+ Indian & International Plateforms
                      </span>
                    </div>
                    <div className="elements--pricing__features-container_section-2-module-1">
                      <span className="elements--pricing__features-image_section-2-module-1">
                        <img
                          src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                          alt="Elements Image"
                          width={26}
                          height={26}
                        />
                      </span>
                      <span className="elements--pricing__features-name_section-2-module-1">
                        CallerTunes (Airtel, Jio, VI, BSNL)
                      </span>
                    </div>
                    <div className="elements--pricing__features-container_section-2-module-1">
                      <span className="elements--pricing__features-image_section-2-module-1">
                        <img
                          src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                          alt="Elements Image"
                          width={26}
                          height={26}
                        />
                      </span>
                      <span className="elements--pricing__features-name_section-2-module-1">
                        Free YouTube Content ID
                      </span>
                    </div>
                    
                    <div className="elements--pricing__features-container_section-2-module-1">
                      <span className="elements--pricing__features-image_section-2-module-1">
                        <img
                          src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                          alt="Elements Image"
                          width={26}
                          height={26}
                        />
                      </span>
                      <span className="elements--pricing__features-name_section-2-module-1">
                        Within 48 Working Hrs Delivery ( Prepaid Orders )
                      </span>
                    </div>
                    <div className="elements--pricing__features-container_section-2-module-1">
                      <span className="elements--pricing__features-image_section-2-module-1">
                        <img
                          src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                          alt="Elements Image"
                          width={26}
                          height={26}
                        />
                      </span>
                      <span className="elements--pricing__features-name_section-2-module-1">
                        100% Revenue Share
                      </span>
                    </div>
                    <div className="elements--pricing__features-container_section-2-module-1">
                      <span className="elements--pricing__features-image_section-2-module-1">
                        <img
                          src="https://8768169.fs1.hubspotusercontent-na1.net/hubfs/8768169/Elements/Theme/icons/featured_pricing.svg"
                          alt="Elements Image"
                          width={26}
                          height={26}
                        />
                      </span>
                      <span className="elements--pricing__features-name_section-2-module-1">
                        Priority Customer Support
                      </span>
                    </div>
                    <Link to="/signup">
                      <div className="elements--pricing__button-container_section-2-module-1">
                        <button className="elements--pricing__button-default_section-2-module-1  btn-secondary-white">
                        Sign Up
                        </button>
                      </div>
                    </Link>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        )}
      </div>
      {/* <div className="c98zp8-0 sc-1axd9de-0 dIVOxo ooTXc">
        <span className="c98zp8-0 hv6g11-0 jfMJXT JuTXL" />
        <span className="c98zp8-0 hv6g11-0 jfMJXT JuTXL">
          <a target="_blank" className="c98zp8-0 lgtdx9-0 jfMJXT jFqIIF">
            Learn more
          </a>{" "}
          about services for agencies, labels, and high-volume distributors.
        </span>
        <span className="c98zp8-0 hv6g11-0 jfMJXT JuTXL">
          *Read about our{" "}
          <a target="_blank" className="c98zp8-0 lgtdx9-0 jfMJXT jFqIIF">
            fair usage policy
          </a>
          .
        </span>
      </div> */}

      <PriceTab />
      <Testimonial />
    </div>
  );
};

export default Price;
