import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';

const HeroSection = () => {

  const navigate = useNavigate();
  const sectionRef = useRef(null); // Reference to the section element
  const [overlayHeight, setOverlayHeight] = useState(0); // State for overlay height

  useEffect(() => {
    // Function to set the overlay height
    const updateOverlayHeight = () => {
      if (sectionRef.current) {
        setOverlayHeight(sectionRef.current.clientHeight);
      }
    };

    // Set the overlay height on component mount
    updateOverlayHeight();

    // Set the overlay height on window resize
    window.addEventListener("resize", updateOverlayHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateOverlayHeight);
    };
  }, []);



  return (
    <section className="hero hero-bg is-medium has-text-centered p-l-0 p-r-0"  ref={sectionRef}>
      <div className="overlay" style={{ height: `${overlayHeight}px` }}></div>
      <div className="hero-padding">
        <Container>
          <Row className="justify-content-center">
            <Col xs={10} className="p-b-0 p-l-0 p-r-0">
              <h1 className="has-text-weight-light has-text-white">
              Music Distribution Services <mark>in India</mark>
              </h1>
              <h2 className="has-text-white has-text-weight-bold">
              FT Tunes stands at the forefront of the music industry, dedicated to bridging the gap between artists and their audiences. As a leading music distribution company, we specialize in providing comprehensive music distribution services that empower independent artists, labels and content creators to reach global platforms with ease. Our mission is to ensure that your music finds its way to every corner of the world, giving you the recognition you deserve.    
              </h2>
            </Col>
          </Row>
          <Row style={{marginTop:"20px", gap:"10px"}}>
            <Col>
              <Button
                className="no-float-mobile is-pulled-right pointer is-rounded is-gradient-solid has-text-weight-bold"
                // onClick={() => history.push('/music-services-form')}
                onClick={() => navigate("/signup")}
                style={{ float: "right", borderRadius:"50px", paddingInline:"75px" }}
              >
                Get in touch
              </Button>
            </Col>
            <Col>
              <Button
                className="svg-button no-float-mobile is-pulled-left artist_button"
                rel="noopener noreferrer"
                onClick={() => navigate("/signup")}
                style={{ float: "left", padding: "0px" }}
              >
                <svg width="230" height="60" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fillRule="evenodd">
                    <text fontSize="16" fontWeight="500" fill="#fff">
                      <tspan x="54.727" y="36">
                        Artists &amp; Bands
                      </tspan>
                    </text>
                    <rect
                      stroke="url(#buttonStroke)"
                      strokeWidth="2"
                      x="1"
                      y="1"
                      width="220"
                      height="58"
                      rx="29"
                    />
                  </g>
                </svg>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="fit-content m-x-auto">
        <a
          onClick={handleScrollToMain}
          href="#main"
          className="pointer scroll-down"
        >
          <img
            alt="Chevron icon pointing down"
            src="	https://www.musicgateway.com/marketing-website/dist/img/svg/chev-down.svg"
          />
        </a>
      </div> */}
    </section>
  );
};

export default HeroSection;
